import {
  AtrigamConditionsSet,
  AtrigamModelsModelFlowStepInteraction,
  AtrigamModelsModelFlowStepInteractionActiveState,
} from '@atrigam/atrigam-types';
import { computed, makeObservable, observable, toJS } from 'mobx';
import { persist } from 'mobx-persist';

import { ConditionsSetEntity } from '../conditions/ConditionsSet.entity';

type Data = Pick<AtrigamModelsModelFlowStepInteraction, 'restrictions' | 'activeState'>;

export class RestrictionsEntity {
  @persist('object', ConditionsSetEntity)
  @observable
  conditionsSet?: ConditionsSetEntity;

  @persist('map')
  @observable
  private _activeState = observable.map<string, boolean>();

  constructor(data?: Data) {
    makeObservable(this);

    if (data?.restrictions) {
      this.conditionsSet = new ConditionsSetEntity(data.restrictions);
    }

    if (data?.activeState) {
      Object.entries(data.activeState).forEach(([key, value]) => {
        this._activeState.set(key, value);
      });
    }
  }

  @computed
  get restrictions() {
    if (!this.conditionsSet) {
      return;
    }

    const conditionSet: AtrigamConditionsSet = {};

    this.conditionsSet.conditionsSet.forEach((value, key) => {
      conditionSet[key] = value.toJS();
    });

    return conditionSet;
  }

  @computed
  get activeState() {
    return Object.fromEntries(
      toJS(this._activeState),
    ) as AtrigamModelsModelFlowStepInteractionActiveState;
  }
}
