import { AtrigamInteractionImage } from '@atrigam/atrigam-types';

export const getSmallestImageVariationLocation = (image?: AtrigamInteractionImage) => {
  if (!image) {
    return;
  }

  if (image.thumbloc) {
    return image.thumbloc;
  }

  if (image.minloc) {
    return image.minloc;
  }

  if (image.midloc) {
    return image.midloc;
  }

  return image.fb_storage_location;
};
