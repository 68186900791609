import { AtrigamModelsModelFlowRecordDetailRoleInteraction } from '@atrigam/atrigam-types';

export const isAtrigamModelsModelFlowRecordDetailRoleInteraction = (
  data: unknown,
): data is AtrigamModelsModelFlowRecordDetailRoleInteraction => {
  if (!data || typeof data !== 'object') {
    return false;
  }
  return (data as AtrigamModelsModelFlowRecordDetailRoleInteraction).data !== undefined;
};
