import { UnhandledCaseError } from '@atrigam/atrigam-types';

import { getRouteComponentsForDefaultLayout } from './getRouteComponentsForDefaultLayout';
import { getRouteComponentsForUnauthorizedLayout } from './getRouteComponentsForUnauthorizedLayout';
import {
  AllRouteComponents,
  RouteLayout,
  RouteOptionsComponents,
  RouteScope,
} from '../Router.types';

interface Options<Scope extends RouteScope> {
  routeComponents: RouteOptionsComponents<Scope>;
  layout: RouteLayout;
}

export const getRouteComponents = <Scope extends RouteScope>(
  options: Options<Scope>,
): AllRouteComponents => {
  const { layout, routeComponents } = options;

  switch (layout) {
    case RouteLayout.Unauthorized: {
      return getRouteComponentsForUnauthorizedLayout({ routeComponents });
    }

    case RouteLayout.Default: {
      return getRouteComponentsForDefaultLayout({ routeComponents });
    }

    default: {
      throw new UnhandledCaseError(layout);
    }
  }
};
