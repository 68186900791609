import { AppBar } from '@mui/material';
import styled from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

export const HeaderWrapper = styled(AppBar)<ThemedStyledComponent>`
  width: 100vw;
  z-index: ${({ theme }: ThemedStyledComponent) => theme.zIndex.drawer + 1};
  transition: ${({ theme }: ThemedStyledComponent) =>
    theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};
`;
