import {
  AtrigamEnvironment,
  AtrigamUniverseAreaTaskFlow,
  throwIfNullable,
} from '@atrigam/atrigam-types';

import { InsightsPageStore } from './Insights.page.store';

const createInsightsPageStoreFactory = () => {
  let environment: AtrigamEnvironment | undefined;
  let universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow | undefined;

  return {
    set: (options: {
      environment: AtrigamEnvironment;
      universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow;
    }) => {
      environment = options.environment;
      universeAreaTaskFlow = options.universeAreaTaskFlow;
    },

    create: () => {
      throwIfNullable('environment cannot be undefined', environment);
      throwIfNullable('universeAreaTaskFlow cannot be undefined', universeAreaTaskFlow);

      return new InsightsPageStore({
        environment,
        universeAreaTaskFlow,
      });
    },

    reset: () => {
      environment = undefined;
      universeAreaTaskFlow = undefined;
    },
  };
};

export const insightsPageStoreFactory = createInsightsPageStoreFactory();
