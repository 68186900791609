import {
  AtrigamNotificationType,
  AtrigamSubscriptionState,
  AtrigamUserNotification,
  AtrigamUserSubscription,
  UnhandledCaseError,
  createAtrigamUniverseAreaTaskFlow,
} from '@atrigam/atrigam-types';

import { EditWorkItemPath } from '@atrigam-webclient/routes/editWorkItem/editWorkItem.path';
import { SubscriptionsPath } from '@atrigam-webclient/routes/subscriptions/subscriptions.path';

interface Options {
  notification: AtrigamUserNotification;
  userSubscription?: AtrigamUserSubscription;
}

export const createLink = ({ notification, userSubscription }: Options) => {
  if (
    !notification.type ||
    !notification.environment ||
    !notification.flow ||
    !notification.area ||
    !notification.universe
  ) {
    return;
  }

  const universeAreaTaskFlow = createAtrigamUniverseAreaTaskFlow({
    universe: notification.universe,
    area: notification.area,
    taskFlow: notification.flow,
  });

  switch (notification.type) {
    case AtrigamNotificationType.ChatMessage: {
      if (!notification.workitemId) {
        return;
      }

      return EditWorkItemPath.getLink({
        params: {
          environment: notification.environment,
          universeAreaTaskFlow,
          node: notification.workitemId,
        },
        query: { tab: 'chat' },
      });
    }

    case AtrigamNotificationType.EmailSuppression:
    case AtrigamNotificationType.Digest:
    case AtrigamNotificationType.ModelError:
    case AtrigamNotificationType.TaskFlowHandOver: {
      return;
    }

    case AtrigamNotificationType.FieldNotUpdated:
    case AtrigamNotificationType.FieldUpdate:
    case AtrigamNotificationType.InviteStillPending: {
      if (!notification.workitemId) {
        return;
      }

      return EditWorkItemPath.getLink({
        params: {
          environment: notification.environment,
          universeAreaTaskFlow,
          node: notification.workitemId,
        },
        query: {},
      });
    }

    case AtrigamNotificationType.Invite: {
      if (
        userSubscription?.subscriptionState === AtrigamSubscriptionState.Accepted &&
        userSubscription?.node
      ) {
        return EditWorkItemPath.getLink({
          params: {
            environment: notification.environment,
            universeAreaTaskFlow,
            node: userSubscription.node,
          },
          query: {},
        });
      }

      return SubscriptionsPath.getLink({
        params: {
          environment: notification.environment,
          universeAreaTaskFlow,
        },
        query: {
          state: userSubscription?.subscriptionState ?? AtrigamSubscriptionState.Pending,
          subscriptionId: notification.workitemId,
          search: notification.workitemId,
        },
      });
    }

    case AtrigamNotificationType.RoleAssignment: {
      if (
        userSubscription?.subscriptionState === AtrigamSubscriptionState.Accepted &&
        userSubscription?.node
      ) {
        return EditWorkItemPath.getLink({
          params: {
            environment: notification.environment,
            universeAreaTaskFlow,
            node: userSubscription.node,
          },
          query: {},
        });
      }

      return SubscriptionsPath.getLink({
        params: {
          environment: notification.environment,
          universeAreaTaskFlow,
        },
        query: {
          state: userSubscription?.subscriptionState ?? AtrigamSubscriptionState.Pending,
          subscriptionId: notification.subscriptionId,
        },
      });
    }

    case AtrigamNotificationType.FlowDeployment: {
      return SubscriptionsPath.getLink({
        params: {
          environment: notification.environment,
          universeAreaTaskFlow,
        },
        query: {
          state: AtrigamSubscriptionState.Accepted,
        },
      });
    }

    default: {
      throw new UnhandledCaseError(notification.type);
    }
  }
};
