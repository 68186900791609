import {
  AtrigamEnvironment,
  AtrigamWorkItemId,
  AtrigamUniverseAreaTaskFlow,
} from '@atrigam/atrigam-types';

import { InteractionsMenuValue } from '@atrigam-webclient/pages/WorkItem/WorkItem.types';
import { RouteParameters, RouteQuery } from '@atrigam-webclient/services/Router/Router.types';
import { createRoutePath } from '@atrigam-webclient/services/Router/helpers/createRoutePath';

export interface Parameters extends RouteParameters {
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow;
}

export interface Query extends RouteQuery {
  tab?: InteractionsMenuValue;
}

export const pattern = '/:environment/:universeAreaTaskFlow/:node';

export const EditWorkItemPath = createRoutePath<Parameters, Query>({ pattern });
