import { ISODateTime, getDateTime } from '@atrigam/atrigam-types';
import React from 'react';

export interface TimerPeriods {
  timeout: number; // in seconds
  until: number; // in seconds
}

interface Properties {
  date?: ISODateTime;
  timerPeriods: TimerPeriods[];
}

// const TIME_SECOND = 1;
// const TIME_MINUTE = 60 * TIME_SECOND;
// const timerPeriods = [
//   { timeout: TIME_SECOND, until: TIME_MINUTE },
//   { timeout: TIME_MINUTE, until: 15 * TIME_MINUTE },
//   { timeout: TIME_MINUTE * 15, until: TIME_MINUTE * 120 },
// ];

export const useTimer = ({ date, timerPeriods }: Properties) => {
  const timeoutReference = React.useRef<number>();
  const [lastUpdate, setLastUpdate] = React.useState('');
  const [seconds, setSeconds] = React.useState(0);

  // update last update and run timer
  const updateLastUpdate = React.useCallback(() => {
    clearTimeout(timeoutReference.current);

    if (!date) {
      setLastUpdate('');

      return;
    }

    setLastUpdate(date ? getDateTime(date).toRelative() ?? '' : '');

    const dateTime = getDateTime(date);

    const diff = dateTime.diffNow('seconds');
    setSeconds(diff.seconds > 60 ? 61 : diff.seconds);

    const period = timerPeriods.find(({ until }) => until > Math.abs(diff.seconds));

    // no period found, nothing to do
    if (!period) {
      return;
    }

    // counting
    timeoutReference.current = window.setTimeout(updateLastUpdate, period.timeout * 1000);
  }, [date, timerPeriods]);

  // on any date change, update
  React.useEffect(() => {
    updateLastUpdate();
  }, [date, updateLastUpdate]);

  // clear timeout when leaving
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutReference.current);
    };
  }, []);

  return { lastUpdate, seconds };
};
