import { withReaction } from '@atrigam-webclient/helpers/withReaction';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { WorkItemPageStore } from '../../WorkItem.page.store';

export const watchForFlowUpdates = (interactionsPageStore: WorkItemPageStore) => {
  const modelsStore = Registry.get('modelsStore');
  const universeAreaTaskFlow = interactionsPageStore.universeAreaTaskFlow;

  const disposer = withReaction({
    name: 'InteractionsPageStore.watchForFlowUpdates',
    data: () => ({
      taskFlowModel: modelsStore.getTaskFlowModel({ universeAreaTaskFlow }),
    }),
    onChange: ({ taskFlowModel }) => {
      if (!taskFlowModel) {
        return;
      }

      if (
        taskFlowModel.version &&
        taskFlowModel.version !== interactionsPageStore.taskFlowModel.version
      ) {
        interactionsPageStore.setTaskFlowModelUpdate(taskFlowModel);
      }
    },
  });

  interactionsPageStore.disposers.push(disposer);
};
