import { AtrigamModelsModelRolesRole, AtrigamRole } from '@atrigam/atrigam-types';
import { makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export class RoleModelEntity {
  @persist('list')
  @observable
  assignedBy: AtrigamRole[] = [];

  @persist
  @observable
  name?: string;

  @persist
  @observable
  role?: AtrigamRole;

  @persist
  @observable
  onlyImplicit = false; // used for subitem

  constructor(data?: AtrigamModelsModelRolesRole) {
    makeObservable(this);

    if (data) {
      this.assignedBy = Object.values(data.assignedBy);
      this.name = data.name;
      this.role = data.role;
      this.onlyImplicit = data.onlyImplicit ?? false;
    }
  }
}
