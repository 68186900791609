import { AtrigamModelsModelFlow, AtrigamRole } from '@atrigam/atrigam-types';
import { computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { InsightsRoleEntity } from './InsightsRole.entity';

export class InsightsEntity {
  @persist('map', InsightsRoleEntity)
  @observable
  roles = observable.map<string, InsightsRoleEntity>();

  @persist
  @observable
  version?: string;

  constructor(flowData?: AtrigamModelsModelFlow) {
    makeObservable(this);

    if (flowData) {
      this.roles.clear();
      if (flowData.insights) {
        const { version, ...insightData } = flowData.insights;

        Object.entries(insightData).forEach(([role, roleData]) => {
          this.roles.set(role, new InsightsRoleEntity({ role: role as AtrigamRole, roleData }));
        });

        this.version = version;
      }
    }
  }

  @computed
  get hasInsights() {
    if (this.roles.size === 0) {
      return false;
    }

    return [...this.roles.values()].some((model) => model.hasBlocks);
  }

  @computed
  get hasFilters() {
    if (this.roles.size === 0) {
      return false;
    }

    return [...this.roles.values()].some((model) => model.hasFilters);
  }
}
