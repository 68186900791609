import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';

import { GenericButton, GenericButtonProperties } from './GenericButton';

const TertiaryButtonComponent: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  GenericButtonProperties
> = ({ variant = 'text', ...properties }, reference) => {
  return <GenericButton color="tertiary" {...properties} ref={reference} variant={variant} />;
};

export const TertiaryButton = reactive(React.forwardRef(TertiaryButtonComponent));
