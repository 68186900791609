import { AtrigamNotificationType } from '@atrigam/atrigam-types';
import { Box, Chip, Toolbar, useTheme } from '@mui/material';
import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { AtrigamTheme } from '@atrigam-webclient/typings/atrigamTheme';

import { getLabel } from './helpers/getLabel';

const UserNotificationsFilterComponent: React.FC = () => {
  const { notificationTypes, toggleFilter } = Registry.get('userNotificationsStore');

  const theme = useTheme<AtrigamTheme>();

  const onClick = React.useCallback(
    (type: AtrigamNotificationType | null) => () => toggleFilter(type),
    [toggleFilter],
  );

  return (
    <Toolbar
      sx={{ backgroundColor: theme.palette.background.default }}
      variant="dense"
      data-testid="user-notifications-filter-toolbar"
    >
      {notificationTypes.map(({ type, isActive }) => (
        <Box margin={1} key={type} display="inline-block">
          <Chip
            color="secondary"
            label={getLabel(type)}
            variant={isActive ? 'filled' : 'outlined'}
            size="small"
            onClick={onClick(type)}
            data-testid={`user-notifications-filter-${type}`}
          />
        </Box>
      ))}
    </Toolbar>
  );
};

export const UserNotificationsFilter = reactive(UserNotificationsFilterComponent);
