import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import { ErrorInfo } from 'react';

import { sentry } from '@atrigam-webclient/services/Sentry/helpers/initializeSentry';

export const handleErrorBoundaryError = (error: Error, info: ErrorInfo) => {
  sentry.log({
    context: {
      componentStack: (info.componentStack ?? '').trim(),
    },
    error,
  });

  try {
    void track({
      event: AtrigamAnalyticEvents.APP_ErrorBoundary,
      screen: AtrigamAnalyticScreens.App,
      errorMessage: error.message,
      error,
    });
  } catch {
    // catch all if it does not work
  }
};
