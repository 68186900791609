import { AtrigamModelsModelFlowStep } from '@atrigam/atrigam-types';
import { makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { sortBySequence } from '@atrigam-webclient/stores/ModelsStore/helpers/sortBySequence';

import { InteractionEntity } from './Interaction.entity';

export class StepEntity {
  @persist
  @observable
  name?: string;

  @persist
  @observable
  sequence = 0;

  @persist('list', InteractionEntity)
  @observable
  interactions: InteractionEntity[] = [];

  constructor(stepData?: AtrigamModelsModelFlowStep) {
    makeObservable(this);

    if (stepData) {
      this.name = stepData.name;
      this.sequence = stepData.sequence;

      this.interactions = [];
      if (stepData.interactions) {
        this.interactions = Object.values(stepData.interactions)
          .sort(sortBySequence)
          .map((interactionData) => new InteractionEntity(interactionData));
      }
    }
  }
}
