import { Email } from '@atrigam/atrigam-types';

interface AcronymUser {
  firstName?: string;
  lastName?: string;
  email?: Email;
}

export const createAcronymForUser = ({ firstName, lastName, email }: AcronymUser) => {
  // only firstname present
  if (firstName && !lastName) {
    return firstName?.slice(0, 2).toUpperCase();
  }

  // only lastname present
  if (lastName && !firstName) {
    return lastName?.slice(0, 2).toUpperCase();
  }

  // full name present
  if (firstName && lastName) {
    return [firstName.charAt(0) ?? '', lastName.charAt(0) ?? ''].join('').toUpperCase();
  }

  // no name, try email
  if (email) {
    const emailWithoutDomain = email.slice(0, Math.max(0, email.indexOf('@'))).split('.');

    // no dot
    if (emailWithoutDomain.length === 1) {
      return emailWithoutDomain[0].slice(0, 2).toUpperCase();
    }

    return emailWithoutDomain
      .map((part) => part.charAt(0))
      .join('')
      .toUpperCase()
      .slice(0, 2);
  }

  // default nothing found
  return '?*';
};
