import { UID, getDay } from '@atrigam/atrigam-types';

import { createAcronymForUser } from '@atrigam-webclient/helpers/createAcronymForUser';

import { ChatMessage, ChatMessagesDateGroup, ChatMessagesUserGroup } from '../Chat.types';

interface Options {
  messages: ChatMessage[];
  currentUserUid: UID;
}

export const groupChatMessages = ({
  messages,
  currentUserUid,
}: Options): ChatMessagesDateGroup[] => {
  const groupedByDate: Record<string, ChatMessagesDateGroup> = {};

  let userGroup: ChatMessagesUserGroup | undefined;

  // group messages
  messages.forEach((message) => {
    // 1. check if we already have the day, prepare if not
    const day = getDay(message.createdAt);
    if (!groupedByDate[day]) {
      groupedByDate[day] = {
        day,
        date: message.createdAt,
        userMessages: [],
      };

      // reset userGroup
      userGroup = undefined;
    }

    // 2. check if user has changed or we don't have any
    if (!userGroup || message.user._id !== userGroup.user._id) {
      const userInitials = !message.user?.name
        ? ''
        : createAcronymForUser({ firstName: message.user.name });

      userGroup = {
        id: `${message.user._id}-${message._id}`, // create a id for react that will always create the same result
        isMe: message.user._id === currentUserUid,
        user: message.user,
        userInitials,
        messages: [],
      };
      groupedByDate[day].userMessages.push(userGroup);
    }

    userGroup.messages.push(message);
  });

  // convert objects into arrays for react
  return Object.values(groupedByDate);
};
