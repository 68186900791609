import { VerifyEmailTokenPageAsync } from '@atrigam-webclient/pages/VerifyEmailToken/VerifyEmailToken.page.async';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { RouteScope } from '@atrigam-webclient/services/Router/Router.types';
import { createRedirect } from '@atrigam-webclient/services/Router/helpers/createRedirect';
import { createRoute } from '@atrigam-webclient/services/Router/helpers/createRoute';

import { pattern } from './verifyEmailToken.path';

export const VerifyEmailTokenRoute = createRoute({
  title: () => translate('routes.verifyEmailToken.title'),
  components: {
    main: VerifyEmailTokenPageAsync,
  },
  pattern,
  scope: RouteScope.Public,
  meta: {
    smallLogo: true,
  },
  canEnter: () => {
    // is the user already verified and loggedIn?
    const { user } = Registry.get('userStore');
    if (user?.isEmailVerified) {
      return createRedirect({
        name: 'VerifyEmailRoute',
        params: {},
      });
    }

    return true;
  },
});
