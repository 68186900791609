import { AtrigamEnvironment } from '@atrigam/atrigam-types';

interface Options {
  currentEnvironment: AtrigamEnvironment;
  environments: AtrigamEnvironment[];
}

export const getEnvironmentOptions = ({ environments, currentEnvironment }: Options) => {
  const options = [];

  if (
    environments.includes(AtrigamEnvironment.Production) ||
    currentEnvironment === AtrigamEnvironment.Production
  ) {
    options.push({
      value: AtrigamEnvironment.Production,
      label: translate('layouts.DefaultLayout.Navigation.EnvironmentSwitcher.Production'),
    });
  }

  if (
    environments.includes(AtrigamEnvironment.Staging) ||
    currentEnvironment === AtrigamEnvironment.Staging
  ) {
    options.push({
      value: AtrigamEnvironment.Staging,
      label: translate('layouts.DefaultLayout.Navigation.EnvironmentSwitcher.Staging'),
    });
  }

  if (
    environments.includes(AtrigamEnvironment.Testing) ||
    currentEnvironment === AtrigamEnvironment.Testing
  ) {
    options.push({
      value: AtrigamEnvironment.Testing,
      label: translate('layouts.DefaultLayout.Navigation.EnvironmentSwitcher.Testing'),
    });
  }

  return options;
};
