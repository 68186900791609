/* eslint-disable unicorn/prefer-module */
import { UnhandledCaseError } from '@atrigam/atrigam-types';

import { Language } from '@atrigam-webclient/typings/language';
import { Translations } from '@atrigam-webclient/typings/translations';
import { Writeable } from '@atrigam-webclient/typings/utils';

export const collectTranslations = (language: Language) => {
  const filesEn = import.meta.glob('../../../**/en.json', { eager: true });
  const filesDe = import.meta.glob('../../../**/de.json', { eager: true });

  switch (language) {
    case Language.en: {
      return collectFiles({ files: filesEn }) as Writeable<Translations>;
    }
    case Language.de: {
      return collectFiles({ files: filesDe }) as Writeable<Translations>;
    }
    default: {
      throw new UnhandledCaseError(language);
    }
  }
};

const collectFiles = (options: { files: Record<string, unknown> }) => {
  const { files } = options;

  const translations: Translations = {} as Translations;

  // eslint-disable-next-line guard-for-in
  for (const key in files) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    Object.assign(translations, (files[key] as any).default);
  }

  return translations;
};
