import { AnyDate, getDateTime } from '@atrigam/atrigam-types';

export const getRelativeDate = (date: AnyDate) => {
  let relativeDate = getDateTime(date).toRelativeCalendar();

  if (relativeDate === 'today' || relativeDate === 'heute') {
    relativeDate = getDateTime(date).toFormat(translate('app.date.formatToday'));
  }

  return relativeDate;
};
