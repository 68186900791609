import { configure as configureMobX } from 'mobx';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app/App/App';
import { sentryConfiguration } from './configuration/sentry.config';
import { IS_DEV } from './mode';
import { initializeCookieListener } from './services/Cookies/initializeCookieListener';
import { initializeFirebase } from './services/FireBase/initializeFirebase';
import { Registry } from './services/Registry/Registry';
import { initializeRegistry } from './services/Registry/initializeRegistry';
import { initializeSentry } from './services/Sentry/helpers/initializeSentry';
import { initializeTracking } from './services/Tracking/initializeTracking';

// initialize Sentry first to catch all errors
initializeSentry(IS_DEV ? false : sentryConfiguration);

initializeFirebase();

initializeTracking({
  appVersion: window.__GLOBAL_STATE__.appVersion,
});

initializeRegistry();

initializeCookieListener({});

const initializeMobx = () => {
  // configure mobx
  // @see https://mobx.js.org/configuration.html
  configureMobX({
    enforceActions: 'observed',
    // todo disabled for now, need to investigate
    // computedRequiresReaction: true,
    // reactionRequiresObservable: true,
    // observableRequiresReaction: true,
    // disableErrorBoundaries: true,
  });
};

initializeMobx();

const render = () => {
  // initialize translations
  Registry.get('translations');

  // initialize push notifications listeners
  Registry.get('pushNotifications');

  // watch app for if it is locked
  Registry.get('appStore');

  // make sure user is there
  Registry.get('userStore');
  Registry.get('userNotificationsStore');

  // make sure environment is loaded from storage
  Registry.get('modelsStore');

  // load fxRates
  Registry.get('exchangeRate');

  // start router
  Registry.get('router').start();

  const container = document.querySelector('#root');
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};

render();
