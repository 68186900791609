import {
  getActiveState,
  AtrigamFirestoreUniverseKpi,
  AtrigamRestrictionActiveState,
  AtrigamWorkItem,
} from '@atrigam/atrigam-types';

import { InteractionEntity } from '@atrigam-webclient/stores/ModelsStore/entities/taskFlow/Interaction.entity';

interface Options {
  interaction: InteractionEntity;
  universeKpisMap: Record<AtrigamFirestoreUniverseKpi['id'], AtrigamFirestoreUniverseKpi['result']>;
  workItem?: AtrigamWorkItem;
}

export const isInteractionDisabled = ({ interaction, universeKpisMap, workItem }: Options) => {
  const activeState = getActiveState({
    restrictions: interaction.restrictions?.restrictions,
    activeState: interaction.restrictions?.activeState,
    universeKpisMap,
    workItem,
  });

  return activeState !== AtrigamRestrictionActiveState.Active;
};
