import { CircularProgress } from '@mui/material';
import React from 'react';

import { LoadingWrapper } from './styles/LoadingWrapper.style';

interface Properties {
  size?: number;
}

const LazyPlaceholderComponent: React.ForwardRefRenderFunction<HTMLDivElement, Properties> = (
  { size = 14 },
  reference,
) => (
  <LoadingWrapper ref={reference}>
    <CircularProgress size={size} />
  </LoadingWrapper>
);

export const LazyPlaceholder = React.forwardRef(LazyPlaceholderComponent);
