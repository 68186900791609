import { AtrigamUniverseRegistrationClientRole } from '@atrigam/atrigam-types';

interface Options {
  before: AtrigamUniverseRegistrationClientRole[];
  after: AtrigamUniverseRegistrationClientRole[];
}

export const diffClientRoleObjects = ({ before, after }: Options) => {
  const changes: AtrigamUniverseRegistrationClientRole[] = [];

  before.forEach((beforeUserRole) => {
    if (
      !after.some(
        (afterUserRole) =>
          beforeUserRole.area === afterUserRole.area &&
          beforeUserRole.environment === afterUserRole.environment &&
          beforeUserRole.flow === afterUserRole.flow &&
          beforeUserRole.role === afterUserRole.role &&
          beforeUserRole.universe === afterUserRole.universe,
      )
    ) {
      changes.push(beforeUserRole);
    }
  });

  after.forEach((afterUserRole) => {
    if (
      !before.some(
        (beforeUserRole) =>
          beforeUserRole.area === afterUserRole.area &&
          beforeUserRole.environment === afterUserRole.environment &&
          beforeUserRole.flow === afterUserRole.flow &&
          beforeUserRole.role === afterUserRole.role &&
          beforeUserRole.universe === afterUserRole.universe,
      )
    ) {
      changes.push(afterUserRole);
    }
  });

  return changes;
};
