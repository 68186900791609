import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import { Warning as WarningIcon } from '@mui/icons-material';
import React from 'react';

import { isBrowserSupported } from '@atrigam-webclient/helpers/isBrowserSupported';
import { reactive } from '@atrigam-webclient/helpers/reactive';
import { Banner } from '@atrigam-webclient/primitive/Banner/Banner';
import { GenericButton } from '@atrigam-webclient/primitive/Button/GenericButton';

interface Properties {
  appBar: boolean;
}

const UnsupportedBrowserBannerComponent: React.FC<Properties> = ({ appBar }) => {
  const browserIsSupported = React.useMemo(() => isBrowserSupported(), []);

  React.useEffect(() => {
    if (!browserIsSupported) {
      void track({
        event: AtrigamAnalyticEvents.LOGIN_UnsupportedBrowser,
        screen: AtrigamAnalyticScreens.Login,
      });
    }
  }, [browserIsSupported]);

  if (browserIsSupported) {
    return null;
  }

  return (
    <Banner
      icon={<WarningIcon />}
      message={translate('patterns.UnsupportedBrowserBanner.message')}
      variation="warning"
      appBar={appBar}
      actions={({ onClose }) => (
        <>
          <GenericButton onClick={onClose} variant="outlined">
            {translate('app.close')}
          </GenericButton>

          {/* <Box marginLeft={1} component="span">
            <PrimaryButton href="https://browser-update.org/update-browser.html" target="blank">
              {translate('patterns.UnsupportedBrowserBanner.update')}
            </PrimaryButton>
          </Box> */}
        </>
      )}
    />
  );
};

export const UnsupportedBrowserBanner = reactive(UnsupportedBrowserBannerComponent);
