interface Options {
  key: string;
  value: string;
  ttl: number;
}

export interface ValueWithExpiry {
  value: string;
  expiry: number;
}

export function setWithExpiry({ key, value, ttl }: Options) {
  const item: ValueWithExpiry = {
    value,
    expiry: Date.now() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}
