import {
  AtrigamDataType,
  AtrigamModelsModelObjectsObjectData,
  AtrigamWorkItem,
} from '@atrigam/atrigam-types';

interface Options {
  before: AtrigamWorkItem | undefined;
  after: Partial<AtrigamWorkItem> | undefined;
  workItemModel?: AtrigamModelsModelObjectsObjectData;
}

const IGNORE_FIELD_NAMES = new Set(['id', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy']);

// see which fields changed
export const getChangedFieldNames = ({ before, after, workItemModel }: Options) => {
  const changedFields = [];
  for (const key in after) {
    // ignore meta fieldnames
    if (IGNORE_FIELD_NAMES.has(key)) {
      continue;
    }

    // check if dataType is to be ignored
    if (workItemModel?.[key]?.type) {
      const dataType = workItemModel[key].type;

      // ignore open durations
      if (dataType === AtrigamDataType.OpenDuration) {
        continue;
      }
    }

    // initially set values
    if (!before || typeof before === 'undefined' || typeof before[key] === 'undefined') {
      changedFields.push(key);
    } else {
      // console.log(key, before[key], after[key]);
      if (
        (typeof before[key] === 'string' || typeof after[key] === 'string') &&
        before[key] !== after[key]
      ) {
        // console.log("field found!!! ", key, before[key], after[key]);
        changedFields.push(key);
      }
      if (
        (typeof before[key] === 'number' || typeof after[key] === 'number') &&
        before[key] !== after[key]
      ) {
        // console.log("field found!!! ", key, before[key], after[key]);
        changedFields.push(key);
      }
      if (
        (typeof before[key] === 'object' || typeof after[key] === 'object') &&
        sortedStringify(before[key]) !== sortedStringify(after[key])
      ) {
        // console.log("field found!!! ", key, before[key], after[key]);
        changedFields.push(key);
      }
      if (
        (typeof before[key] === 'boolean' || typeof after[key] === 'boolean') &&
        before[key] !== after[key]
      ) {
        // console.log("field found!!! ", key, before[key], after[key]);
        changedFields.push(key);
      }
    }
  }
  return changedFields;
};

const sortedStringify = (data: unknown) => {
  if (data === null || typeof data !== 'object') {
    return JSON.stringify(data);
  }

  return JSON.stringify(data, Object.keys(data).sort());
};
