import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { UnsupportedBrowserBanner } from '@atrigam-webclient/patterns/UnsupportedBrowserBanner/UnsupportedBrowserBanner';
import { AtrigamTheme } from '@atrigam-webclient/typings/atrigamTheme';

import { EmailVerificationBanner } from './features/EmailVerificationBanner/EmailVerificationBanner';
import { NavigationToggle } from './features/NavigationToggle/NavigationToggle';
import { HeaderWrapper } from './styles/HeaderWrapper.style';

const HeaderComponent: React.FC = () => {
  const theme = useTheme<AtrigamTheme>();
  const breakpointNavigation = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box display="flex" flexDirection="column">
      <HeaderWrapper id="app-bar" position="fixed" color="transparent" elevation={0}>
        {!breakpointNavigation && <NavigationToggle />}
      </HeaderWrapper>

      <EmailVerificationBanner />
      <UnsupportedBrowserBanner appBar={true} />
    </Box>
  );
};

export const Header = reactive(HeaderComponent);
