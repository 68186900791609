import { UnhandledCaseError } from '@atrigam/atrigam-types';

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;
const QUARTER = MONTH * 3;
const YEAR = MONTH * 12;

interface Properties {
  seconds: number;
}

export const getUnitFromSeconds = ({ seconds }: Properties): Intl.RelativeTimeFormatUnit => {
  const absValue = Math.abs(seconds);

  if (absValue < MINUTE) {
    return 'second';
  }

  if (absValue < HOUR) {
    return 'minute';
  }

  if (absValue < DAY) {
    return 'hour';
  }
  if (absValue < MONTH) {
    return 'day';
  }
  if (absValue < YEAR) {
    return 'month';
  }
  return 'year';
};

export const calculateValue = ({
  seconds,
  unit,
}: {
  seconds: number;
  unit: Intl.RelativeTimeFormatUnit;
}) => {
  switch (unit) {
    case 'second':
    case 'seconds': {
      return seconds;
    }

    case 'minute':
    case 'minutes': {
      return Math.round(seconds / MINUTE);
    }

    case 'hour':
    case 'hours': {
      return Math.round(seconds / HOUR);
    }

    case 'day':
    case 'days': {
      return Math.round(seconds / DAY);
    }

    case 'week':
    case 'weeks': {
      return Math.round(seconds / WEEK);
    }

    case 'month':
    case 'months': {
      return Math.round(seconds / MONTH);
    }

    case 'quarter':
    case 'quarters': {
      return Math.round(seconds / QUARTER);
    }

    case 'year':
    case 'years': {
      return Math.round(seconds / YEAR);
    }

    default: {
      throw new UnhandledCaseError(unit);
    }
  }
};
