import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';

import { withReaction } from '@atrigam-webclient/helpers/withReaction';

import { PushNotificationsService } from '../PushNotifications.service';

export const watchPermissionAndUserUid = (service: PushNotificationsService) => {
  withReaction({
    name: 'PushNotificationService.watchPermissionAndUserUid',
    data: () => ({
      permission: service.permission,
      userUid: service.userUid,
      isEnabled: service.isEnabled,
    }),
    onChange: ({ permission, userUid, isEnabled }) => {
      // track if user has denied the permissions
      if (isEnabled && userUid && permission === 'denied') {
        void track({
          event: AtrigamAnalyticEvents.NOTIFICATION_FCMNotificationPermissionDenied,
          screen: AtrigamAnalyticScreens.Notification,
        });
      }

      if (!isEnabled || permission !== 'granted' || !userUid) {
        return;
      }

      void service.refreshToken();
    },
  });
};
