import { DestructibleService } from '@atrigam/atrigam-service-registry';
import {
  AtrigamFirestoreUserSubscriptionsUserEnvironmentFlowDocumentCounts,
  AtrigamWorkItemId,
  AtrigamSubscriptionState,
  AtrigamUniverseAreaTaskFlow,
  AtrigamUserSubscription,
  AtrigamWorkItem,
  createAtrigamDatabaseUniverseEnvironment,
  createAtrigamUniverseAreaTaskFlow,
  extractAtrigamUniverseAreaTaskFlow,
  throwIfNullable,
} from '@atrigam/atrigam-types';
import algoliasearch from 'algoliasearch';
import { action, computed, makeObservable, observable, values } from 'mobx';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { canUserCreateWorkItem } from '@atrigam-webclient/stores/ModelsStore/helpers/canUserCreateWorkItem';

import { SubscriptionEntity } from './entities/Subscription.entity';
import { hasMoreSubscriptions } from './helpers/hasMoreSubscriptions';
import { sortSubscriptions } from './helpers/sortSubscriptions';
import { syncSubscriptionsUrl } from './helpers/syncSubscriptionsUrl';
import { watchSubscriptions } from './helpers/watchSubscriptions';
import { watchUserSubscriptionCounts } from './helpers/watchUserSubscriptionCounts';

const client = algoliasearch('5TK94KW2FP', 'a66129fbee49558ae2df02470693627f');
const userSubscriptionIndex = client.initIndex('test_UserSubscriptions');

export class SubscriptionsStore extends DestructibleService {
  @observable
  hasSearchResults = false;

  @observable
  isLoading = false;

  @observable
  isEnabled = false;

  @observable
  isSearching = false;

  @observable
  lastVisitedNode: AtrigamWorkItemId | undefined;

  @observable
  limit = 25;

  @observable
  page = 0;

  @observable
  searchString = '';

  @observable
  subscriptionState: AtrigamSubscriptionState = AtrigamSubscriptionState.Pending;

  @observable
  subscriptionCounts: AtrigamFirestoreUserSubscriptionsUserEnvironmentFlowDocumentCounts = {
    accepted: 0,
    archived: 0,
    pending: 0,
  };

  @observable
  universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow | undefined;

  @observable
  watchSubscriptionsDisposer?: () => void;

  @observable
  private _searchResult = observable.map<string, SubscriptionEntity>();

  @observable
  private _subscriptions = observable.map<string, SubscriptionEntity>();

  constructor() {
    super();

    makeObservable(this);

    syncSubscriptionsUrl(this);
  }

  @computed
  get canCreate() {
    if (!this.currentTaskFlowModel) {
      return false;
    }

    return canUserCreateWorkItem({
      environment: this.environment,
      flowModel: this.currentTaskFlowModel,
    });
  }

  @computed
  get currentTaskFlowModel() {
    if (!this.universeAreaTaskFlow) {
      return;
    }

    const taskFlowModel = Registry.get('modelsStore').getTaskFlowModel({
      universeAreaTaskFlow: this.universeAreaTaskFlow,
    });
    return taskFlowModel;
  }

  @computed
  get currentSubscriptionCount() {
    // return max
    if (!this.subscriptionState) {
      return this.subscriptionCounts
        ? (this.subscriptionCounts.accepted ?? 0) +
            (this.subscriptionCounts.archived ?? 0) +
            (this.subscriptionCounts.pending ?? 0)
        : 0;
    }

    return this.subscriptionCounts ? this.subscriptionCounts[this.subscriptionState] ?? 0 : 0;
  }

  @computed
  get environment() {
    return Registry.get('modelsStore').environment;
  }

  @computed
  get hasMore() {
    // no more if we are searching
    if (this.hasSearchResults || this.isSearching) {
      return false;
    }

    const subscriptionCount = this.currentSubscriptionCount;
    const loadedCount = this.subscriptions.length;

    return hasMoreSubscriptions({
      limit: this.limit,
      loadedCount,
      subscriptionCount,
    });
  }

  @computed
  get clientRolesForTaskFlow() {
    if (!this.currentTaskFlowModel) {
      return [];
    }

    const { area, taskFlow, universe } = this.currentTaskFlowModel;

    if (!area || !taskFlow || !universe) {
      return [];
    }

    return Registry.get('userStore').userClientRoles.getRolesForTaskFlow({
      environment: this.environment,
      area,
      taskFlow,
      universe,
    });
  }

  @computed
  get lastSubscription() {
    if (this.subscriptions.length === 0) {
      return;
    }

    return this.subscriptions.at(-1)?.userSubscription ?? undefined;
  }

  @computed
  get subscriptions() {
    return values(this.hasSearchResults ? this._searchResult : this._subscriptions)
      .filter((subscription) => {
        // filter state
        if (
          this.subscriptionState &&
          subscription.userSubscription.subscriptionState !== this.subscriptionState
        ) {
          return false;
        }

        // filter user profile
        if (subscription.isAtrigamUserProfile) {
          return false;
        }

        return true;
      })
      .sort(sortSubscriptions);
  }

  @action
  addWorkItem = ({ node, workItem }: { node: AtrigamWorkItemId; workItem: AtrigamWorkItem }) => {
    const subscription = this._subscriptions.get(node);
    if (!subscription) {
      return;
    }

    subscription.updateWorkItem(workItem);
  };

  @action
  getSubscription = ({ node }: { node: AtrigamWorkItemId }) => {
    if (this.hasSearchResults) {
      return this._searchResult.get(node);
    }

    return this._subscriptions.get(node);
  };

  @action
  loadSubscriptions = (
    subscriptionsWithWorkItems: {
      subscription: AtrigamUserSubscription;
      workItem?: AtrigamWorkItem;
    }[],
  ) => {
    this._subscriptions.clear();
    subscriptionsWithWorkItems.forEach(({ subscription, workItem }) => {
      this._subscriptions.set(
        subscription.node,
        new SubscriptionEntity({
          node: subscription.node,
          subscription,
          workItem,
        }),
      );
    });
  };

  @action
  removeSubscription = (subscription: AtrigamUserSubscription) => {
    this._subscriptions.delete(subscription.node);
  };

  @action
  reset = () => {
    this._subscriptions.clear();
    this.setLastVisitedNode();

    this._searchResult.clear();
    this.hasSearchResults = false;
    this.isSearching = false;
    this.setPage(0);
    void this.setSearchString('');

    this.universeAreaTaskFlow = undefined;
    this.subscriptionState = AtrigamSubscriptionState.Pending;
    this.setIsLoading(false);
    this.subscriptionCounts = { accepted: 0, archived: 0, pending: 0 };
  };

  @action
  resetLimit = () => {
    this.setPage(0);

    this._searchResult.clear();
    this.hasSearchResults = false;
    this.isSearching = false;
  };

  @action
  setFinishedLoading = () => {
    this._subscriptions.forEach((subscription) => subscription.setFinishedLoading());
  };

  @action
  setIsLoading = (loading: boolean) => {
    if (!loading) {
      this.setFinishedLoading();
    }

    this.isLoading = loading;
  };

  @action
  setLastVisitedNode = (node?: AtrigamWorkItemId) => {
    this.lastVisitedNode = node;
  };

  @action
  setPage = (page: number) => {
    this.page = page;
  };

  @action
  setSearchString = async (search: string) => {
    this.searchString = search;
    this.resetLimit();

    if (search.length > 0) {
      await this.startSearch();
    }
  };

  @action
  setSubscriptionState = (state: AtrigamSubscriptionState) => {
    if (this.subscriptionState === state) {
      return;
    }

    this.subscriptionState = state;
    this._subscriptions.clear();
    this.setLastVisitedNode();
    this.resetLimit();
    this.setIsLoading(true);
  };

  @action
  setUniverseAreaTaskFlow = (universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow) => {
    this.universeAreaTaskFlow = universeAreaTaskFlow;
  };

  @action
  setWatchSubscriptionsDisposer = (disposer?: () => void) => {
    this.watchSubscriptionsDisposer = disposer;
  };

  @action
  startWatchers = () => {
    // already running
    if (this.isEnabled) {
      return;
    }

    this.isEnabled = true;
    watchSubscriptions(this);
    watchUserSubscriptionCounts(this);
  };

  @action
  stopWatchers = () => {
    this.isEnabled = false;
    // not a nice workaround but stay with me
    this._destruct();
  };

  @action
  syncWithInteraction = ({
    userSubscription,
    workItem,
  }: {
    userSubscription: AtrigamUserSubscription;
    workItem: AtrigamWorkItem;
  }) => {
    // are we looking at a different universeAreaTaskFlow, then reset everything
    const universeAreaTaskFlow = createAtrigamUniverseAreaTaskFlow({
      area: userSubscription.area,
      taskFlow: userSubscription.taskFlow,
      universe: userSubscription.universe,
    });
    if (this.universeAreaTaskFlow !== universeAreaTaskFlow) {
      this.reset();
      this.setUniverseAreaTaskFlow(universeAreaTaskFlow);
    }

    // else do we have search results?
    if (this.hasSearchResults) {
      // is the subscription within the search result?
      const searchResult = this._searchResult.get(userSubscription.node);
      if (searchResult) {
        searchResult.updateSubscription(userSubscription);
        searchResult.updateWorkItem(workItem);
        //
        return;
      } else {
        // userSubscription is not in the search result, clear search
        void this.setSearchString('');
      }
    }

    // reset subscriptions
    if (!this._subscriptions.has(userSubscription.node)) {
      this.loadSubscriptions([{ subscription: userSubscription, workItem }]);
      return;
    }

    // update with our workitem
    this.updateSubscription(userSubscription);
    this.addWorkItem({ workItem, node: userSubscription.node });
  };

  @action
  updateSubscription = (subscription: AtrigamUserSubscription) => {
    const model = this._subscriptions.get(subscription.node);
    if (model) {
      model.updateSubscription(subscription);
      return;
    }

    this._subscriptions.set(
      subscription.node,
      new SubscriptionEntity({
        node: subscription.node,
        subscription,
      }),
    );
  };

  @action
  updateSubscriptionCounts = (
    counts?: AtrigamFirestoreUserSubscriptionsUserEnvironmentFlowDocumentCounts,
  ) => {
    this.subscriptionCounts = {
      accepted: counts?.accepted ?? 0,
      archived: counts?.archived ?? 0,
      pending: counts?.pending ?? 0,
    };
  };

  @action
  private startSearch = async () => {
    if (
      this.searchString.trim().length === 0 ||
      !this.currentTaskFlowModel ||
      !this.universeAreaTaskFlow
    ) {
      return;
    }

    const { uid } = Registry.get('userStore');

    const extracted = extractAtrigamUniverseAreaTaskFlow(this.universeAreaTaskFlow);

    const databaseUniverseEnvironment = createAtrigamDatabaseUniverseEnvironment({
      universe: extracted.universe,
      environment: this.environment,
    });

    const objectName = this.currentTaskFlowModel.objectName;
    throwIfNullable('Object Name cannot be undefined', objectName);

    this.isSearching = true;

    interface SearchResult {
      userSubscription: AtrigamUserSubscription;
      workItem: AtrigamWorkItem;
      subscriptionState: AtrigamSubscriptionState;
    }

    const responses = await userSubscriptionIndex.search<SearchResult>(this.searchString, {
      hitsPerPage: 50,
      facetFilters: [
        `_AtrigamUserUid:${uid}`,
        `subscriptionState:${this.subscriptionState}`,
        `universeDB:${databaseUniverseEnvironment}`,
        `objectName:${objectName}`,
      ],
      allowTyposOnNumericTokens: false,
    });

    // Response from Algolia:
    // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
    responses.hits.forEach((hit) => {
      const model = new SubscriptionEntity({
        node: hit.userSubscription.node,
        subscription: hit.userSubscription,
        workItem: hit.workItem,
      });
      model.setFinishedLoading();

      this._searchResult.set(hit.userSubscription.node, model);
    });
    this.hasSearchResults = true;

    this.isSearching = false;
  };
}
