import {
  AtrigamInteractionType,
  AtrigamRestrictionActiveState,
  AtrigamWorkItem,
  getActiveState,
} from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable } from 'mobx';

import { TaskFlowModelEntity } from '@atrigam-webclient/stores/ModelsStore/entities/TaskFlowModel.entity';
import { InteractionEntity } from '@atrigam-webclient/stores/ModelsStore/entities/taskFlow/Interaction.entity';

interface Options {
  taskFlowModel: TaskFlowModelEntity;
  interaction: InteractionEntity;
  workItem: AtrigamWorkItem;
}

export class UniverseInteractionEntity {
  @observable
  workItem: AtrigamWorkItem;

  @observable
  interaction: InteractionEntity;

  @observable
  isOpen = true;

  @observable
  hasAutofocus = false;

  @observable
  private _taskFlowModel: TaskFlowModelEntity;

  constructor(data: Options) {
    makeObservable(this);

    this._taskFlowModel = data.taskFlowModel;
    this.interaction = data.interaction;
    this.workItem = data.workItem;
  }

  @computed
  get activeState() {
    return getActiveState({
      restrictions: this.interaction.restrictions?.restrictions,
      activeState: this.interaction.restrictions?.activeState,
      universeKpisMap: this._taskFlowModel.universeKpisMap,
      workItem: this.workItem,
    });
  }

  @computed
  get fieldName() {
    return this.interaction.writeTarget?.data;
  }

  @computed
  get isDisabled() {
    return this.activeState !== AtrigamRestrictionActiveState.Active;
  }

  /**
   * It is needed to have this on Model level so if a step get's expanded it automatically knows
   * this should not expand the type.
   */
  @computed
  get isExpandableType() {
    if (!this.interaction.type) {
      return true;
    }

    if (
      [
        AtrigamInteractionType.AcceptInput,
        AtrigamInteractionType.Invite,
        AtrigamInteractionType.Switch,
      ].includes(this.interaction.type)
    ) {
      return false;
    }

    return true;
  }

  @action
  setOpen = (open: boolean) => {
    this.isOpen = open;
  };

  @action
  setAutofocus = (autofocus: boolean) => {
    this.hasAutofocus = autofocus;
  };
}
