import styled from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

export const SettingsMenuItemWrapper = styled.div<ThemedStyledComponent>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }: ThemedStyledComponent) => `${theme.spacing(1)} ${theme.spacing(2)}`};
`;
