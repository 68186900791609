import { AtrigamUser } from '@atrigam/atrigam-types';

import { getName } from './getName';

interface Options extends Pick<AtrigamUser, 'uid' | 'phoneNumber' | 'firstname' | 'lastname'> {
  email?: AtrigamUser['email'];
}

export const getReducedUserDataSet = (options?: Options) =>
  !options
    ? {}
    : {
        uid: options.uid ?? null,
        email: options.email ?? null,
        phoneNumber: options.phoneNumber ?? null,
        name: getName(options) ?? null,
        firstname: options.firstname ?? null,
        lastname: options.lastname ?? null,
      };
