import { isAtrigamLanguage } from '@atrigam-webclient/helpers/isAtrigamLanguage';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { collectTranslations } from './collectTranslations';
import { TranslationService } from '../Translation.service';

export const STORAGE_TRANSLATION_LANGUAGE = 'User.language';

export const persistLanguage = (translationService: TranslationService) => {
  const disposer = Registry.get('storage').persist({
    name: STORAGE_TRANSLATION_LANGUAGE,
    data: () => translationService.language,
    hydrate: (language) => {
      if (!isAtrigamLanguage(language)) {
        return;
      }

      // only update uid if it has changed
      if (language !== translationService.language) {
        const translations = collectTranslations(language);
        const timezone = Registry.get('translations').timezone;

        translationService.updateTranslations({ language, timezone, translations });
      }
    },
  });

  // collect reaction so a store clean up with dispose it
  translationService.disposers.push(disposer);
};
