import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import { getUserNotificationListQuery } from '@atrigam/server-functions-eu-clientsdk';
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  LinearProgress,
} from '@mui/material';
import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { PrimaryButton } from '@atrigam-webclient/primitive/Button/PrimaryButton';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { warmupFirestoreCache } from '@atrigam-webclient/stores/UserNotificationsStore/helpers/warmupFirestoreCache';

import { PushNotificationAlert } from './features/PushNotificationAlert/PushNotificationAlert';
import { UserNotificationsFilter } from './features/UserNotificationsFilter/UserNotificationsFilter';
import { UserNotificationsList } from './features/UserNotificationsList/UserNotificationsList';

interface Properties {
  anchorReference: React.RefObject<HTMLButtonElement>;
  onClose: () => void;
  open: boolean;
}

const UserNotificationsWindowComponent: React.FC<Properties> = ({ open, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { updateNotifications, setShouldUpdateNotifications, hasNotifications } =
    Registry.get('userNotificationsStore');

  const loadData = React.useCallback(async () => {
    setIsLoading(true);

    const { uid } = Registry.get('userStore');

    const notifications = await getUserNotificationListQuery({
      uid,
    });

    await warmupFirestoreCache({ notifications, uid });
    updateNotifications(notifications);

    setShouldUpdateNotifications(true);

    setIsLoading(false);
  }, [setShouldUpdateNotifications, updateNotifications]);

  // track if user opens the Window
  React.useEffect(() => {
    if (open) {
      void loadData();

      void track({
        screen: AtrigamAnalyticScreens.Notification,
        event: AtrigamAnalyticEvents.NOTIFICATION_OpenNotificationWindow,
      });
    }
  }, [open, loadData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="lg"
      PaperProps={{ sx: { minHeight: '80vh' } }}
      data-testid="user-notifications-dialog"
    >
      <DialogTitle>
        {translate('layouts.DefaultLayout.Navigation.UserNotifications.Notifications')}
      </DialogTitle>

      <Divider />

      <PushNotificationAlert />

      {isLoading && (
        <Box>
          <LinearProgress />
        </Box>
      )}

      {!hasNotifications && !isLoading && (
        <Alert severity="info">
          {translate('layouts.DefaultLayout.Navigation.UserNotifications.emptyNotifications')}
        </Alert>
      )}

      {hasNotifications && <UserNotificationsFilter />}

      <UserNotificationsList onClose={onClose} />

      <Divider />

      <DialogActions>
        <PrimaryButton onClick={onClose} autoFocus variant="text" aria-label="Close">
          {translate('app.close')}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export const UserNotificationsWindow = reactive(UserNotificationsWindowComponent);
