import { AtrigamUniverseRegistrationClientRole } from '@atrigam/atrigam-types';

import { withReaction } from '@atrigam-webclient/helpers/withReaction';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { diffClientRoleObjects } from './diffUserClientRoles/diffClientRoleObjects';
import { extractUserClientRoles } from './diffUserClientRoles/extractUserClientRoles';
import { syncModelsFromUser } from './syncModelsFromUser';
import { ModelsStore } from '../Models.store';

let backupUserRoles: AtrigamUniverseRegistrationClientRole[] = [];
let isInitialized = false;

/**
 * This makes sure we update the models in the store if user roles are changing
 */
export const watchUserStoreRoles = (modelsStore: ModelsStore) => {
  const userStore = Registry.get('userStore');
  const disposer = withReaction({
    name: 'ModelsStore.syncUserRoles',
    data: () => ({
      modelsIsInitialized: modelsStore.isInitialized,
      userIsInitialized: userStore.isInitialized,
      // sync if roles change
      isSynchingClientRoles: userStore.userClientRoles.isSynching,
      clientRolesLastUpdated: userStore.userClientRoles.lastUpdated,
      // also sync if environment changes
      environment: modelsStore.environment,
    }),
    onChange: ({ modelsIsInitialized, userIsInitialized, isSynchingClientRoles }) => {
      // verify all are initialized
      if (!modelsIsInitialized || !userIsInitialized || isSynchingClientRoles) {
        return;
      }

      const userRoles = extractUserClientRoles(userStore.userClientRoles);

      const changedClientRoles = isInitialized
        ? diffClientRoleObjects({ before: backupUserRoles, after: userRoles })
        : [];

      backupUserRoles = userRoles;
      isInitialized = true;

      // sync changes to modelsStore
      void syncModelsFromUser({ changedClientRoles });
    },
  });

  modelsStore.disposers.push(disposer);
};
