/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
interface Options {
  interval?: number;
}

const DEFAULT_INTERVAL = 1000;

export interface CookieListenerEventData {
  oldValue?: string;
  newValue?: string;
}

declare type CookieListener = (data: CookieListenerEventData) => void;

const listeners: CookieListener[] = [];

let intervalId: NodeJS.Timeout | undefined;

export const initializeCookieListener = ({ interval = DEFAULT_INTERVAL }: Options) => {
  let lastCookie = document.cookie;

  intervalId = setInterval(() => {
    const cookie = document.cookie;
    if (cookie !== lastCookie) {
      try {
        listeners.forEach((listener) => listener({ oldValue: lastCookie, newValue: cookie }));
      } finally {
        lastCookie = cookie;
      }
    }
  }, interval);
};

export const addCookieListener = (listener: CookieListener) => listeners.push(listener);

export const stopAllCookieListeners = () => {
  if (!intervalId) {
    return;
  }

  clearInterval(intervalId);
  intervalId = undefined;
};
