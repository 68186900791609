import { getISO } from '@atrigam/atrigam-types';
import { getFirestoreTimestamp } from '@atrigam/server-functions-eu-clientsdk';
import { Box } from '@mui/material';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { useTimer } from '@atrigam-webclient/hooks/useTimer';
import { UnauthorizedLayout } from '@atrigam-webclient/layouts/UnauthorizedLayout/UnauthorizedLayout';
import { AppVersion } from '@atrigam-webclient/primitive/AppVersion/AppVersion';
import { Link } from '@atrigam-webclient/primitive/Link/Link';
import { Typography } from '@atrigam-webclient/primitive/Typography/Typography.style';
import { StatusPagePath } from '@atrigam-webclient/routes/statusPage/statusPage.path';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { LockScreenWrapper } from './styles/LockScreenWrapper.style';

const TIME_SECOND = 1;
const TIME_MINUTE = 60 * TIME_SECOND;
const timerPeriods = [
  { timeout: TIME_SECOND, until: TIME_MINUTE },
  { timeout: TIME_MINUTE, until: 15 * TIME_MINUTE },
  { timeout: TIME_MINUTE * 15, until: TIME_MINUTE * 120 },
];

const LockScreenComponent: React.FC = () => {
  const appStore = Registry.get('appStore');

  const { lastUpdate } = useTimer({
    date: appStore.estimatedDowntimeEnd
      ? getISO(getFirestoreTimestamp(appStore.estimatedDowntimeEnd))
      : undefined,
    timerPeriods,
  });

  return (
    <UnauthorizedLayout disableSlot={true}>
      <LockScreenWrapper>
        <Typography variant="h4" weight="medium" align="center">
          {translate('app.LockScreen.appIsLocked')}
        </Typography>

        {appStore.status && (
          <Box mt={8}>
            <Typography variant="body1" weight="light" align="center">
              {appStore.status}
            </Typography>
          </Box>
        )}

        {appStore.estimatedDowntimeEnd && (
          <Box mt={4}>
            <Typography variant="body2" weight="light" align="center">
              {translate('app.LockScreen.remainingDowntime', {
                downtime: lastUpdate,
              })}
            </Typography>
          </Box>
        )}

        <Box mt={4} justifyContent="space-between" display="flex" alignItems="center">
          <AppVersion />

          <Typography variant="overline">
            <Link to={StatusPagePath.getLink({})} openInNewTab={true}>
              Status Page
            </Link>
          </Typography>
        </Box>
      </LockScreenWrapper>
    </UnauthorizedLayout>
  );
};

export const LockScreen = reactive(LockScreenComponent);
