import { AtrigamModelsModelFlowSummary } from '@atrigam/atrigam-types';
import { makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { TaskFlowSummaryFormatEntity } from './TaskFlowSummaryFormat.entity';

export class TaskFlowSummaryEntity {
  @persist('object', TaskFlowSummaryFormatEntity)
  @observable
  format = new TaskFlowSummaryFormatEntity();

  constructor(data?: AtrigamModelsModelFlowSummary) {
    makeObservable(this);
    if (data) {
      this.format = new TaskFlowSummaryFormatEntity(data.format);
    }
  }
}
