import { NotFoundPageAsync } from '@atrigam-webclient/pages/NotFound/NotFound.page.async';
import { RouteScope } from '@atrigam-webclient/services/Router/Router.types';
import { createRoute } from '@atrigam-webclient/services/Router/helpers/createRoute';

import { pattern } from './notFound.path';

export const NotFoundRoute = createRoute({
  title: () => 'Not found',
  components: {
    main: NotFoundPageAsync,
  },
  pattern,
  scope: RouteScope.Public,
});
