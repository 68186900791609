import { DestructibleService } from '@atrigam/atrigam-service-registry';
import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import {
  AtrigamChatMessage,
  AtrigamChatMessageUser,
  ISODateTime,
  isAfter,
  throwIfNullable,
} from '@atrigam/atrigam-types';
import {
  addChatMessageMutation,
  getChatMessagesListQuery,
  updateChatMetaData,
} from '@atrigam/server-functions-eu-clientsdk';
import { action, computed, makeObservable, observable, values } from 'mobx';

import { sortByDateFunction } from '@atrigam-webclient/helpers/sortByDate';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { SubscriptionEntity } from '@atrigam-webclient/stores/SubscriptionsStore/entities/Subscription.entity';

import { ChatMessage } from './Chat.types';
import { groupChatMessages } from './helpers/groupChatMessages';
import { setupChatMessageWatcher } from './helpers/setupChatMessageWatcher';
import { WorkItemPageStore } from '../WorkItem.page.store';

interface Options {
  pageStore: WorkItemPageStore;
  userSubscription?: SubscriptionEntity;
}

export class ChatStore extends DestructibleService {
  static MESSAGES_LIMIT = 15;

  @observable
  hasMore = true;

  @observable
  isLoading = true;

  @observable
  isOpen = false;

  @observable
  private _pageStore: WorkItemPageStore;

  @observable
  private _messages = observable.map<string, ChatMessage>();

  @observable
  private _userSubscription?: SubscriptionEntity;

  constructor({ pageStore, userSubscription }: Options) {
    super();
    makeObservable(this);

    this._pageStore = pageStore;
    this._userSubscription = userSubscription;

    if (!this._pageStore.interactionsStore.isNewWorkItem) {
      this.enableChatWatcher();
    }
  }

  @computed
  get groupedMessages() {
    const { uid } = Registry.get('userStore');

    // sort message
    const messages = [...values(this._messages)].sort((a, b) =>
      sortByDateFunction(a.createdAt, b.createdAt),
    );

    return groupChatMessages({ messages, currentUserUid: uid });
  }

  @computed
  get isEmpty() {
    return this._messages.size === 0;
  }

  @computed
  get isEnabled() {
    return !this._pageStore.interactionsStore.isNewWorkItem;
  }

  @computed
  get unreadCount() {
    return values(this._messages).filter((message) => message.seen === false).length;
  }

  @action
  closeChat = () => {
    this.isOpen = false;
  };

  @action
  loadMore = async () => {
    // do nothing if we already have no more messages
    if (!this.hasMore || !this.isEnabled) {
      return;
    }

    this.isLoading = true;

    let startAfter: ISODateTime | undefined;
    values(this._messages).forEach((message) => {
      if (!startAfter || isAfter(startAfter, message.createdAt)) {
        startAfter = message.createdAt;
      }
    });

    const { environment, node, objectName, universe, area, taskFlow } = this._pageStore.basicData;
    throwIfNullable('node cannot be undefined', node);

    const messages = await getChatMessagesListQuery({
      environment,
      limit: ChatStore.MESSAGES_LIMIT,
      node,
      objectName,
      universe,
      startAfter,
    });

    await this.updateMessages({ messages, loadingMore: true });

    void track({
      event: AtrigamAnalyticEvents.CHAT_LoadMoreChatMessages,
      screen: AtrigamAnalyticScreens.Chat,
      area,
      env: environment,
      flow: taskFlow,
      object: objectName,
      universe,
      workitemId: node,
    });
  };

  @action
  markAsRead = async (messageId: string) => {
    const message = this._messages.get(messageId);
    if (!message) {
      return;
    }

    message.seen = true;

    await this.updateLastChatMessage(message);
  };

  @action
  openChat = async () => {
    const { environment, node, objectName, universe, area, taskFlow } = this._pageStore.basicData;
    throwIfNullable('node cannot be undefined', node);

    void track({
      event: AtrigamAnalyticEvents.CHAT_OpenChat,
      screen: AtrigamAnalyticScreens.Chat,
      area,
      env: environment,
      flow: taskFlow,
      object: objectName,
      universe,
      workitemId: node,
    });

    this.isOpen = true;
    await this.updateLastChatMessageWithLatestMessage();
  };

  @action
  sendMessage = async (message: string) => {
    const { userName, avatarUri, uid } = Registry.get('userStore');
    const { node, objectName, universe, area, taskFlow } = this._pageStore.basicData;

    throwIfNullable('node cannot be null', node);

    const chatUser: AtrigamChatMessageUser = {
      _id: uid,
      avatar: avatarUri,
      name: userName,
    };

    const { environment } = Registry.get('modelsStore');

    await addChatMessageMutation({
      environment,
      message,
      node,
      objectName,
      universe,
      user: chatUser,
    });

    // track
    void track({
      event: AtrigamAnalyticEvents.CHAT_SendChatMessage,
      screen: AtrigamAnalyticScreens.Chat,
      area,
      env: environment,
      flow: taskFlow,
      object: objectName,
      universe,
      workitemId: node,
    });
  };

  @action
  setUserSubscription = (userSubscription?: SubscriptionEntity) => {
    this._userSubscription = userSubscription;
  };

  @action
  updateMessages = async ({
    messages,
    loadingMore = false,
  }: {
    messages: AtrigamChatMessage[];
    loadingMore?: boolean;
  }) => {
    const { uid } = Registry.get('userStore');

    // get last message seen from user subscription
    let lastChatMessageDate: ISODateTime | undefined;
    if (this._userSubscription?.userSubscription.lastChatMessageSeen?.createdAt) {
      lastChatMessageDate = this._userSubscription.userSubscription.lastChatMessageSeen.createdAt;
    }

    messages.forEach((message) => {
      if (!this._messages.has(message._id)) {
        let seen = loadingMore || message.user._id === uid;
        if (
          lastChatMessageDate &&
          (lastChatMessageDate === message.createdAt ||
            isAfter(lastChatMessageDate, message.createdAt))
        ) {
          seen = true;
        }

        this._messages.set(message._id, {
          ...message,
          seen,
        });
      }
    });

    if (messages.length < ChatStore.MESSAGES_LIMIT) {
      this.hasMore = false;
    }

    // update lastChatMessage
    if (!loadingMore && this.isOpen) {
      await this.updateLastChatMessageWithLatestMessage();
    }

    this.isLoading = false;
  };

  @action
  enableChatWatcher = () => {
    const { environment, node, objectName, universe } = this._pageStore.basicData;
    throwIfNullable('node cannot be undefined', node);
    setupChatMessageWatcher({
      chatStore: this,
      userSubscription: this._userSubscription,
      environment,
      node,
      objectName,
      universe,
    });
  };

  @action
  private updateLastChatMessage = async (message: ChatMessage) => {
    const { environment, node, objectName, universe, area, taskFlow } = this._pageStore.basicData;

    if (!node) {
      return;
    }

    const { uid } = Registry.get('userStore');

    const chatMessageSeen = this._userSubscription?.userSubscription.chatMessageCount ?? 0;

    await updateChatMetaData({
      area,
      chatMessageSeen,
      environment,
      message,
      node,
      objectName,
      taskFlow,
      uid,
      universe,
    });
  };

  @action
  private updateLastChatMessageWithLatestMessage = async () => {
    const latestMessage = [...values(this._messages)]
      .filter((message) => message.seen)
      .sort((a, b) => sortByDateFunction(a.createdAt, b.createdAt))
      .pop();

    if (latestMessage) {
      await this.updateLastChatMessage(latestMessage);
    }
  };
}
