import { logout } from '@atrigam-webclient/helpers/logout';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

export const handleCrashRecovery = async () => {
  try {
    await logout({});
  } catch {
    // try to clear local storage
    try {
      const localStorage = Registry.get('storage');
      await localStorage.clear();
    } catch {}

    // try to clear all cookies
    try {
      const cookies = document.cookie.split(';');

      cookies.forEach((value) => {
        const [name] = value.split('=');
        // eslint-disable-next-line unicorn/no-document-cookie
        document.cookie = `${name}=;expires=${new Date(0).toUTCString()}`;
      });
    } catch {}

    window.location.href = '/';
  }
};
