import {
  RouteComponents,
  RouteOptionsComponents,
  RouteScopesWithUnauthorizedLayout,
} from '../Router.types';

const defaultSlotComponents: Omit<RouteComponents<RouteScopesWithUnauthorizedLayout>, 'main'> = {
  breadcrumbs: undefined,
  header: undefined,
  navigation: undefined,
  footer: undefined,
};

const getSlotComponent = <Scope extends RouteScopesWithUnauthorizedLayout>(options: {
  slot: Exclude<keyof RouteComponents<RouteScopesWithUnauthorizedLayout>, 'main'>;
  routeComponents: RouteOptionsComponents<Scope>;
}) => {
  const { slot, routeComponents } = options;

  if (slot === 'breadcrumbs' || slot === 'header' || slot === 'navigation') {
    return;
  }

  const component = routeComponents[slot];

  // we don't want to render this slot at all for this route
  // none -> undefined
  if (component === 'none') {
    return;
  }

  // this slot renders the provided component for this route
  // component -> component
  if (component !== undefined) {
    return component;
  }

  // we did not provide any value for this slot so we're using the default slot component
  // undefined -> default component
  return defaultSlotComponents[slot];
};

interface Options<Scope extends RouteScopesWithUnauthorizedLayout> {
  routeComponents: RouteOptionsComponents<Scope>;
}

export const getRouteComponentsForUnauthorizedLayout = <
  Scope extends RouteScopesWithUnauthorizedLayout,
>(
  options: Options<Scope>,
): RouteComponents<RouteScopesWithUnauthorizedLayout> => {
  const { routeComponents } = options;

  return {
    // Unauthorized layouts only have a required main slot
    main: routeComponents.main,

    footer: getSlotComponent({ slot: 'footer', routeComponents }),

    // every other slot cannot be set
    breadcrumbs: undefined,
    header: undefined,
    navigation: undefined,
  };
};
