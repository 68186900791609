import {
  APP_NAME_EU,
  watcherServiceEu,
  watcherServiceModels,
} from '@atrigam/server-functions-eu-clientsdk';
import { getApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import { clearIndexedDbPersistence, getFirestore, terminate } from 'firebase/firestore';

import { initializeAtrigamEu } from '@atrigam-webclient/services/FireBase/initializeAtrigamEu';

export const logoutUser = async () => {
  watcherServiceModels.get().unsubscribeAll();
  watcherServiceEu.get().unsubscribeAll();

  const atrigamEuAuth = getAuth(getApp(APP_NAME_EU));

  // terminate firestore and clear cache
  const firestoreEu = getFirestore(getApp(APP_NAME_EU));
  await terminate(firestoreEu);
  await clearIndexedDbPersistence(firestoreEu);

  await signOut(atrigamEuAuth);

  // reinitialize firestore
  initializeAtrigamEu();
};
