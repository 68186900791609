import { Typography } from '@mui/material';
import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { Link } from '@atrigam-webclient/primitive/Link/Link';

interface Properties {
  body?: string;
  subject?: string;
}

const ContactSupportMessageComponent: React.FC<Properties> = ({ body, subject }) => {
  const postfix = React.useMemo(() => {
    const parts = [];
    if (subject) {
      parts.push(`subject=${subject}`);
    }

    if (body) {
      parts.push(`body=${body}`);
    }

    if (parts.length === 0) {
      return '';
    }

    return `?${parts.join('&amp;')}`;
  }, [body, subject]);

  const mailto = `mailto:support@atrigam.com${postfix}`;

  return (
    <Typography variant="body2">
      {translate('patterns.ContactSupportMessage.message', {
        link: (
          <Link to={mailto} openInNewTab={true} color="secondary">
            support@atrigam.com
          </Link>
        ),
      })}
    </Typography>
  );
};

export const ContactSupportMessage = reactive(ContactSupportMessageComponent);
