import { EmailOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';

import { getRelativeDate } from '@atrigam-webclient/helpers/getRelativeDate';
import { reactive } from '@atrigam-webclient/helpers/reactive';
import { useSendVerificationMail } from '@atrigam-webclient/hooks/useSendVerificationMail';
import { ContactSupportMessage } from '@atrigam-webclient/patterns/ContactSupportMessage/ContactSupportMessage';
import { Banner } from '@atrigam-webclient/primitive/Banner/Banner';
import { TertiaryButton } from '@atrigam-webclient/primitive/Button/TertiaryButton';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { isEmailVerificationOverdue } from '@atrigam-webclient/stores/UserStore/helpers/isEmailVerificationOverdue';

import { useLaterTimeout } from './hooks/useLaterTimeout';
import { useVerifyLater } from './hooks/useVerifyLater';

const EmailVerificationBannerComponent: React.FC = () => {
  const router = Registry.get('router');
  const { user } = Registry.get('userStore');
  const [showBanner, setShowBanner] = React.useState(
    user?.isEmailVerified !== true || user?.verifyEmailLater !== undefined,
  );

  const { isVerifyingLater, onVerifyLater } = useVerifyLater({ user });
  const { isSending, onSendVerificationMail } = useSendVerificationMail();
  useLaterTimeout({ user, showBanner, setShowBanner });

  const text = React.useMemo(() => {
    if (!user) {
      return '';
    }

    if (!user?.verificationLinkResentAt) {
      return translate('layouts.DefaultLayout.Header.EmailVerificationBanner.message.pleaseVerify');
    }

    if (user.verificationLinkResentAt) {
      const date = getRelativeDate(user.verificationLinkResentAt);
      if (!date) {
        return '';
      }

      return translate(
        'layouts.DefaultLayout.Header.EmailVerificationBanner.message.resendVerification',
        {
          strong: (chunks: string) => <strong>{chunks}</strong>,
          date,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.verifyEmailLater, user?.verificationLinkResentAt]);

  const isEmailOverdue = React.useMemo(() => {
    if (!user) {
      return false;
    }

    return isEmailVerificationOverdue({
      createdAt: user.createdAt,
      isEmailVerified: user.isEmailVerified,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.createdAt, user?.isEmailVerified]);

  // don't show banner if email is overdue, we redirect to a page anyway
  if (!user || user.isEmailVerified || !text || isEmailOverdue || !showBanner) {
    return null;
  }

  // don't show banner if we are on verify email page
  if (router.currentRoute.name === 'VerifyEmailRoute') {
    return null;
  }

  return (
    <Banner
      message={text}
      icon={<EmailOutlined />}
      appBar={true}
      variation="warning"
      actions={() => (
        <>
          {!user.verificationLinkResentAt && (
            <TertiaryButton
              variant="outlined"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onSendVerificationMail}
              loading={isSending}
              disabled={isVerifyingLater}
            >
              {translate(
                'layouts.DefaultLayout.Header.EmailVerificationBanner.button.sendVerificationMail',
              )}
            </TertiaryButton>
          )}

          {user.verificationLinkResentAt && (
            <>
              <TertiaryButton
                variant="outlined"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onVerifyLater}
                loading={isVerifyingLater}
                disabled={isSending}
              >
                {translate('layouts.DefaultLayout.Header.EmailVerificationBanner.button.later')}
              </TertiaryButton>

              <Box marginLeft={1} component="span">
                <TertiaryButton
                  variant="outlined"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={onSendVerificationMail}
                  loading={isSending}
                  disabled={isVerifyingLater}
                >
                  {translate(
                    'layouts.DefaultLayout.Header.EmailVerificationBanner.button.resendVerificationMail',
                  )}
                </TertiaryButton>
              </Box>
            </>
          )}
        </>
      )}
    >
      <ContactSupportMessage />
    </Banner>
  );
};

export const EmailVerificationBanner = reactive(EmailVerificationBannerComponent);
