import { ListItemIcon, ListItemIconProps } from '@mui/material';
import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

interface Properties {
  $isActive: boolean;
}

export const NavigationItemIcon = styled(ListItemIcon)<
  ListItemIconProps & ThemedStyledComponent & Properties
>`
  ${({ theme, $isActive }: ListItemIconProps & ThemedStyledComponent & Properties) => css`
    color: ${$isActive ? theme.palette.primary.main : theme.palette.text.secondary};
    transition: ${theme.transitions.create('color')};
  `}
`;
