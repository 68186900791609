import {
  AtrigamConditionsSetCondition,
  AtrigamObjectName,
  AtrigamValidationTypes,
  throwIfNullable,
} from '@atrigam/atrigam-types';
import { computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export class ConditionsSetConditionEntity {
  @persist
  @observable
  field?: string;

  @persist
  @observable
  objectName?: AtrigamObjectName;

  @persist
  @observable
  validation?: AtrigamValidationTypes;

  @persist
  @observable
  value?: string | number;

  constructor(data?: AtrigamConditionsSetCondition) {
    makeObservable(this);

    if (data) {
      this.field = data.data;
      this.objectName = data.object;
      this.validation = data.validation;
      this.value = data.value;
    }
  }

  @computed
  get values(): AtrigamConditionsSetCondition {
    throwIfNullable('field cannot be undefined', this.field);
    throwIfNullable('objectName cannot be undefined', this.objectName);
    throwIfNullable('validation cannot be undefined', this.validation);

    return {
      data: this.field,
      object: this.objectName,
      validation: this.validation,
      value: this.value,
    };
  }
}
