import { withReaction } from '@atrigam-webclient/helpers/withReaction';
import { SubscriptionsPath } from '@atrigam-webclient/routes/subscriptions/subscriptions.path';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { SubscriptionsStore } from '../Subscriptions.store';

export const syncSubscriptionsUrl = (subscriptionStore: SubscriptionsStore) =>
  withReaction({
    name: 'syncSubscriptionsUrl',
    data: () => ({
      state: subscriptionStore.subscriptionState,
      search: subscriptionStore.searchString,
    }),
    onChange: ({ state, search }) => {
      const router = Registry.get('router');

      // if user is not currently on calendar page -> do not update URL
      const isOnSubscriptionsPage = router.isActiveRoutePath(SubscriptionsPath);

      if (!isOnSubscriptionsPage) {
        return;
      }

      router.updateQueryWithoutNavigation({
        state,
        search: search.length > 0 ? search : undefined,
      });
    },
  });
