import { useMediaQuery } from '@mui/material';
import React from 'react';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { ThemeTypes, Themes } from '@atrigam-webclient/themes/Themes';

export const useTheme = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [theme, setTheme] = React.useState<ThemeTypes>(prefersDarkMode ? 'dark' : 'light');
  const [isInitialized, setIsInitialized] = React.useState(false);

  // get initial value from local storage
  React.useEffect(() => {
    void Registry.get('storage')
      .getItem<ThemeTypes>({
        name: 'User.theme',
      })
      .then((value) => {
        if (value) {
          setTheme(value);
        }
        setIsInitialized(true);
      });
  }, []);

  const toggleTheme = React.useCallback(() => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    Registry.get('storage').setItem({ name: 'User.theme', value: newTheme });
  }, [theme, setTheme]);

  const themeContextValue = React.useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme]);

  // need to create a new object every time or it will not change back correctly
  // @see https://stackoverflow.com/a/60909979/190450
  const currentTheme = React.useMemo(
    () => ({ ...(theme === 'light' ? Themes.light : Themes.dark) }),
    [theme],
  );

  return { currentTheme, themeContextValue, isThemeInitialized: isInitialized };
};
