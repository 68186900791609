import { PushNotificationPlaygroundPageAsync } from '@atrigam-webclient/pages/PushNotificationPlayground/PushNotificationPlayground.page.async';
import { RouteScope } from '@atrigam-webclient/services/Router/Router.types';
import { createRoute } from '@atrigam-webclient/services/Router/helpers/createRoute';

import { pattern } from './pushNotificationPlayground.path';

export const PushNotificationPlaygroundRoute = createRoute({
  title: () => translate('routes.pushNotificationPlayground.title'),
  components: {
    main: PushNotificationPlaygroundPageAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,
});
