import { Link as MaterialLink } from '@mui/material';
import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { LinkProperties } from './Link.types';
import { useActiveState } from './hooks/useActiveState';
import { usePreloading } from './hooks/usePreloading';

const LinkComponent: React.ForwardRefRenderFunction<HTMLAnchorElement, LinkProperties> = (
  {
    to,
    openInNewTab: openInNewTabProperty,
    forceReload,
    activeRoutes,

    className = '',
    onClick: onClickProperty,

    children,
    ...properties
  },
  reference,
) => {
  const router = Registry.get('router');

  const isExternalLink = to.startsWith('http');
  const openInNewTab = isExternalLink === true || openInNewTabProperty === true;
  const shouldUseBrowserNavigation = forceReload === true || openInNewTab === true;
  const { onMouseOver, onMouseOut } = usePreloading({ to });
  const { isActive } = useActiveState({
    to,
    activeRoutes,
  });

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onClickProperty) {
        onClickProperty(event);

        if (event.defaultPrevented) {
          return;
        }
      }

      // use browser navigation
      const isComboKey = event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
      if (isComboKey || shouldUseBrowserNavigation) {
        return;
      }

      // use router navigation
      event.preventDefault();
      void router.goTo({ url: to });
    },
    [onClickProperty, shouldUseBrowserNavigation, router, to],
  );

  const commonProperties = {
    ref: reference,
    children,
    onClick,
    onMouseOver,
    onMouseOut,
    dir: 'auto',
    className: isActive ? `${className} active` : className,
    target: openInNewTab ? '_blank' : undefined,
    rel: openInNewTab ? 'noopener no-referrer' : undefined,
    ...properties,
  };

  return <MaterialLink href={to} {...commonProperties} />;
};

export const Link = reactive(React.forwardRef(LinkComponent));
