import queryString from 'query-string';

const hasWindow = typeof window !== 'undefined';
const DEBUG_STORAGE_KEY = 'DEBUG_ENABLED';

const getDebugFromStorage = () => {
  try {
    return localStorage.getItem(DEBUG_STORAGE_KEY) === 'true' ? true : false;
  } catch {}

  return false;
};

if (hasWindow) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  (window as any).debug = (value: any = true) => {
    localStorage.setItem(DEBUG_STORAGE_KEY, value ? 'true' : 'false');
  };
}

const isLocalhost = window.location.host.startsWith('localhost:');

export const IS_PROD = import.meta.env.PROD;

export const IS_BETA =
  hasWindow && IS_PROD && window.location.host.includes('app-beta.atrigam.com');

export const IS_DEV = import.meta.env.DEV;

export const IS_DEV_HTTPS = IS_DEV && import.meta.env.VITE_HTTPS === 'true';

export const IS_CI = import.meta.env.VITE_CI === 'true' || (isLocalhost && !IS_DEV);

export const IS_TEST = import.meta.env.NODE_ENV === 'test' || IS_CI;

export const IS_FULL_DEBUG = hasWindow
  ? 'debug' in queryString.parse(window.location.search) || getDebugFromStorage()
  : false;

export const IS_DEBUG = IS_DEV || (IS_PROD && IS_FULL_DEBUG) || IS_CI;
