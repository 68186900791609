import { ListItemText, ListItemTextProps } from '@mui/material';
import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

interface Properties {
  $isActive: boolean;
}

export const NavigationItemText = styled(ListItemText)<
  ListItemTextProps & ThemedStyledComponent & Properties
>`
  ${({ theme, $isActive }: ListItemTextProps & ThemedStyledComponent & Properties) => css`
    padding: 0;
    color: ${$isActive ? theme.palette.text.primary : theme.palette.text.secondary};
    transition: ${theme.transitions.create(['opacity', 'color'])};
    font-size: 16px;
  `}
`;
