import {
  AtrigamAnalyticEvents,
  AtrigamAnalyticScreens,
  track,
  trackingConfiguration,
} from '@atrigam/atrigam-tracking';
import {
  APP_NAME_EU,
  unImpersonateUserHttpOnCallFunction,
} from '@atrigam/server-functions-eu-clientsdk';
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { sentry } from '@atrigam-webclient/services/Sentry/helpers/initializeSentry';

import { getReducedUserDataSet } from './getReducedUserDataSet';

export const stopImpersonateUser = async () => {
  // 1. check if we are impersonating
  if (!Registry.get('userStore').isImpersonating) {
    return false;
  }

  // Log Unimpersonate
  const { user: impersonatedUserModel, impersonator: impersonatorModel } =
    Registry.get('userStore');
  const impersonator = getReducedUserDataSet(impersonatorModel);
  const impersonatedUser = getReducedUserDataSet(impersonatedUserModel);

  sentry.addBreadcrumb({
    message: `User ${impersonator?.email ?? 'n/a'} stops impersonating ${
      impersonatedUser?.email ?? 'n/a'
    }`,
    data: { impersonator, impersonatedUser },
  });

  void track({
    event: AtrigamAnalyticEvents.IMPERSONATION_Unimpersonate,
    screen: AtrigamAnalyticScreens.Impersonation,
    impersonator,
    impersonatedUser,
  });

  // stop rendering
  Registry.get('router').setTransition(true);

  // 2. stop all watchers
  Registry.get('modelsStore').stopWatchers();
  Registry.get('userStore').stopWatchers();

  // 3. exchange user in store
  await Registry.get('userStore').stopImpersonate();

  // 4. clear all stores
  Registry.get('subscriptionsStore').reset();
  Registry.get('modelsStore').reset();
  // need to reset isSyncing as it has been set to true during reset()
  Registry.get('modelsStore').setIsSyncingModels(false);
  Registry.get('subscriptionsStore').reset();

  // unimpersonate Auth
  const impersonated = await unImpersonateUserHttpOnCallFunction({
    onException: (error) =>
      sentry.log({
        error: error as Error,
        context: { payload: { impersonator: impersonatorModel!.uid } },
      }),
    XAppEnvironment: trackingConfiguration?.appEnvironment,
    XTransactionID: sentry.transactionID,
  });

  if (!impersonated) {
    Registry.get('snackbar').addNotification({
      message: 'Unimpersonating failed!<br>Maybe you have no rights!',
      options: {
        variant: 'error',
      },
    });
    return false;
  }

  const euAuth = getAuth(getApp(APP_NAME_EU));
  await euAuth.currentUser?.getIdToken(true);

  // 5. run watchers
  Registry.get('modelsStore').startWatchers();

  return true;
};
