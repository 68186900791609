import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { reactive } from '@atrigam-webclient/helpers/reactive';

import { MobileBackButtonWrapper } from './styles/MobileBackButtonWrapper.style';

interface Properties {
  toggleNavigation: () => void;
}

const MobileBackButtonComponent: React.FC<Properties> = ({ toggleNavigation }) => {
  return (
    <MobileBackButtonWrapper>
      <IconButton onClick={toggleNavigation} size="large">
        <ArrowBackIcon />
      </IconButton>
    </MobileBackButtonWrapper>
  );
};

export const MobileBackButton = reactive(MobileBackButtonComponent);
