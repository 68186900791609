import { Box, LinearProgress } from '@mui/material';
import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { Typography } from '@atrigam-webclient/primitive/Typography/Typography.style';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

const ProfileCompletenessComponent: React.FC = () => {
  const userStore = Registry.get('userStore');
  const user = userStore.userOrFail;

  const profileCompleteness = React.useMemo(() => {
    const profileFields = [
      user.company,
      user.firstname,
      user.lastname,
      user.jobtitle,
      user.profilePicture,
      user.salutation,
      user.termsAndConditionsAccepted,
    ];

    const fieldCount = profileFields.length;
    const filledCount = profileFields.filter((field) => {
      if (!field || field === null) {
        return false;
      }

      if (typeof field === 'string' && field.trim().length === 0) {
        return false;
      }

      return true;
    }).length;

    return Math.round((filledCount / fieldCount) * 100);
  }, [user]);

  const text = React.useMemo(() => {
    // not even terms are accepted !! red
    if (!user.termsAndConditionsAccepted) {
      return translate(
        'layouts.DefaultLayout.Navigation.UserAvatar.ProfileCompleteness.termsNotAccepted',
      );
    }

    // user name is filled
    if (profileCompleteness < 60) {
      return translate(
        'layouts.DefaultLayout.Navigation.UserAvatar.ProfileCompleteness.profileNeedsAttention',
      );
    }

    // user name is filled
    if (profileCompleteness < 90) {
      return translate(
        'layouts.DefaultLayout.Navigation.UserAvatar.ProfileCompleteness.profileAlmostComplete',
      );
    }

    if (profileCompleteness >= 99) {
      return translate(
        'layouts.DefaultLayout.Navigation.UserAvatar.ProfileCompleteness.profileComplete',
      );
    }
  }, [profileCompleteness, user.termsAndConditionsAccepted]);

  return (
    <>
      <Box display="flex" alignItems="center" paddingX={2}>
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={profileCompleteness} />
        </Box>
        <Box minWidth={35}>
          <Typography
            variant="body2"
            color={!user.termsAndConditionsAccepted ? 'error' : 'textSecondary'}
          >{`${profileCompleteness}%`}</Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" paddingX={2} paddingBottom={2}>
        <Typography
          variant="caption"
          color={!user.termsAndConditionsAccepted ? 'error' : 'textSecondary'}
        >
          {text}
        </Typography>
      </Box>
    </>
  );
};

export const ProfileCompleteness = reactive(ProfileCompletenessComponent);
