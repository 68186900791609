import { AtrigamWorkItem } from '@atrigam/atrigam-types';

interface Options {
  fieldNames: string[];
  workItem: Partial<AtrigamWorkItem>;
}

export const getPartialWorkItemByFieldNames = ({ fieldNames, workItem }: Options) =>
  fieldNames.reduce(
    (result, fieldName) => ({
      ...result,
      [fieldName]: workItem[fieldName],
    }),
    {} as Partial<AtrigamWorkItem>,
  );
