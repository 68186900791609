import { APP_NAME_EU } from '@atrigam/server-functions-eu-clientsdk';
import { initializeApp } from 'firebase/app';
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';

import { atrigamEU } from '../../configuration/firestore.config';

export const initializeAtrigamEu = () => {
  const euApp = initializeApp(atrigamEU, APP_NAME_EU);
  initializeFirestore(euApp, {
    localCache: persistentLocalCache(/* settings*/ { tabManager: persistentMultipleTabManager() }),
  });

  return euApp;
};
