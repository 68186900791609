import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamWorkItemId,
  AtrigamUniverseName,
  UID,
  URI,
} from '@atrigam/atrigam-types';
import { updateUserNotificationReadMutation } from '@atrigam/server-functions-eu-clientsdk';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';

interface ReceivedNotification {
  type: 'RECEIVED_NOTIFICATION';
  data: Record<string, unknown>;
}

interface TappedNotification {
  type: 'TAPPED_NOTIFICATION';
  data: Record<string, unknown>;
  url: URI;
}

type NotificationEvents = ReceivedNotification | TappedNotification;

export const listenToServiceWorker = () => {
  // eslint-disable-next-line unicorn/prefer-add-event-listener, compat/compat
  navigator.serviceWorker.onmessage = (event) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!event.data?.type) {
      return;
    }

    const data = (event as MessageEvent<NotificationEvents>).data;
    switch (data.type) {
      case 'RECEIVED_NOTIFICATION': {
        const notifyData = data.data ?? {};

        const { user, avatarUri } = Registry.get('userStore');

        void track({
          event: AtrigamAnalyticEvents.NOTIFICATION_FCMNotificationReceived,
          screen: AtrigamAnalyticScreens.Notification,
          universe: notifyData.universe as AtrigamUniverseName | undefined,
          area: notifyData.area as AtrigamAreaName | undefined,
          flow: notifyData.flow as AtrigamTaskFlowName | undefined,
          object: notifyData.object as string | undefined,
          workitemId: notifyData.nodeName as AtrigamWorkItemId | undefined,
          env: (notifyData.env ?? null) as AtrigamEnvironment | null,
          subscriptionId: (notifyData.subscriptionId ?? null) as UID | null,
          ...(user?.uid ? { uid: user.uid } : {}),
          ...(user?.email
            ? {
                user: {
                  avatar: avatarUri ?? null,
                  email: user.email ?? null,
                  firstname: user.firstname ?? null,
                  lastname: user.lastname ?? null,
                  phoneNumber: user.phoneNumber ?? null,
                  isTestUser: user.isTestUser ?? null,
                },
              }
            : {}),
        });

        break;
      }

      case 'TAPPED_NOTIFICATION': {
        const notifyData = data.data ?? {};

        const { user, avatarUri } = Registry.get('userStore');

        if (notifyData.notificationId && user?.uid) {
          void updateUserNotificationReadMutation({
            uid: user.uid,
            id: notifyData.notificationId as AtrigamWorkItemId,
            unread: false,
          });
        }

        void track({
          event: AtrigamAnalyticEvents.NOTIFICATION_FCMNotificationTapped,
          screen: AtrigamAnalyticScreens.Notification,
          area: notifyData.area as AtrigamAreaName | undefined,
          env: (notifyData.env ?? null) as AtrigamEnvironment | null,
          flow: notifyData.flow as AtrigamTaskFlowName | undefined,
          object: notifyData.object as string | undefined,
          universe: notifyData.universe as AtrigamUniverseName | undefined,
          url: data.url,
          workitemId: notifyData.nodeName as AtrigamWorkItemId | undefined,
          ...(user?.uid ? { uid: user.uid } : {}),
          ...(user?.email
            ? {
                user: {
                  avatar: avatarUri ?? null,
                  email: user.email ?? null,
                  firstname: user.firstname ?? null,
                  lastname: user.lastname ?? null,
                  phoneNumber: user.phoneNumber ?? null,
                  isTestUser: user.isTestUser ?? null,
                },
              }
            : {}),
        });
        break;
      }

      default:
      // do nothing
    }
  };
};
