import { extractAtrigamUniverseAreaTaskFlow } from '@atrigam/atrigam-types';
import { watchUserSubscriptionsFlowNode } from '@atrigam/server-functions-eu-clientsdk';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { SubscriptionsStore } from '../Subscriptions.store';

export const watchUserSubscriptionCounts = (subscriptionStore: SubscriptionsStore) => {
  const { uid } = Registry.get('userStore');

  const { area, taskFlow, universe } = extractAtrigamUniverseAreaTaskFlow(
    subscriptionStore.universeAreaTaskFlow!,
  );

  const disposer = watchUserSubscriptionsFlowNode({
    environment: subscriptionStore.environment,
    universe,
    area,
    taskFlow,
    uid,
    onUpdate: (flowNode) => {
      if (!flowNode) {
        return;
      }

      subscriptionStore.updateSubscriptionCounts(flowNode.counts);
    },
  });

  subscriptionStore.disposers.push(disposer);
};
