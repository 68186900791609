import { getISO } from '@atrigam/atrigam-types';
import {
  getAllUniverseRegistrationClientRolesForUserListQuery,
  getUniverseRegistrationQuery,
} from '@atrigam/server-functions-eu-clientsdk';

import { UserStore } from '../User.store';

export const syncUserClientRoles = async (userStore: UserStore) => {
  if (userStore.userClientRoles.isSynching) {
    return;
  }

  userStore.userClientRoles.setIsSynching(true);

  if (!userStore.user?.uid) {
    userStore.userClientRoles.clear();
    userStore.userClientRoles.setIsSynching(false);
    return;
  }

  // remove all universes that are not present anymore
  userStore.userClientRoles.universeNames.forEach((universe) => {
    // still have the universe
    if ((userStore.userOrFail.clientUniverses ?? []).includes(universe)) {
      return;
    }

    userStore.userClientRoles.removeUniverse({ universe });
  });

  // update or add universes
  await Promise.all(
    (userStore.user.clientUniverses ?? []).map(async (universe) => {
      const universeRegistration = await getUniverseRegistrationQuery({ universe });
      if (!universeRegistration) {
        return;
      }

      const model = userStore.userClientRoles.universes.get(universe);

      // make sure we are not logging out
      const uid = userStore.user?.uid;
      if (!uid) {
        return;
      }

      // check if clientRoles have changed at all
      if (
        !model?.updatedAt ||
        !universeRegistration.updatedAt ||
        model.updatedAt !== getISO(universeRegistration.updatedAt)
      ) {
        const clientRoles = await getAllUniverseRegistrationClientRolesForUserListQuery({
          universe,
          uid,
        });

        userStore.userClientRoles.addUniverse({
          universe,
          updatedAt: universeRegistration.updatedAt,
          clientRoles,
        });
      }
    }),
  );

  userStore.userClientRoles.setIsSynching(false);
};
