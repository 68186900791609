import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, Theme } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from 'styled-components';

import { PageLoadingContext } from '@atrigam-webclient/contexts/PageLoadingContext';
import { ThemeContext } from '@atrigam-webclient/contexts/ThemeContext';
import { reactive } from '@atrigam-webclient/helpers/reactive';
import { Layouts } from '@atrigam-webclient/layouts/Layouts';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { GlobalStyleCollection } from './GlobalStyleCollection';
import { ErrorFallback } from './features/ErrorFallback/ErrorFallback';
import { LockScreen } from './features/LockScreen/LockScreen';
import { RouterTransition } from './features/RouterTransition/RouterTransition';
import { SnackbarNotifier } from './features/SnackbarNotifier/SnackbarNotifier';
import { ThemedSnackbarProvider } from './features/ThemedSnackbarProvider/ThemedSnackbarProvider';
import { UpdateAvailableNotification } from './features/UpdateAvailableNotification/UpdateAvailableNotification';
import { handleCrashRecovery } from './helpers/handleCrashRecovery';
import { handleErrorBoundaryError } from './helpers/handleErrorBoundaryError';
import { usePageLoading } from './hooks/usePageLoading';
import { useRemoveInitialLoader } from './hooks/useRemoveInitialLoader';
import { useTheme } from './hooks/useTheme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const AppComponent = () => {
  const { currentTheme, themeContextValue, isThemeInitialized } = useTheme();
  const { pageLoadingContextValue } = usePageLoading();
  useRemoveInitialLoader();

  const appStore = Registry.get('appStore');
  const translationService = Registry.get('translations');

  // need to make sure the router is ready for everything outside Layouts
  // (as Layouts takes care, Toasts .. etc. not!)
  if (!isThemeInitialized) {
    return null;
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleErrorBoundaryError}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onReset={handleCrashRecovery}
    >
      <div key={translationService.language}>
        <ThemeContext.Provider value={themeContextValue}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={currentTheme}>
              <ThemeProvider theme={currentTheme}>
                <PageLoadingContext.Provider value={pageLoadingContextValue}>
                  <ThemedSnackbarProvider>
                    <GlobalStyleCollection />

                    <RouterTransition />
                    <SnackbarNotifier />
                    <UpdateAvailableNotification />

                    {appStore.isLocked && <LockScreen />}
                    {!appStore.isLocked && <Layouts />}
                  </ThemedSnackbarProvider>
                </PageLoadingContext.Provider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </ThemeContext.Provider>
      </div>
    </ErrorBoundary>
  );
};

export const App = reactive(AppComponent);
