import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

export const AppVersionWrapper = styled.div<ThemedStyledComponent>(
  () => css`
    cursor: default;
    opacity: 0.3;
    font-size: 0.875rem;
  `,
);
