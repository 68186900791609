import { Avatar } from '@mui/material';
import styled, { css } from 'styled-components';

interface Options {
  color?: string;
  background?: string;
}

export const WorkItemAvatar = styled(Avatar)<Options>`
  ${({ color }) => {
    if (color) {
      return css`
        color: ${color};
      `;
    }
  }};

  ${({ background }) => {
    if (background) {
      return css`
        background-color: ${background};
      `;
    }
  }};
`;
