import { AtrigamUserNotification, AtrigamUserSubscription } from '@atrigam/atrigam-types';
import { getUserSubscriptionFromCacheQuery } from '@atrigam/server-functions-eu-clientsdk';
import React from 'react';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';

interface Options {
  notification: AtrigamUserNotification;
}

export const useLoadUserSubscription = ({ notification }: Options) => {
  const { uid } = Registry.get('userStore');

  const [userSubscription, setUserSubscription] = React.useState<AtrigamUserSubscription>();
  const [loadingUserSubscription, setLoadingUserSubscription] = React.useState(true);

  React.useEffect(() => {
    if (
      notification.environment &&
      notification.workitemId &&
      notification.area &&
      notification.universe &&
      notification.flow
    ) {
      void getUserSubscriptionFromCacheQuery({
        uid,
        environment: notification.environment,
        node: notification.workitemId,
        universe: notification.universe,
        area: notification.area,
        taskFlow: notification.flow,
      })
        .then((value) => {
          if (value) {
            setUserSubscription(value);
          }
          setLoadingUserSubscription(false);
        })
        .catch(() => {
          // ignore errors
          setLoadingUserSubscription(false);
        });
    }
  }, [
    notification.area,
    notification.environment,
    notification.flow,
    notification.universe,
    notification.workitemId,
    uid,
  ]);

  return { userSubscription, loadingUserSubscription };
};
