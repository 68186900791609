import { List, ListSubheader, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';

import { ThemeContext } from '@atrigam-webclient/contexts/ThemeContext';
import { reactive } from '@atrigam-webclient/helpers/reactive';
import { Typography } from '@atrigam-webclient/primitive/Typography/Typography.style';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { toggleLanguage } from '@atrigam-webclient/services/Translation/helpers/toggleLanguage';
import { Language } from '@atrigam-webclient/typings/language';

import { SettingsMenuItemWrapper } from './styles/SettingsMenuItemWrapper.style';
import { SettingsWrapper } from './styles/SettingsWrapper.style';

const SettingsMenuComponent: React.FC = () => {
  const { language } = Registry.get('translations');
  const { theme, toggleTheme } = React.useContext(ThemeContext);

  return (
    <SettingsWrapper>
      <List>
        <ListSubheader>
          {translate('layouts.DefaultLayout.Navigation.UserAvatar.SettingsMenu.Settings')}
        </ListSubheader>

        <SettingsMenuItemWrapper>
          <Typography variant="body2" id="language">
            {translate('layouts.DefaultLayout.Navigation.UserAvatar.SettingsMenu.Language')}
          </Typography>

          <ToggleButtonGroup value={language} onChange={toggleLanguage} size="small" exclusive>
            <ToggleButton value={Language.en} key={Language.en}>
              {Language.en}
            </ToggleButton>

            <ToggleButton value={Language.de} key={Language.de}>
              {Language.de}
            </ToggleButton>
          </ToggleButtonGroup>
        </SettingsMenuItemWrapper>

        <SettingsMenuItemWrapper>
          <Typography variant="body2" id="language">
            {translate('layouts.DefaultLayout.Navigation.UserAvatar.SettingsMenu.Theme')}
          </Typography>

          <ToggleButtonGroup value={theme} onChange={toggleTheme} size="small" exclusive>
            <ToggleButton value="light" key="light">
              {translate('layouts.DefaultLayout.Navigation.UserAvatar.SettingsMenu.Light')}
            </ToggleButton>

            <ToggleButton value="dark" key="dark">
              {translate('layouts.DefaultLayout.Navigation.UserAvatar.SettingsMenu.Dark')}
            </ToggleButton>
          </ToggleButtonGroup>
        </SettingsMenuItemWrapper>
      </List>
    </SettingsWrapper>
  );
};

export const SettingsMenu = reactive(SettingsMenuComponent);
