import { Box, Divider, Link, List, ListSubheader, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { UserNotifications } from '@atrigam-webclient/layouts/DefaultLayout/features/Navigation/features/UserNotifications/UserNotifications';
import { IS_DEV } from '@atrigam-webclient/mode';
import { AppVersion } from '@atrigam-webclient/primitive/AppVersion/AppVersion';
import { LanguageSwitcher } from '@atrigam-webclient/primitive/LanguageSwitcher/LanguageSwitcher';
import { Logo } from '@atrigam-webclient/primitive/Logo/Logo';
import { ThemeSwitcher } from '@atrigam-webclient/primitive/ThemeSwitcher/ThemeSwitcher';
import { WebsitePath } from '@atrigam-webclient/routes/modeler/modeler.path';
import { ModelerPath } from '@atrigam-webclient/routes/website/website.path';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { AtrigamTheme } from '@atrigam-webclient/typings/atrigamTheme';

import { EnvironmentSwitcher } from './features/EnvironmentSwitcher/EnvironmentSwitcher';
import { Impersonating } from './features/Impersonating/Impersonating';
import { MobileBackButton } from './features/MobileBackButton/MobileBackButton';
import { UserAvatar } from './features/UserAvatar/UserAvatar';
import { useWindowResize } from './hooks/useWindowResize';
import { FlowOverviewNavigationItem } from './navigationItems/FlowOverviewNavigationItem/FlowOverview.navigationItem';
import { PlaygroundNavigationItem } from './navigationItems/PlaygroundNavigationItem/Playground.navigationItem';
import { PushNotificationPlaygroundNavigationItem } from './navigationItems/PushNotificationPlaygroundNavigationItem/PushNotificationPlayground.navigationItem';
import { NavigationWrapper } from './styles/NavigationWrapper.style';
import { NavigationContext } from '../../contexts/NavigationContext';

const footerLinks = [
  {
    to: ModelerPath.getLink(),
    label: 'Modeler',
  },
  {
    to: WebsitePath.getLink(),
    label: 'Website',
  },
];

const NavigationComponent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = Registry.get('router');

  const theme = useTheme<AtrigamTheme>();
  const breakpointNavigation = useMediaQuery(theme.breakpoints.up('lg'));
  const isSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  const { isOpen, toggleNavigation } = React.useContext(NavigationContext);
  const { isPermanent } = useWindowResize();

  // close open navigation on mobile
  React.useEffect(() => {
    if (isSmaller && isOpen) {
      toggleNavigation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.currentPath]);

  // open navigation if it was closed on a smaller view
  React.useEffect(() => {
    if (!isOpen && breakpointNavigation) {
      toggleNavigation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakpointNavigation]);

  const variant = isPermanent ? 'permanent' : 'temporary';

  const onClose = React.useCallback(() => {
    if (isOpen) {
      toggleNavigation();
    }
  }, [isOpen, toggleNavigation]);

  return (
    <NavigationWrapper
      open={isOpen}
      variant={variant}
      data-testid="layout-navigation"
      onClose={onClose}
    >
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <MobileBackButton toggleNavigation={toggleNavigation} />
        <Logo width={80} style={{ marginLeft: breakpointNavigation ? '16px' : '0px' }} />
        <UserAvatar />
      </Box>

      <Impersonating />

      <List dense={true}>
        <UserNotifications />
        <EnvironmentSwitcher />
      </List>

      <Box flex="1">
        <List dense={true}>
          <ListSubheader>
            {translate('layouts.DefaultLayout.Navigation.NavigationTitle')}
          </ListSubheader>
          <FlowOverviewNavigationItem />

          {children}
          {IS_DEV && (
            <>
              <Divider />

              <PlaygroundNavigationItem />
              <PushNotificationPlaygroundNavigationItem />
            </>
          )}
        </List>
      </Box>

      <Divider />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft={2}
        paddingRight={2}
        paddingBottom={1}
        paddingTop={1}
      >
        {footerLinks.map(({ to, label }) => (
          <Link
            href={to}
            key={label}
            underline="hover"
            color="text.secondary"
            target="_blank"
            rel="noopener"
            fontSize="small"
          >
            {label}
          </Link>
        ))}
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft={2}
        paddingRight={1}
        paddingBottom={1}
      >
        <AppVersion />

        {!isSmaller && <LanguageSwitcher />}
        {!isSmaller && <ThemeSwitcher />}
      </Box>
    </NavigationWrapper>
  );
};

export const Navigation = reactive(NavigationComponent);
