import styled from 'styled-components';

import { spin } from '@atrigam-webclient/app/App/styles/Utils.style';
import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

export const LoadingWrapper = styled.div<ThemedStyledComponent>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }: ThemedStyledComponent) => theme.palette.text.primary};
  height: 100%;
  width: 100%;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  > svg {
    ${spin()};
  }
`;
