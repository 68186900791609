import { AtrigamWorkItem, Currency, formatPattern } from '@atrigam/atrigam-types';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { WorkItemModelEntity } from '@atrigam-webclient/stores/ModelsStore/entities/WorkItemModel.entity';

interface Options {
  template: string;
  workItem?: AtrigamWorkItem;
  workItemModel?: WorkItemModelEntity;
  displayCurrency?: Currency;
}

export const getTitle = ({ template, workItemModel, workItem, displayCurrency }: Options) => {
  const { dateFormat, dateTimeFormat } = Registry.get('translations');
  const { getFxRates } = Registry.get('exchangeRate');
  const { language } = Registry.get('translations');

  return formatPattern({
    workItemModelData: workItemModel?.asObjectModel?.data,
    pattern: template,
    workItem,
    options: {
      dateFormat,
      dateTimeFormat,
      fxRates: getFxRates(),
      language,
      displayCurrency,
      relativeDateTime: false,
    },
  });
};
