import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';

import { LoginFooterAsync } from '@atrigam-webclient/pages/Login/Login.footer.async';
import { LoginPageAsync } from '@atrigam-webclient/pages/Login/Login.page.async';
import { RouteParameters, RouteScope } from '@atrigam-webclient/services/Router/Router.types';
import { createRoute } from '@atrigam-webclient/services/Router/helpers/createRoute';

import { Query, pattern } from './login.path';

export const LoginRoute = createRoute<RouteParameters, Query>({
  title: () => translate('routes.login.title'),
  components: {
    main: LoginPageAsync,
    footer: LoginFooterAsync,
  },
  pattern,
  scope: RouteScope.LoggedOut,
  onBeforeEnter: () => {
    void track({
      event: AtrigamAnalyticEvents.LOGIN_EnterLogin,
      screen: AtrigamAnalyticScreens.Login,
    });
  },
});
