/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  countUserSubscriptionWithWorkItemChangedQuery,
  getUserSubscriptionFlowNodeQuery,
} from '@atrigam/server-functions-eu-clientsdk';

import { withReaction } from '@atrigam-webclient/helpers/withReaction';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { TaskFlowModelEntity } from '../entities/TaskFlowModel.entity';

export const watchModelsStoreUserSubscriptions = (taskFlowModelEntity: TaskFlowModelEntity) => {
  const modelsStore = Registry.get('modelsStore');

  const disposer = withReaction({
    name: 'TaskFlowModelEntity.watchUserSubscriptionsLastUpdatedAt',
    data: () => ({
      lastUpdatedAt: modelsStore.userSubscriptionsLastUpdatedAt,
      environment: modelsStore.environment,
    }),
    fireImmediately: true,
    onChange: async ({ lastUpdatedAt, environment }) => {
      if (taskFlowModelEntity.environment !== environment) {
        return;
      }

      if (taskFlowModelEntity.userSubscriptionsLastUpdatedAt === lastUpdatedAt) {
        return;
      }

      if (
        !taskFlowModelEntity.universe ||
        !taskFlowModelEntity.area ||
        !taskFlowModelEntity.taskFlow
      ) {
        return;
      }

      const { uid } = Registry.get('userStore');

      // update subscription counts
      const flowNode = await getUserSubscriptionFlowNodeQuery({
        environment,
        uid,
        universe: taskFlowModelEntity.universe,
        area: taskFlowModelEntity.area,
        taskFlow: taskFlowModelEntity.taskFlow,
      });
      taskFlowModelEntity.setSubscriptionCounts(flowNode?.counts);

      // update changes counts
      const changeCount = await countUserSubscriptionWithWorkItemChangedQuery({
        environment,
        uid,
        universe: taskFlowModelEntity.universe,
        area: taskFlowModelEntity.area,
        taskFlow: taskFlowModelEntity.taskFlow,
      });
      taskFlowModelEntity.setChangesCount(changeCount);

      taskFlowModelEntity.setUserSubscriptionsLastUpdatedAt(lastUpdatedAt);
    },
  });

  taskFlowModelEntity.disposers.push(disposer);
};
