import { AtrigamUser, getDateTime } from '@atrigam/atrigam-types';
import React from 'react';

const LATER_TIMEOUT = 30; // 30 minutes

interface Options {
  user: AtrigamUser | undefined;
  showBanner: boolean;
  setShowBanner: (show: boolean) => void;
}

export const useLaterTimeout = ({ user, showBanner, setShowBanner }: Options) => {
  const timerReference = React.useRef<number>();

  React.useEffect(() => {
    if (!user || user.isEmailVerified || !user.verifyEmailLater) {
      clearTimeout(timerReference.current);
      return;
    }

    // already timing
    if (timerReference.current) {
      return;
    }

    const minutes = getDateTime(user.verifyEmailLater).diffNow('minutes').minutes;
    const timeout = LATER_TIMEOUT - minutes;

    // timed already out
    if (timeout <= 0) {
      setShowBanner(true);
      return;
    }

    timerReference.current = window.setTimeout(
      () => {
        setShowBanner(true);
      },
      timeout * 60 * 1000,
    );

    if (showBanner) {
      setShowBanner(false);
    }
  }, [user?.isEmailVerified, user?.verifyEmailLater, setShowBanner, user, showBanner]);

  // only clear on unmount
  React.useEffect(() => {
    return () => {
      clearTimeout(timerReference.current);
    };
  }, []);
};
