import { track, trackingConfiguration, updateTracking } from '@atrigam/atrigam-tracking';
import { Timezone } from '@atrigam/atrigam-types';

import { getLanguageFromIntl } from '@atrigam-webclient/helpers/getLanguageFromIntl';
import { routes } from '@atrigam-webclient/routes';
import { LocalStorage } from '@atrigam-webclient/services/LocalStorage/LocalStorage';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { RouterService } from '@atrigam-webclient/services/Router/Router.service';
import { TranslationService } from '@atrigam-webclient/services/Translation/Translation.service';
import { collectTranslations } from '@atrigam-webclient/services/Translation/helpers/collectTranslations';
import { AppStore } from '@atrigam-webclient/stores/AppStore/App.store';
import { UserStore } from '@atrigam-webclient/stores/UserStore/User.store';

import { firebaseMessagingConfig } from '../../configuration/firebase.config.messaging';
import { insightsPageStoreFactory } from '../../pages/Insights/stores/Insights.page.store.factory';
import { workItemPageStoreFactory } from '../../pages/WorkItem/stores/WorkItem.page.store.factory';
import { ModelsStore } from '../../stores/ModelsStore/Models.store';
import { SubscriptionsStore } from '../../stores/SubscriptionsStore/Subscriptions.store';
import { UserNotificationsStore } from '../../stores/UserNotificationsStore/UserNotifications.store';
import { ExchangeRateService } from '../ExchangeRate/ExchangeRate.service';
import { PushNotificationsService } from '../PushNotifications/PushNotifications.service';
import { SnackbarService } from '../Snackbar/Snackbar.service';

export const initializeRegistry = () => {
  Registry.set('storage', () => new LocalStorage());

  // create translations
  Registry.set('translations', () => {
    // todo: persist the language and let users decide on the page
    const language = getLanguageFromIntl();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const translations = collectTranslations(language);

    return new TranslationService({
      language,
      timezone,
      translations,
    });
  });

  Registry.set('pushNotifications', () => {
    return new PushNotificationsService(firebaseMessagingConfig);
  });

  Registry.set(
    'router',
    () =>
      new RouterService({
        routes,
        fallbackRoute: 'HomeRoute',
      }),
  );

  Registry.set('snackbar', () => {
    return new SnackbarService();
  });

  Registry.set('exchangeRate', () => new ExchangeRateService());

  // dev helpers
  Registry.set('tracking', () => ({
    updateTracking,
    track,
    trackingConfiguration,
  }));

  // stores
  Registry.set('appStore', () => new AppStore());
  Registry.set('modelsStore', () => new ModelsStore());
  Registry.set('subscriptionsStore', () => new SubscriptionsStore());
  Registry.set('userStore', () => new UserStore());
  Registry.set('userNotificationsStore', () => new UserNotificationsStore());

  // page stores
  Registry.set('workItemPageStore', () => workItemPageStoreFactory.create());
  Registry.set('insightsPageStore', () => insightsPageStoreFactory.create());
};
