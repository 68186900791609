import { DestructibleService } from '@atrigam/atrigam-service-registry';
import { updateTracking } from '@atrigam/atrigam-tracking';
import {
  AtrigamAreaName,
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamModelsModel,
  AtrigamUniverseAreaTaskFlow,
  AtrigamUniverseName,
  ISODateTime,
  UID,
  createAtrigamUniverseAreaTaskFlow,
  extractAtrigamUniverseAreaTaskFlow,
  isAtrigamUserProfileFlow,
} from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable, values } from 'mobx';
import { persist } from 'mobx-persist';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { sentry } from '@atrigam-webclient/services/Sentry/helpers/initializeSentry';

import { EnvironmentModelsEntity } from './entities/EnvironmentModels.entity';
import { TaskFlowModelEntity } from './entities/TaskFlowModel.entity';
import { persistModelsStore } from './helpers/persistModelsStore';
import { syncEnvironmentFromUrl } from './helpers/syncEnvironmentFromUrl';
import { watchUserStoreRoles } from './helpers/watchUserStoreRoles';
import { watchUserSubscriptionsRootNodeUpdatedAt } from './helpers/watchUserSubscriptionsRootNodeUpdatedAt';

interface GetModelOptions {
  // needed for watcher
  environment?: AtrigamEnvironment;
  universe: AtrigamUniverseName;
}

export class ModelsStore extends DestructibleService {
  static PERSISTANCE_VERSION = 22;

  @persist('map', EnvironmentModelsEntity)
  @observable
  environmentModels = observable.map<string, EnvironmentModelsEntity>();

  @persist
  @observable
  environment = AtrigamEnvironment.Production;

  @persist
  @observable
  persistedVersion?: number;

  @observable
  isInitialized = false;

  @observable
  isSyncingModels = false;

  @persist
  @observable
  userSubscriptionsLastUpdatedAt?: ISODateTime;

  @persist
  @observable
  userSubscriptionsUid?: UID;

  @observable
  isWatchingSubscriptions = false;

  private _userSubscriptionDisposers: (() => void)[] = [];

  constructor() {
    super();

    makeObservable(this);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    persistModelsStore(this).then(() => {
      this.startWatchers();
      syncEnvironmentFromUrl(this);
      this.setInitializationFinished();
    });

    this.disposers.push(() => this.stopWatchingSubscriptions());
  }

  /**
   * get all models for the current environment
   */
  @computed
  get universeModelList() {
    if (!this.environmentModels.get(this.environment)) {
      return [];
    }
    return values(this.environmentModels.get(this.environment)!.models);
  }

  @computed
  get userTaskFlowModelList() {
    const { userClientRoles } = Registry.get('userStore');

    const universeNames = userClientRoles.getUniverseNamesForEnvironment({
      environment: this.environment,
    });

    const filteredFlows: TaskFlowModelEntity[] = [];
    universeNames.forEach((universe) => {
      const universeModel = this.getUniverseModel({ universe });
      if (!universeModel) {
        return;
      }

      const universeClientRolesModel = userClientRoles.universes.get(universe)!;
      const taskFlowNames = universeClientRolesModel.getTaskFlowNamesForEnvironment({
        environment: this.environment,
      });

      taskFlowNames.forEach((taskFlowName) => {
        const taskFlowModel = universeModel.taskFlowModelList.get(taskFlowName);
        if (taskFlowModel) {
          // don't add atrigam user profile to userFlows
          if (isAtrigamUserProfileFlow(taskFlowModel.universeAreaTaskFlow)) {
            return;
          }

          filteredFlows.push(taskFlowModel);
          return;
        }
      });
    });

    return filteredFlows;
  }

  @action
  getUniverseModelMapForEnvironment = (environment: AtrigamEnvironment) => {
    if (!this.environmentModels.has(environment)) {
      const environmentModel = new EnvironmentModelsEntity({ environment });
      this.environmentModels.set(environment, environmentModel);
      return environmentModel.models;
    }

    return this.environmentModels.get(environment)!.models;
  };

  @action
  hasTaskFlowsInEnvironment = (environment: AtrigamEnvironment) => {
    const universeModelMap = this.environmentModels.get(environment)?.models;
    if (!universeModelMap) {
      return false;
    }

    return values(universeModelMap).some((universeModel) =>
      values(universeModel.taskFlowModelList).some(
        (taskFlowModel) =>
          !isAtrigamUserProfileFlow(
            createAtrigamUniverseAreaTaskFlow({
              universe: taskFlowModel.universe ?? ('' as AtrigamUniverseName),
              area: taskFlowModel.area ?? ('' as AtrigamAreaName),
              taskFlow: (taskFlowModel.name ?? '') as AtrigamTaskFlowName,
            }),
          ),
      ),
    );
  };

  @action
  removeEnvironmentModels = (environment: AtrigamEnvironment) => {
    if (!this.environmentModels.has(environment)) {
      return;
    }

    this.environmentModels.get(environment)!._destruct();
    this.environmentModels.delete(environment);
  };

  @action
  removeUniverseModel = ({
    environment,
    universe,
  }: {
    environment: AtrigamEnvironment;
    universe: AtrigamUniverseName;
  }) => {
    if (!this.environmentModels.has(environment)) {
      return;
    }

    this.environmentModels.get(environment)!.removeModel(universe.toLowerCase());
  };

  @action
  reset = () => {
    values(this.environmentModels).forEach((environmentModel) => environmentModel._destruct());
    this.environmentModels.clear();
    this.persistedVersion = undefined;
    this.userSubscriptionsLastUpdatedAt = undefined;

    this.updateEnvironment(AtrigamEnvironment.Production);
  };

  @action
  setInitializationFinished = () => {
    this.isInitialized = true;
  };

  @action
  setIsSyncingModels = (syncing: boolean) => {
    this.isSyncingModels = syncing;
  };

  @action
  setUserSubscriptionsLastUpdatedAt(updatedAt: ISODateTime) {
    this.userSubscriptionsLastUpdatedAt = updatedAt;
  }

  @action
  startWatchers = () => {
    watchUserStoreRoles(this);
    // watchModels(this);
  };

  @action
  startWatchingSubscriptions = () => {
    if (this.isWatchingSubscriptions) {
      return;
    }

    this._userSubscriptionDisposers.push(watchUserSubscriptionsRootNodeUpdatedAt(this));

    this.isWatchingSubscriptions = true;
  };

  @action
  stopWatchers = () => {
    this.stopWatchingSubscriptions();
    this._destruct();
  };

  @action
  stopWatchingSubscriptions = () => {
    const disposers = [...this._userSubscriptionDisposers];
    disposers.forEach((dispose) => {
      dispose();
    });
    this._userSubscriptionDisposers = [];
    this.isWatchingSubscriptions = false;
  };

  @action
  updateEnvironment = (environment: AtrigamEnvironment) => {
    if (
      ![
        AtrigamEnvironment.Production,
        AtrigamEnvironment.Staging,
        AtrigamEnvironment.Testing,
      ].includes(environment)
    ) {
      return;
    }

    this.environment = environment;
    sentry.setAtrigamEnvironment(environment);
    updateTracking({
      env: environment,
    });
  };

  @action
  updateUniverseModelForEnvironment = ({
    environment,
    modelData,
  }: {
    environment: AtrigamEnvironment;
    modelData: AtrigamModelsModel;
  }) => {
    if (!this.environmentModels.has(environment)) {
      this.environmentModels.set(environment, new EnvironmentModelsEntity({ environment }));
    }

    this.environmentModels.get(environment)!.updateModel(modelData);
    this.persistedVersion = ModelsStore.PERSISTANCE_VERSION;
  };

  /**
   * get model from current environment
   */
  getTaskFlowModel = ({
    environment,
    universeAreaTaskFlow,
  }: {
    environment?: AtrigamEnvironment;
    universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow;
    // eslint-disable-next-line unicorn/consistent-function-scoping
  }) => {
    const environmentModels = this.environmentModels.get(environment ?? this.environment);
    if (!environmentModels) {
      return;
    }

    const { universe, taskFlow } = extractAtrigamUniverseAreaTaskFlow(universeAreaTaskFlow);

    const universeModel = environmentModels.models.get(universe.toLowerCase());
    if (!universeModel) {
      return;
    }

    return universeModel.taskFlowModelList.get(taskFlow);
  };

  /**
   * get model from current environment
   */
  getUniverseModel = (options: GetModelOptions) => {
    const environment = options.environment ?? this.environment;
    const environmentModels = this.environmentModels.get(environment);
    if (!environmentModels) {
      return;
    }

    return environmentModels.models.get(options.universe.toLowerCase());
  };

  getWorkItemModel = ({
    environment,
    universeAreaTaskFlow,
  }: {
    environment?: AtrigamEnvironment;
    universeAreaTaskFlow: AtrigamUniverseAreaTaskFlow;
    // eslint-disable-next-line unicorn/consistent-function-scoping
  }) => {
    const taskFlowModel = this.getTaskFlowModel({
      environment,
      universeAreaTaskFlow,
    });
    if (!taskFlowModel || !taskFlowModel.universe || !taskFlowModel.objectName) {
      return;
    }

    const universeModel = this.getUniverseModel({ environment, universe: taskFlowModel.universe });
    return universeModel ? universeModel.workItemModels.get(taskFlowModel.objectName) : undefined;
  };
}
