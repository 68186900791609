import { reactive } from '@atrigam-webclient/helpers/reactive';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { AllRouteSlots } from '@atrigam-webclient/services/Router/Router.types';

interface Properties {
  slot: AllRouteSlots;
}

const RouteSlotComponent: React.FC<Properties> = ({ slot }) => {
  const router = Registry.get('router');

  if (!router.isReady) {
    return null;
  }

  // `currentRoute` can only be accessed after `isReady` is true
  // otherwise it will throw an error
  const Component = router.currentRouteComponents[slot];
  if (!Component) {
    return null;
  }

  // The rendered component needs a `key` so it completely re-renders
  // when a route navigation occurred, meaning `router.currentRoute.pathname` changed.
  // This will happen when either the route name changed or of the route parameters changed
  // but NOT when one of the route query parameters changed.
  //
  // This is important so when a route navigation occured from one route to the same route where only
  // an id parameter has changed, we still have a clean state in all components
  // as this also leads to resetting all local state values etc.
  return <Component key={router.currentRoute.pathname} />;
};

export const RouteSlot = reactive(RouteSlotComponent);
