import { MAIN_ID } from '@atrigam-webclient/helpers/getMainElement';
import { reactive } from '@atrigam-webclient/helpers/reactive';

import { MainWrapper } from './styles/MainWrapper.style';

const MainComponent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <MainWrapper id={MAIN_ID}>{children}</MainWrapper>;
};

export const Main = reactive(MainComponent);
