import { persist } from 'mobx-persist';
import { Types } from 'mobx-persist/lib/types';
import { Props } from 'serializr';

import { createPersistSchema } from '../createPersistSchema';

export const schemaPersist = <T>(type: Types, properties: Props<Partial<T>>) => {
  return (target: object, propertyKey: string, descriptor?: PropertyDescriptor) => {
    const schema = createPersistSchema<Partial<T>>(properties);

    return persist(type, schema)(target, propertyKey, descriptor);
  };
};
