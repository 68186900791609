import { Mode } from '@atrigam-webclient/pages/Login/Login.types';
import { RouteParameters, RouteQuery } from '@atrigam-webclient/services/Router/Router.types';
import { createRoutePath } from '@atrigam-webclient/services/Router/helpers/createRoutePath';

export const pattern = '/login';

export interface Query extends RouteQuery {
  tab?: Mode;
  origin?: URL;
}

export const LoginPath = createRoutePath<RouteParameters, Query>({ pattern });
