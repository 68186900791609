import { NumberSeparator } from '../Translation.types';

interface Options {
  formatNumber: (input: number) => string;
}

export const getNumberSeparator = ({ formatNumber }: Options) => {
  const formattedValue = formatNumber(1000);

  // find seperator
  const match = new RegExp('^[0-9]*([^0-9])').exec(formattedValue);
  const separator = match?.[1] ?? '.';

  return separator as NumberSeparator;
};
