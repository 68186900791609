import { ValueWithExpiry } from './setWithExpiry';

interface Options {
  key: string;
}

export function getWithExpiry({ key }: Options) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString) as ValueWithExpiry | undefined;
  if (!item) return null;

  const isExpired = Date.now() > item.expiry;
  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}
