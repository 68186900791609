import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import { isAtrigamUserProfileFlow } from '@atrigam/atrigam-types';

import { logger } from '@atrigam-webclient/helpers/logger';
import { preloadModels } from '@atrigam-webclient/helpers/routeHelper/preloadModels';
import { InsightsNavigationAsync } from '@atrigam-webclient/pages/Insights/Insights.navigation.async';
import { InsightsPageAsync } from '@atrigam-webclient/pages/Insights/Insights.page.async';
import { insightsPageStoreFactory } from '@atrigam-webclient/pages/Insights/stores/Insights.page.store.factory';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { RouteQuery, RouteScope } from '@atrigam-webclient/services/Router/Router.types';
import { createRedirect } from '@atrigam-webclient/services/Router/helpers/createRedirect';
import { createRoute } from '@atrigam-webclient/services/Router/helpers/createRoute';

import { Parameters, pattern } from './insights.path';
import { FlowsOverviewPath } from '../flowsOverview/flowsOverview.path';

export const InsightsRoute = createRoute<Parameters, RouteQuery>({
  title: () => {
    const router = Registry.get('router');
    const modelsStore = Registry.get('modelsStore');

    const { universeAreaTaskFlow } = router.currentRoute.parameters as unknown as Parameters;
    const taskFlowModel = modelsStore.getTaskFlowModel({
      universeAreaTaskFlow,
    });

    return translate('routes.insights.title', { flowLabel: taskFlowModel?.label ?? '' });
  },
  components: {
    main: InsightsPageAsync,
    navigation: InsightsNavigationAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,

  getBreadcrumbs: ({ parameters }) => {
    const { environment, universeAreaTaskFlow } = parameters as unknown as Parameters;
    const taskFlowModel = Registry.get('modelsStore').getTaskFlowModel({
      universeAreaTaskFlow,
    });

    return [
      {
        label: translate(
          'layouts.DefaultLayout.Navigation.navigationItems.FlowOverviewNavigationItem.Overview',
        ),
        link: FlowsOverviewPath.getLink({ params: { environment } }),
      },
      {
        label: translate('routes.insights.title', { flowLabel: taskFlowModel?.label ?? 'Flow' }),
      },
    ];
  },

  canEnter: async ({ parameters: { universeAreaTaskFlow, environment } }) => {
    const modelsStore = Registry.get('modelsStore');

    // if environment differs set it correctly
    if (modelsStore.environment !== environment) {
      modelsStore.updateEnvironment(environment);
    }

    await preloadModels();

    // redirect if we are seeing the Atrigam User Profile
    if (isAtrigamUserProfileFlow(universeAreaTaskFlow)) {
      logger.warn(
        `* ${universeAreaTaskFlow} is the Atrigam User Profile Flow! Redirecting to Overview`,
      );
      return createRedirect({
        name: 'HomeRoute',
        params: {},
      });
    }

    // make sure we have a taskFlow, if taskFlow cannot be found -> redirect
    const taskFlowModel = modelsStore.getTaskFlowModel({
      universeAreaTaskFlow,
    });
    if (!taskFlowModel) {
      logger.warn(
        `* taskFlowModel cannot be found for ${universeAreaTaskFlow}! Redirecting to Overview`,
      );
      return createRedirect({
        name: 'HomeRoute',
        params: {},
      });
    }

    if (!taskFlowModel.hasUserInsights) {
      return createRedirect({
        name: 'HomeRoute',
        params: {},
      });
    }

    void track({
      event: AtrigamAnalyticEvents.INSIGHTS_ViewInsights,
      screen: AtrigamAnalyticScreens.Insights,
      area: taskFlowModel.area,
      env: environment,
      flow: taskFlowModel.taskFlow,
      flowVersion: taskFlowModel.version,
      object: taskFlowModel.objectName,
      universe: taskFlowModel.universe,
    });

    return true;
  },

  onBeforeEnter: async ({ parameters: { environment, universeAreaTaskFlow }, query }) => {
    if (Registry.hasInstance('insightsPageStore')) {
      Registry.clear('insightsPageStore');
    }

    insightsPageStoreFactory.set({ environment, universeAreaTaskFlow });
    const store = Registry.get('insightsPageStore');
    store.syncFiltersFromUrl(query);
    await store.initialize();
  },

  syncDataFromURL: ({ query }) => {
    const insightsPageStore = Registry.get('insightsPageStore');
    insightsPageStore.syncFiltersFromUrl(query);
  },

  onAfterLeave: ({ name }) => {
    if (name === 'InsightsRoute') {
      return;
    }

    insightsPageStoreFactory.reset();
    Registry.clear('insightsPageStore');
  },
});
