import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import { AtrigamEnvironment, UnhandledCaseError } from '@atrigam/atrigam-types';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { createRoutePath } from '@atrigam-webclient/services/Router/helpers/createRoutePath';

export const switchEnvironment = (environment: AtrigamEnvironment) => {
  const router = Registry.get('router');
  if (!router.isReady) {
    return false;
  }

  const parameters = router.currentRoute.parameters;
  if (!parameters.environment || parameters.environment === environment) {
    return false;
  }

  if (environment === AtrigamEnvironment.Modeler) {
    throw new Error('Modeler is not an allowed environment');
  }

  trackEnvironmentSwitch(environment);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const helper = createRoutePath<any, any>({ pattern: router.currentRoute.pattern });

  void router.goTo({
    url: helper.getLink({
      params: {
        ...parameters,
        environment,
      },
      query: {
        ...router.currentRoute.query,
      },
    }),
    transition: true,
  });

  return true;
};

const trackEnvironmentSwitch = (environment: AtrigamEnvironment) => {
  switch (environment) {
    case AtrigamEnvironment.Production: {
      void track({
        event: AtrigamAnalyticEvents.ENVIRONMENT_SWITCHER_SwitchEnvironmentToProduction,
        screen: AtrigamAnalyticScreens.EnvironmentSwitcher,
      });
      break;
    }

    case AtrigamEnvironment.Staging: {
      void track({
        event: AtrigamAnalyticEvents.ENVIRONMENT_SWITCHER_SwitchEnvironmentToStaging,
        screen: AtrigamAnalyticScreens.EnvironmentSwitcher,
      });
      break;
    }

    case AtrigamEnvironment.Testing: {
      void track({
        event: AtrigamAnalyticEvents.ENVIRONMENT_SWITCHER_SwitchEnvironmentToTesting,
        screen: AtrigamAnalyticScreens.EnvironmentSwitcher,
      });
      break;
    }

    case AtrigamEnvironment.Modeler: {
      throw new Error('Modeler is not an allowed environment');
    }

    default: {
      throw new UnhandledCaseError(environment);
    }
  }
};
