import { AtrigamEnvironment, UnhandledCaseError } from '@atrigam/atrigam-types';
import DoneIcon from '@mui/icons-material/Done';
import ReportIcon from '@mui/icons-material/Report';
import TrafficIcon from '@mui/icons-material/Traffic';
import WarningIcon from '@mui/icons-material/Warning';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { switchEnvironment } from '@atrigam-webclient/layouts/DefaultLayout/features/Navigation/features/EnvironmentSwitcher/helpers/switchEnvironment';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { AtrigamTheme } from '@atrigam-webclient/typings/atrigamTheme';

import { getEnvironmentOptions } from './helpers/getEnvironmentOptions';

const EnvironmentSwitcherComponent: React.FC = () => {
  const theme = useTheme<AtrigamTheme>();
  const isPhone = useMediaQuery((t: AtrigamTheme) => t.breakpoints.down('sm'));

  const { environment, updateEnvironment } = Registry.get('modelsStore');
  const { userClientRoles } = Registry.get('userStore');

  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

  const openMenu = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorElement(event.currentTarget);
    },
    [setAnchorElement],
  );

  const closeMenu = React.useCallback(() => {
    setAnchorElement(null);
  }, [setAnchorElement]);

  const handleClick = React.useCallback(
    (newEnvironment: AtrigamEnvironment) => () => {
      const switched = switchEnvironment(newEnvironment);
      if (!switched) {
        updateEnvironment(newEnvironment);
      }
      closeMenu();
    },
    [closeMenu, updateEnvironment],
  );

  const options = React.useMemo(
    () =>
      getEnvironmentOptions({
        currentEnvironment: environment,
        environments: userClientRoles.environments,
      }),
    [environment, userClientRoles.environments],
  );

  const color = React.useMemo(() => {
    switch (environment) {
      case AtrigamEnvironment.Production: {
        return theme.environmentHeader.production;
      }

      case AtrigamEnvironment.Staging: {
        return theme.environmentHeader.staging;
      }

      case AtrigamEnvironment.Testing: {
        return theme.environmentHeader.testing;
      }

      case AtrigamEnvironment.Modeler: {
        return '';
      }

      default: {
        throw new UnhandledCaseError(environment);
      }
    }
  }, [
    environment,
    theme.environmentHeader.production,
    theme.environmentHeader.staging,
    theme.environmentHeader.testing,
  ]);

  const label = React.useMemo(() => {
    return options
      .filter((option) => option.value === environment)
      .map((option) => (isPhone ? option.label.slice(0, 4) : option.label));
  }, [environment, isPhone, options]);

  return (
    <>
      <ListItemButton onClick={openMenu} aria-label="EnvironmentSwitchButton">
        <ListItemIcon>
          <TrafficIcon />
        </ListItemIcon>
        <ListItemText>{label}</ListItemText>
        <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
          {environment === AtrigamEnvironment.Testing && <ReportIcon sx={{ color }} />}
          {environment === AtrigamEnvironment.Staging && <WarningIcon sx={{ color }} />}
          {environment === AtrigamEnvironment.Production && <DoneIcon sx={{ color }} />}
        </ListItemIcon>
      </ListItemButton>

      <Menu
        id="environment-switcher-menu"
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {options.map(({ label: environmentLabel, value }) => (
          <MenuItem onClick={handleClick(value)} key={value} selected={value === environment}>
            <ListItemText>{environmentLabel}</ListItemText>
            {value === AtrigamEnvironment.Testing && (
              <ReportIcon sx={{ color: theme.environmentHeader.testing }} />
            )}
            {value === AtrigamEnvironment.Staging && (
              <WarningIcon sx={{ color: theme.environmentHeader.staging }} />
            )}
            {value === AtrigamEnvironment.Production && (
              <DoneIcon sx={{ color: theme.environmentHeader.production }} />
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const EnvironmentSwitcher = reactive(EnvironmentSwitcherComponent);
