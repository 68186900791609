import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import React, { Suspense } from 'react';

import { PageLoadingContext } from '@atrigam-webclient/contexts/PageLoadingContext';
import { reactive } from '@atrigam-webclient/helpers/reactive';
import { RouteSlot } from '@atrigam-webclient/patterns/RouteSlot/RouteSlot';
import { LazyPlaceholder } from '@atrigam-webclient/primitive/LazyPlaceholder/LazyPlaceholder';
import { Typography } from '@atrigam-webclient/primitive/Typography/Typography.style';
import { AtrigamTheme } from '@atrigam-webclient/typings/atrigamTheme';

import { NavigationContext } from './contexts/NavigationContext';
import { Main } from './features/Main/Main';
import { BackdropWrapper } from './styles/BackdropWrapper.style';
import { Content } from './styles/Content.style';
import { LayoutWrapper } from './styles/LayoutWrapper.style';

const DefaultLayoutComponent: React.FC = () => {
  const theme = useTheme<AtrigamTheme>();
  const isSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  const { isPageLoading, pageLoadingMessage } = React.useContext(PageLoadingContext);
  const [isNavigationOpened, setIsNavigationOpened] = React.useState(isSmaller ? false : true);
  const toggleNavigation = React.useCallback(() => {
    setIsNavigationOpened(!isNavigationOpened);
  }, [isNavigationOpened, setIsNavigationOpened]);

  const navigationContextValue = React.useMemo(
    () => ({
      isOpen: isNavigationOpened,
      toggleNavigation,
    }),
    [isNavigationOpened, toggleNavigation],
  );

  return (
    <NavigationContext.Provider value={navigationContextValue}>
      <LayoutWrapper>
        <Box display="flex" flexDirection="row">
          <Suspense fallback={<LazyPlaceholder />}>
            <RouteSlot slot="navigation" />
          </Suspense>

          <Content>
            <Suspense fallback={<LazyPlaceholder />}>
              <RouteSlot slot="header" />
            </Suspense>

            <Suspense fallback={<LazyPlaceholder />}>
              <Main>
                <BackdropWrapper open={isPageLoading}>
                  <CircularProgress size={24} />{' '}
                  <Typography variant="h6" color="secondary">
                    {pageLoadingMessage}
                  </Typography>
                </BackdropWrapper>

                <RouteSlot slot="breadcrumbs" />
                <RouteSlot slot="main" />
              </Main>
            </Suspense>
          </Content>
        </Box>
      </LayoutWrapper>
    </NavigationContext.Provider>
  );
};

export const DefaultLayout = reactive(DefaultLayoutComponent);
