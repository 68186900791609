import {
  AtrigamModelsModelFlowRecordDetailRoleInteraction,
  AtrigamObjectName,
} from '@atrigam/atrigam-types';
import { makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export class RecordDetailRoleInteractionEntity {
  @persist
  @observable
  fieldName?: string; // field name

  @persist
  @observable
  label?: string;

  @persist
  @observable
  object?: AtrigamObjectName;

  @persist
  @observable
  sequence?: number;

  constructor(data?: AtrigamModelsModelFlowRecordDetailRoleInteraction) {
    makeObservable(this);

    if (data) {
      this.fieldName = data.data;
      this.label = data.label;
      this.object = data.object;
      this.sequence = data.sequence;
    }
  }
}
