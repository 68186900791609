import { isAtrigamEnvironment } from '@atrigam/atrigam-types';

import { preloadModels } from '@atrigam-webclient/helpers/routeHelper/preloadModels';
import { withReaction } from '@atrigam-webclient/helpers/withReaction';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { ModelsStore } from '../Models.store';

export const syncEnvironmentFromUrl = (modelsStore: ModelsStore) => {
  const router = Registry.get('router');

  withReaction({
    name: 'syncEnvironmentFromUrl',
    data: () => ({
      isReady: router.isReady,
      parameters: router.isReady ? router.currentRoute.parameters : undefined,
    }),
    onChange: ({ isReady, parameters }) => {
      if (!isReady || !parameters) {
        return;
      }

      if (!parameters.environment || parameters.environment === modelsStore.environment) {
        return;
      }

      // make sure we are trying to sync an environment
      if (!isAtrigamEnvironment(parameters.environment)) {
        return;
      }

      modelsStore.updateEnvironment(parameters.environment);
      // update needs preload or it will never stop
      void preloadModels();
    },
  });
};
