/* eslint-disable @typescript-eslint/naming-convention */
import { TinyColor } from '@ctrl/tinycolor';
import { Theme } from '@mui/material';

const primary = '#D4DE25'; // green - 3c
const secondary = '#1b5e46'; // blue - 3a
const tertiary = '#e0e0e0';
const warning = '#ffcc33'; // green - 3e
const error = '#ea4848';
const success = '#84c443'; // green - 3a
const info = '#00aeff';

const textPrimary = '#4A4A4A';
const contrastText = '#FFFFFF';

const lightenRate = 7.5;
const darkenRate = 15;

export const lightTheme = {
  logo: '/logoLight.svg',
  palette: {
    mode: 'light' as const,
    primary: {
      main: primary,
      light: new TinyColor(primary).lighten(lightenRate).toHexString(),
      dark: new TinyColor(primary).darken(darkenRate).toHexString(),
      contrastText,
    },
    secondary: {
      main: secondary,
      light: new TinyColor(secondary).lighten(lightenRate).toHexString(),
      dark: new TinyColor(secondary).darken(darkenRate).toHexString(),
      contrastText,
    },
    tertiary: {
      main: tertiary,
      light: new TinyColor(tertiary).lighten(lightenRate).toHexString(),
      dark: new TinyColor(tertiary).darken(darkenRate).toHexString(),
      contrastText: '#6E6E6E',
    },
    warning: {
      main: warning,
      light: new TinyColor(warning).lighten(lightenRate).toHexString(),
      dark: new TinyColor(warning).darken(darkenRate).toHexString(),
      contrastText: '#6E6E6E',
    },
    error: {
      main: error,
      light: new TinyColor(error).lighten(lightenRate).toHexString(),
      dark: new TinyColor(error).darken(darkenRate).toHexString(),
      contrastText,
    },
    success: {
      main: success,
      light: new TinyColor(success).lighten(lightenRate).toHexString(),
      dark: new TinyColor(success).darken(darkenRate).toHexString(),
      contrastText,
    },
    info: {
      main: info,
      light: new TinyColor(info).lighten(lightenRate).toHexString(),
      dark: new TinyColor(info).darken(darkenRate).toHexString(),
      contrastText,
    },
    text: {
      primary: textPrimary,
      secondary: '#6E6E6E',
      tertiary: '#6E6E6E',
      hint: '#B9B9B9',
      disabled: 'hsl(0, 0%, 40%)',
    },
    background: {
      default: '#fafafa',
      light: '#f3f3f3',
      paper: '#fff',
    },
  },
  environmentHeader: {
    production: primary,
    staging: warning,
    testing: error,
  },
  chat: {
    bubble: {
      default: {
        background: tertiary,
        text: textPrimary,
      },
      primary: {
        background: new TinyColor(primary).lighten(lightenRate).toHexString(),
        text: textPrimary,
      },
    },
  },
  customShadows: {
    widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          padding: theme.spacing(2),
          '&.Mui-selected': {
            backgroundColor: '#F3F5FF !important',
            '&:focus': {
              backgroundColor: '#F3F5FF',
            },
          },
        }),
        button: {
          '&:hover, &:focus': {
            backgroundColor: '#F3F5FF',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#F3F5FF !important',
            '&:focus': {
              backgroundColor: '#F3F5FF',
            },
          },
        },
        button: {
          '&:hover, &:focus': {
            backgroundColor: '#F3F5FF',
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: 'white',
        },
      },
    },
  },
};
