import { darken, lighten } from '@mui/material';
import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

import { BannerVariation } from '../Banner.types';

interface Properties {
  $variation: BannerVariation;
}

export const StaticWrapper = styled.div<Properties & ThemedStyledComponent>(
  ({ $variation, theme }: Properties & ThemedStyledComponent) => css`
    .MuiPaper-root {
      ${() => {
        const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
        const getColor = theme.palette.mode === 'light' ? darken : lighten;

        switch ($variation) {
          case 'error': {
            return css`
              background-color: ${getBackgroundColor(theme.palette.error.main, 0.9)};
              color: ${getColor(theme.palette.error.main, 0.6)};
            `;
          }

          case 'info': {
            return css`
              background-color: ${getBackgroundColor(theme.palette.info.main, 0.9)};
              color: ${getColor(theme.palette.info.main, 0.6)};
            `;
          }

          case 'warning': {
            return css`
              background-color: ${getBackgroundColor(theme.palette.warning.main, 0.9)};
              color: ${getColor(theme.palette.warning.main, 0.6)};
            `;
          }

          case 'default':
          default: {
            return ``;
          }
        }
      }}
    }
  `,
);
