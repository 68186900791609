import { AtrigamModelsModelFlowRecordDetailRole } from '@atrigam/atrigam-types';
import { makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { RecordDetailRoleInteractionEntity } from './RecordDetailRoleInteraction.entity';

export class RecordDetailRoleEntity {
  @persist('map', RecordDetailRoleInteractionEntity)
  @observable
  interactions = observable.map<string, RecordDetailRoleInteractionEntity>();

  constructor(data?: AtrigamModelsModelFlowRecordDetailRole) {
    makeObservable(this);

    if (data) {
      this.interactions.clear();
      Object.entries(data).forEach(([key, interactionData]) => {
        this.interactions.set(key, new RecordDetailRoleInteractionEntity(interactionData));
      });
    }
  }
}
