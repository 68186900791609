import { getDateTime } from '@atrigam/atrigam-types';

import { SubscriptionEntity } from '../entities/Subscription.entity';

export const sortSubscriptionsAfterInvitedAt = (a: SubscriptionEntity, b: SubscriptionEntity) => {
  // handle invited at is missing
  if (!a.userSubscription.invitedAt && b.userSubscription.invitedAt) {
    return -1;
  }

  if (a.userSubscription.invitedAt && !b.userSubscription.invitedAt) {
    return 1;
  }

  if (!a.userSubscription.invitedAt && !b.userSubscription.invitedAt) {
    return 0;
  }

  // sort after datetime
  const millisecondsA = getDateTime(a.userSubscription.invitedAt).toMillis();
  const millisecondsB = getDateTime(b.userSubscription.invitedAt).toMillis();

  if (millisecondsA < millisecondsB) {
    return 1;
  }
  if (millisecondsA > millisecondsB) {
    return -1;
  }
  return 0;
};
