import { URI } from '@atrigam/atrigam-types';

const reURLS =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-]*)?\??(?:[-+=&;%@.\w]*)#?\w*)?)/gm;

export const getURLsFromString = (text: string) => {
  const match = text.match(reURLS);
  if (!match) {
    return;
  }

  return match.map((value) => value as URI);
};
