import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { IconButton } from '@mui/material';
import React from 'react';

import { ThemeContext } from '@atrigam-webclient/contexts/ThemeContext';
import { reactive } from '@atrigam-webclient/helpers/reactive';

const ThemeSwitcherComponent: React.FC = () => {
  const { theme: currentTheme, toggleTheme } = React.useContext(ThemeContext);

  return (
    <IconButton
      onClick={toggleTheme}
      aria-label={`Toggle Theme to ${currentTheme === 'light' ? 'Dark Mode' : 'Light Mode'}`}
      className="themeSwitcher"
      size="small"
    >
      {currentTheme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
};

export const ThemeSwitcher = reactive(ThemeSwitcherComponent);
