import { Timezone } from '@atrigam/atrigam-types';
import { createIntl, createIntlCache } from 'react-intl';

import { Language } from '@atrigam-webclient/typings/language';
import { Translations } from '@atrigam-webclient/typings/translations';

interface Options {
  language: Language;
  timezone: Timezone;
  translations: Translations;
}

export const getIntl = (options: Options) => {
  const { language, timezone, translations } = options;

  const intlCache = createIntlCache();
  const intl = createIntl(
    {
      locale: language,
      timeZone: timezone,
      messages: translations,
    },
    intlCache,
  );

  return intl;
};
