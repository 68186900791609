import { useTheme } from '@mui/material';
import React, { Suspense } from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { RouteSlot } from '@atrigam-webclient/patterns/RouteSlot/RouteSlot';
import { UnsupportedBrowserBanner } from '@atrigam-webclient/patterns/UnsupportedBrowserBanner/UnsupportedBrowserBanner';
import { LazyPlaceholder } from '@atrigam-webclient/primitive/LazyPlaceholder/LazyPlaceholder';
import { Logo } from '@atrigam-webclient/primitive/Logo/Logo';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { AtrigamTheme } from '@atrigam-webclient/typings/atrigamTheme';

import { Main } from './features/Main/Main';
import { Content } from './styles/Content.style';
import { InnerLayoutWrapper } from './styles/InnerLayoutWrapper.style';
import { LayoutWrapper } from './styles/LayoutWrapper.style';

interface Properties {
  // add possibility to override the slot for usage in lockscreen
  disableSlot?: boolean;
}

const UnauthorizedLayoutComponent: React.FC<React.PropsWithChildren<Properties>> = ({
  disableSlot = false,
  children,
}) => {
  const router = Registry.get('router');
  const theme = useTheme<AtrigamTheme>();

  const meta = router.isReady ? router.currentRoute.meta : undefined;

  const fullWidth = meta?.fullWidth ?? false;
  const smallLogo = meta?.smallLogo ?? false;

  const logoWidth = fullWidth ? '75px' : '30px';
  const logoHeight = fullWidth ? '300px' : '90px';
  const logoStyle = React.useMemo(
    () => ({
      display: 'block',
      margin: 'auto',
      marginTop: theme.spacing(smallLogo ? 0 : 4),
      marginBottom: theme.spacing(smallLogo ? 1 : 2),
      width: fullWidth ? '300px' : '90px',
    }),
    [fullWidth, smallLogo, theme],
  );

  return (
    <>
      <UnsupportedBrowserBanner appBar={false} />

      <LayoutWrapper>
        <InnerLayoutWrapper $fullWidth={fullWidth}>
          <Content>
            <Logo width={logoWidth} height={logoHeight} style={logoStyle} />

            <Suspense fallback={<LazyPlaceholder />}>
              <Main>
                {!disableSlot && <RouteSlot slot="main" />}

                {children}
              </Main>
            </Suspense>
          </Content>

          <Suspense fallback={<LazyPlaceholder />}>
            {!disableSlot && <RouteSlot slot="footer" />}
          </Suspense>
        </InnerLayoutWrapper>
      </LayoutWrapper>
    </>
  );
};

export const UnauthorizedLayout = reactive(UnauthorizedLayoutComponent);
