import { DestructibleService } from '@atrigam/atrigam-service-registry';
import { AtrigamRestrictionActiveState, AtrigamWorkItem } from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable } from 'mobx';

import { StepEntity } from '@atrigam-webclient/stores/ModelsStore/entities/taskFlow/Step.entity';

import { UniverseInteractionEntity } from './UniverseInteraction.entity';
import { WorkItemPageStore } from '../../WorkItem.page.store';
import { syncExpandAll } from '../helpers/syncExpandAll';

interface Options {
  flowStep: StepEntity;
  workItem: AtrigamWorkItem;
  interactionStore: WorkItemPageStore;
}

export class InteractionStepEntity extends DestructibleService {
  @observable
  name?: string;

  @observable
  sequence = 0;

  @observable
  areAllExpanded = true;

  @observable
  private _interactions: UniverseInteractionEntity[] = [];

  @observable
  private _store: WorkItemPageStore;

  constructor({ flowStep, workItem, interactionStore }: Options) {
    super();

    makeObservable(this);

    this.name = flowStep.name;
    this.sequence = flowStep.sequence;
    this._store = interactionStore;

    this._interactions = [...flowStep.interactions].map(
      (interaction) =>
        new UniverseInteractionEntity({
          taskFlowModel: interactionStore.taskFlowModel,
          interaction,
          workItem,
        }),
    );

    const disposer = syncExpandAll(this);
    this.disposers.push(disposer);
  }

  @computed
  get allInteractions() {
    return this._interactions
      .slice()
      .sort((a, b) => (a.interaction.sequence ?? 0) - (b.interaction.sequence ?? 0));
  }

  @computed
  get interactions() {
    return (
      this.allInteractions
        // filter user roles
        .filter((interaction) =>
          interaction.interaction.roles.some((role) => this._store.userRoles.includes(role)),
        )
        // restrictions
        .filter((interaction) => interaction.activeState !== AtrigamRestrictionActiveState.Hidden)

      // todo default Role
      // todo subFlows
    );
  }

  @computed
  get hasInteractions() {
    return this.interactions.length > 0;
  }

  @computed
  get hasExpandableInteractions() {
    if (!this.hasInteractions) {
      return false;
    }

    return this.interactions.some((interaction) => interaction.isExpandableType);
  }

  @action
  toggleExpandAll = () => {
    this.areAllExpanded = !this.areAllExpanded;

    this._interactions.forEach((interaction, index) => {
      interaction.setOpen(this.areAllExpanded);
      interaction.setAutofocus(index === 0 && this.areAllExpanded);
    });
  };
}
