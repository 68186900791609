import {
  AtrigamAnalyticEvents,
  AtrigamAnalyticScreens,
  track,
  trackingConfiguration,
} from '@atrigam/atrigam-tracking';
import { UID } from '@atrigam/atrigam-types';
import {
  APP_NAME_EU,
  getUserQuery,
  impersonateUserHttpOnCallFunction,
} from '@atrigam/server-functions-eu-clientsdk';
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { sentry } from '@atrigam-webclient/services/Sentry/helpers/initializeSentry';

import { getReducedUserDataSet } from './getReducedUserDataSet';

export const impersonateUser = async ({ uid }: { uid: UID }) => {
  // 1. check if we can get the user
  const user = await getUserQuery({ uid });
  if (!user) {
    Registry.get('snackbar').addNotification({
      message: 'Impersonating failed!<br>User is unknown or you dont have the rights!',
      options: {
        variant: 'error',
      },
    });
    return false;
  }

  // hard set email verified to true
  user.isEmailVerified = true;

  // Log Impersonation
  const { userOrFail: currentUser, uid: currentUserUid } = Registry.get('userStore');
  const impersonator = getReducedUserDataSet(currentUser);
  const impersonatedUser = getReducedUserDataSet({
    uid: user.uid,
    email: user.email,
    firstname: user.firstname,
    lastname: user.lastname,
  });

  sentry.addBreadcrumb({
    message: `User ${currentUser?.email ?? 'n/a'} is impersonating ${user.email ?? 'n/a'}`,
    data: { impersonator, impersonatedUser },
  });

  // Impersonate Auth
  const impersonated = await impersonateUserHttpOnCallFunction({
    uid,
    onException: (error) =>
      sentry.log({
        error: error as Error,
        context: { payload: { uid, impersonator: currentUserUid } },
      }),
    XAppEnvironment: trackingConfiguration?.appEnvironment,
    XTransactionID: sentry.transactionID,
  });
  if (!impersonated) {
    Registry.get('snackbar').addNotification({
      message: 'Impersonating failed!<br>Maybe you have no rights!',
      options: {
        variant: 'error',
      },
    });
    return false;
  }

  // refresh token
  const euAuth = getAuth(getApp(APP_NAME_EU));
  await euAuth.currentUser?.getIdToken(true);

  void track({
    event: AtrigamAnalyticEvents.IMPERSONATION_Impersonate,
    screen: AtrigamAnalyticScreens.Impersonation,
    impersonatedUser,
  });

  // 2. stop all watchers
  Registry.get('modelsStore').stopWatchers();
  Registry.get('userStore').stopWatchers();

  // 3. exchange user in store
  await Registry.get('userStore').impersonateUser(user);

  // 4. clear all stores
  Registry.get('subscriptionsStore').reset();
  Registry.get('modelsStore').reset();
  // need to reset isSyncing as it has been set to true during reset()
  Registry.get('modelsStore').setIsSyncingModels(false);
  Registry.get('subscriptionsStore').reset();

  // 5. run watchers
  Registry.get('modelsStore').startWatchers();

  return true;
};
