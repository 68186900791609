import { Menu } from '@mui/material';
import styled from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

export const MenuWrapper = styled(Menu)<ThemedStyledComponent>`
  margin-top: ${({ theme }: ThemedStyledComponent) => theme.spacing(1)};

  & .MuiList-root {
    padding: 0;
  }
`;
