import { AtrigamModelsModelFlowHeaderFormat } from '@atrigam/atrigam-types';
import { makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export class TaskFlowSummaryFormatEntity {
  @persist
  @observable
  title = '[id]';

  @persist
  @observable
  subtitle = '';

  constructor(data?: AtrigamModelsModelFlowHeaderFormat) {
    makeObservable(this);
    if (data) {
      this.title = data.title ?? '[id]';
      this.subtitle = data.subtitle ?? '';
    }
  }
}
