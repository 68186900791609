import { Backdrop } from '@mui/material';
import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

export const BackdropWrapper = styled(Backdrop)<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => css`
    z-index: 1;

    & > div {
      margin-right: ${theme.spacing(2)};
    }
  `,
);
