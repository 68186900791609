import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import {
  AtrigamSubscriptionState,
  extractAtrigamUniverseAreaTaskFlow,
  isAtrigamUserProfileFlow,
} from '@atrigam/atrigam-types';
import {
  getUserSubscriptionListQuery,
  getWorkItemQuery,
} from '@atrigam/server-functions-eu-clientsdk';

import { logger } from '@atrigam-webclient/helpers/logger';
import { preloadModels } from '@atrigam-webclient/helpers/routeHelper/preloadModels';
import { SubscriptionsNavigationAsync } from '@atrigam-webclient/pages/Subscriptions/Subscriptions.navigation.async';
import { SubscriptionsPageAsync } from '@atrigam-webclient/pages/Subscriptions/Subscriptions.page.async';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { RouteScope } from '@atrigam-webclient/services/Router/Router.types';
import { createRedirect } from '@atrigam-webclient/services/Router/helpers/createRedirect';
import { createRoute } from '@atrigam-webclient/services/Router/helpers/createRoute';

import { Parameters, Query, pattern } from './subscriptions.path';
import { FlowsOverviewPath } from '../flowsOverview/flowsOverview.path';

export const SubscriptionsRoute = createRoute<Parameters, Query>({
  title: () => {
    const { currentTaskFlowModel } = Registry.get('subscriptionsStore');

    return translate('routes.subscriptions.title', {
      flowLabel: currentTaskFlowModel?.label ?? '',
    });
  },
  components: {
    main: SubscriptionsPageAsync,
    navigation: SubscriptionsNavigationAsync,
  },
  pattern,
  scope: RouteScope.LoggedIn,

  getBreadcrumbs: ({ parameters }) => {
    const { currentTaskFlowModel } = Registry.get('subscriptionsStore');

    const { environment } = parameters as unknown as Parameters;

    return [
      {
        label: translate(
          'layouts.DefaultLayout.Navigation.navigationItems.FlowOverviewNavigationItem.Overview',
        ),
        link: FlowsOverviewPath.getLink({ params: { environment } }),
      },
      {
        label: currentTaskFlowModel?.label ?? 'Flow',
      },
    ];
  },

  canEnter: async ({ parameters: { universeAreaTaskFlow, environment }, query: { state } }) => {
    const modelsStore = Registry.get('modelsStore');

    // if environment differs set it correctly
    if (modelsStore.environment !== environment) {
      modelsStore.updateEnvironment(environment);
    }

    await preloadModels();

    // redirect if we are seeing the Atrigam User Profile
    if (isAtrigamUserProfileFlow(universeAreaTaskFlow)) {
      logger.warn(
        `* ${universeAreaTaskFlow} is the Atrigam User Profile Flow! Redirecting to Overview`,
      );
      return createRedirect({
        name: 'HomeRoute',
        params: {},
      });
    }

    // make sure we have a taskFlowModel, if taskFlowModel cannot be found -> redirect
    const taskFlowModel = modelsStore.getTaskFlowModel({
      universeAreaTaskFlow,
    });
    if (!taskFlowModel) {
      logger.warn(
        `* taskFlowModel cannot be found for ${universeAreaTaskFlow}! Redirecting to Overview`,
      );
      return createRedirect({
        name: 'HomeRoute',
        params: {},
      });
    }

    if (!state) {
      return createRedirect<Parameters, Query>({
        name: 'SubscriptionsRoute',
        params: {
          environment,
          universeAreaTaskFlow,
          state: AtrigamSubscriptionState.Pending,
        },
      });
    }

    void track({
      event: AtrigamAnalyticEvents.SUBSCRIPTIONS_OpenSubscriptions,
      screen: AtrigamAnalyticScreens.Subscriptions,
      area: taskFlowModel.area,
      env: environment,
      flow: taskFlowModel.taskFlow,
      flowVersion: taskFlowModel.version,
      object: taskFlowModel.objectName,
      universe: taskFlowModel.universe,
    });

    return true;
  },

  onBeforeEnter: async ({
    parameters: { universeAreaTaskFlow, environment },
    query: { state },
  }) => {
    const userStore = Registry.get('userStore');
    const subscriptionsStore = Registry.get('subscriptionsStore');

    const subscriptionState = state ?? AtrigamSubscriptionState.Pending;

    const { area, taskFlow, universe } = extractAtrigamUniverseAreaTaskFlow(universeAreaTaskFlow);

    const subscriptions = await getUserSubscriptionListQuery({
      uid: userStore.uid,
      environment,
      limit: 25,
      subscriptionState,
      universe,
      area,
      taskFlow,
    });

    // preload all universe objects
    const subscriptionsWithWorkItems = await Promise.all(
      subscriptions.map(async (subscription) => {
        const workItem = await getWorkItemQuery({
          universe: subscription.universe,
          environment,
          objectName: subscription.objectName,
          node: subscription.node,
        });

        return {
          subscription,
          workItem,
        };
      }),
    );

    // update store
    subscriptionsStore.setUniverseAreaTaskFlow(universeAreaTaskFlow);
    subscriptionsStore.setSubscriptionState(subscriptionState);
    subscriptionsStore.loadSubscriptions(subscriptionsWithWorkItems);
    subscriptionsStore.startWatchers();
  },

  syncDataFromURL: ({ query: { state, search, subscriptionId } }) => {
    const subscriptionsStore = Registry.get('subscriptionsStore');
    subscriptionsStore.setSubscriptionState(state ?? AtrigamSubscriptionState.Pending);
    void subscriptionsStore.setSearchString(search ?? '');
    subscriptionsStore.setLastVisitedNode(subscriptionId);
  },

  onAfterLeave: ({ name }) => {
    // don't remove if we are still on the subscriptions route
    if (name === 'SubscriptionsRoute') {
      return;
    }

    Registry.get('subscriptionsStore').setLastVisitedNode();
    Registry.get('subscriptionsStore').stopWatchers();
  },
});
