// Universes Data via Firestore
export const atrigamEU = {
  apiKey: 'AIzaSyA9pfdDaEJZ6RhU2GDkmMfGncXuxsAfTrk',
  authDomain: 'atrigam-eu.firebaseapp.com',
  databaseURL: 'https://atrigam-eu.firebaseio.com',
  projectId: 'atrigam-eu',
  storageBucket: 'atrigam-eu-universe-data',
  messagingSenderId: '694979860485',
  appId: '1:694979860485:web:58759e2d4e185f40da246c',
  persistence: true,
};
