import { AtrigamSubscriptionState } from '@atrigam/atrigam-types';

import { SubscriptionEntity } from '../entities/Subscription.entity';

export const sortSubscriptionsAfterState = (a: SubscriptionEntity, b: SubscriptionEntity) => {
  // pending first
  if (
    a.userSubscription.subscriptionState === AtrigamSubscriptionState.Pending &&
    b.userSubscription.subscriptionState !== AtrigamSubscriptionState.Pending
  ) {
    return -1;
  }

  if (
    a.userSubscription.subscriptionState !== AtrigamSubscriptionState.Pending &&
    b.userSubscription.subscriptionState === AtrigamSubscriptionState.Pending
  ) {
    return 1;
  }

  // accepted second
  if (
    a.userSubscription.subscriptionState === AtrigamSubscriptionState.Accepted &&
    b.userSubscription.subscriptionState === AtrigamSubscriptionState.Archived
  ) {
    return -1;
  }

  if (
    a.userSubscription.subscriptionState === AtrigamSubscriptionState.Archived &&
    b.userSubscription.subscriptionState === AtrigamSubscriptionState.Accepted
  ) {
    return 1;
  }

  return 0;
};
