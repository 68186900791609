import { AtrigamConditionsSet } from '@atrigam/atrigam-types';
import { makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { ConditionsSetOrSetEntity } from './ConditionsSetOrSet.entity';
import { isAtrigamConditionsSetOrEmptySet } from '../../helpers/isAtrigamConditionsSetOrEmptySet';

export class ConditionsSetEntity {
  @persist('map', ConditionsSetOrSetEntity)
  @observable
  conditionsSet = observable.map<string, ConditionsSetOrSetEntity>();

  constructor(data?: AtrigamConditionsSet) {
    makeObservable(this);

    if (data) {
      this.conditionsSet.clear();
      Object.entries(data).forEach(([key, group]) => {
        // if there are only empty sets, ignore
        if (Object.values(group).every((orSet) => isAtrigamConditionsSetOrEmptySet(orSet))) {
          return;
        }

        this.conditionsSet.set(key, new ConditionsSetOrSetEntity(group));
      });
    }
  }
}
