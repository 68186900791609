import { AtrigamModelsModelFlowCustomizations } from '@atrigam/atrigam-types';
import { makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export class TaskFlowCustomizationsEntity {
  @persist
  @observable
  title?: string;

  @persist
  @observable
  subtitle?: string;

  @persist
  @observable
  avatarDefaultColor?: string;

  @persist
  @observable
  avatarDefaultComplementaryColor?: string;

  @persist
  @observable
  avatarTitleField?: string;

  constructor(data?: AtrigamModelsModelFlowCustomizations) {
    makeObservable(this);

    if (data) {
      this.title = data.summary?.format?.title;
      this.subtitle = data.summary?.format?.subtitle;
      this.avatarDefaultColor = data.summary?.avatarDefaultColor;
      this.avatarDefaultComplementaryColor = data.summary?.avatarDefaultComplementaryColor;
      this.avatarTitleField = data.summary?.avatarTitleField;
    }
  }
}
