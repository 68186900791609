import { AtrigamDataType, AtrigamModelsModelObjectsObjectDataItem } from '@atrigam/atrigam-types';
import { computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export class WorkItemModelFieldEntity {
  @persist
  @observable
  name?: string;

  @persist
  @observable
  label?: string;

  @persist
  @observable
  type?: AtrigamDataType;

  constructor(data?: AtrigamModelsModelObjectsObjectDataItem) {
    makeObservable(this);

    if (data) {
      this.name = data.name;
      this.label = data.label;
      this.type = data.type;
    }
  }

  @computed
  get asDataItem() {
    if (!this.name || !this.label || !this.type) {
      return;
    }

    const dataItem: AtrigamModelsModelObjectsObjectDataItem = {
      name: this.name,
      label: this.label,
      type: this.type,
    };

    return dataItem;
  }
}
