import { Grid } from '@mui/material';
import styled from 'styled-components';

export const WorkItemHeaderWrapper = styled(Grid)`
  flex-wrap: nowrap;
  white-space: nowrap;

  .MuiListItemText-root span,
  .MuiListItemText-root p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
