import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';
import {
  AtrigamNotificationType,
  AtrigamUserNotification,
  getDateTime,
} from '@atrigam/atrigam-types';
import { updateUserNotificationReadMutation } from '@atrigam/server-functions-eu-clientsdk';
import { Box, Divider, Grid, ListItemButton, Typography, useTheme } from '@mui/material';
import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { WorkItemHeader } from '@atrigam-webclient/patterns/WorkItemHeader/WorkItemHeader';
import { TextWrap } from '@atrigam-webclient/primitive/TextWrap/TextWrap.style';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { sentry } from '@atrigam-webclient/services/Sentry/helpers/initializeSentry';
import { AtrigamTheme } from '@atrigam-webclient/typings/atrigamTheme';

import { createLink } from './helpers/createLink';
import { useLoadModels } from './hooks/useLoadModels';
import { useLoadUserSubscription } from './hooks/useLoadUserSubscription';
import { useLoadWorkItem } from './hooks/useLoadWorkItem';
import { getLabel } from '../UserNotificationsFilter/helpers/getLabel';

interface Properties {
  notification: AtrigamUserNotification;
  isLastItem: boolean;
  onClose: () => void;
}

const UserNotificationsListItemComponent: React.FC<Properties> = ({
  notification,
  isLastItem,
  onClose,
}) => {
  const { dateTimeFormat } = Registry.get('translations');
  const { uid } = Registry.get('userStore');
  const router = Registry.get('router');
  const theme = useTheme<AtrigamTheme>();

  const { taskFlowModel, workItemModel } = useLoadModels({ notification });
  const { workItem, loadingWorkItem } = useLoadWorkItem({ notification });
  const { userSubscription, loadingUserSubscription } = useLoadUserSubscription({
    notification,
  });

  const link = React.useMemo(() => {
    // catch any unhandled case errors and log them. should never happen
    try {
      return createLink({ notification, userSubscription });
    } catch (error) {
      sentry.log({ error: error as Error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.id, userSubscription]);

  const time = React.useMemo(
    () => getDateTime(notification.timestamp).toFormat(dateTimeFormat),
    [dateTimeFormat, notification.timestamp],
  );

  const onClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (!link) {
        return false;
      }

      void track({
        screen: AtrigamAnalyticScreens.Notification,
        event: AtrigamAnalyticEvents.NOTIFICATION_FollowNotification,
        notificationId: notification.id,
        env: notification.environment,
        area: notification.area,
        flow: notification.flow,
        universe: notification.universe,
        object: notification.objectName,
        workitemId: notification.workitemId,
      });

      void router.goTo({ url: link });
      onClose();

      return false;
    },
    [
      link,
      notification.area,
      notification.environment,
      notification.flow,
      notification.id,
      notification.objectName,
      notification.universe,
      notification.workitemId,
      onClose,
      router,
    ],
  );

  // mark unread items read after some time
  React.useEffect(() => {
    if (!notification.unread) {
      return;
    }

    window.setTimeout(() => {
      void updateUserNotificationReadMutation({
        uid,
        id: notification.id,
        unread: false,
      });
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listItemStyle = React.useMemo(
    () => ({
      borderLeftWidth: '5px',
      borderLeftStyle: 'solid',
      borderLeftColor: notification.unread ? theme.palette.primary.main : 'transparent',
    }),
    [notification.unread, theme.palette.primary.main],
  );

  const inviteHasBeenRevoked =
    notification.type === AtrigamNotificationType.Invite &&
    notification.workitemId &&
    !loadingUserSubscription &&
    !userSubscription;

  const textStyle = inviteHasBeenRevoked
    ? {
        textDecoration: 'line-through',
      }
    : {};

  return (
    <>
      <ListItemButton
        alignItems="flex-start"
        href={link}
        component="a"
        onClick={onClick}
        sx={listItemStyle}
        className="user-notifications-list-item"
      >
        <Grid container alignItems="center">
          <Grid item xs={12} className="interaction-header-subscription-header">
            {taskFlowModel && (workItem !== undefined || loadingWorkItem) && (
              <WorkItemHeader
                taskFlowModel={taskFlowModel}
                workItemModel={workItemModel}
                workItem={workItem}
              />
            )}
          </Grid>

          <Grid item xs={12} className="interaction-header-invitation">
            <Box paddingLeft={7}>
              <Typography variant="body2" component="span" display="block">
                <strong style={textStyle}>{notification.title}</strong>

                {inviteHasBeenRevoked && (
                  <i>
                    &nbsp;-&nbsp;
                    {translate(
                      'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.UserNotificationsListItem.inviteHasBeenRevoked',
                    )}
                  </i>
                )}
              </Typography>

              <TextWrap>
                <Box paddingBottom={1}>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                    display="block"
                    style={textStyle}
                  >
                    {notification.message}
                  </Typography>
                </Box>

                <Typography component="span" variant="body2" color="textSecondary" display="block">
                  {time} - {notification.type ? getLabel(notification.type) : ''}
                </Typography>
              </TextWrap>
            </Box>
          </Grid>
        </Grid>
      </ListItemButton>

      {!isLastItem && <Divider variant="inset" component="li" />}
    </>
  );
};

export const UserNotificationsListItem = reactive(UserNotificationsListItemComponent);
