import {
  AtrigamModelsModelFlowStepInteractionWriteTarget,
  AtrigamObjectName,
} from '@atrigam/atrigam-types';
import { makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export class WriteTargetEntity {
  @persist
  @observable
  data?: string;

  @persist
  @observable
  object?: AtrigamObjectName;

  constructor(data?: AtrigamModelsModelFlowStepInteractionWriteTarget) {
    makeObservable(this);

    if (data) {
      this.data = data.data;
      this.object = data.object;
    }
  }
}
