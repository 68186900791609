import { withReaction } from '@atrigam-webclient/helpers/withReaction';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { UserNotificationsStore } from '../UserNotifications.store';

export const watchUserStore = (store: UserNotificationsStore) => {
  const userStore = Registry.get('userStore');

  const disposer = withReaction({
    name: 'UserNotificationsStore.watchUserStore',
    data: () => ({
      user: userStore.user,
      userInitialized: userStore.isInitialized,
    }),
    onChange: ({ user, userInitialized }) => {
      if (!userInitialized) {
        return;
      }

      if (!user?.uid) {
        void store.disableStore();
        return;
      }

      store.enableStore({ uid: user.uid });
    },
  });

  store.disposers.push(disposer);
};
