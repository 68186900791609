// app services

import {
  initializeRegistry,
  Instanceable,
  MockableRegistryType,
} from '@atrigam/atrigam-service-registry';

import { IS_DEBUG, IS_TEST } from '@atrigam-webclient/mode';
import { InsightsPageStore } from '@atrigam-webclient/pages/Insights/stores/Insights.page.store';
import { WorkItemPageStore } from '@atrigam-webclient/pages/WorkItem/stores/WorkItem.page.store';
import { AppStore } from '@atrigam-webclient/stores/AppStore/App.store';
import { ModelsStore } from '@atrigam-webclient/stores/ModelsStore/Models.store';
import { SubscriptionsStore } from '@atrigam-webclient/stores/SubscriptionsStore/Subscriptions.store';
import { UserNotificationsStore } from '@atrigam-webclient/stores/UserNotificationsStore/UserNotifications.store';
import { UserStore } from '@atrigam-webclient/stores/UserStore/User.store';

import { ExchangeRateService } from '../ExchangeRate/ExchangeRate.service';
import type { LocalStorage } from '../LocalStorage/LocalStorage';
import { PushNotificationsService } from '../PushNotifications/PushNotifications.service';
import type { AnyRouterService } from '../Router/Router.service';
import { SnackbarService } from '../Snackbar/Snackbar.service';
import { TrackingService } from '../Tracking/Tracking.types';
import type { TranslationService } from '../Translation/Translation.service';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type ServicesAndStores = {
  // app services
  exchangeRate: Instanceable<ExchangeRateService>;
  pushNotifications: Instanceable<PushNotificationsService>;
  router: Instanceable<AnyRouterService>;
  snackbar: Instanceable<SnackbarService>;
  storage: Instanceable<LocalStorage>;
  translations: Instanceable<TranslationService>;
  // only for dev
  tracking: Instanceable<TrackingService>;

  // app stores type
  appStore: Instanceable<AppStore>;
  modelsStore: Instanceable<ModelsStore>;
  subscriptionsStore: Instanceable<SubscriptionsStore>;
  userStore: Instanceable<UserStore>;
  userNotificationsStore: Instanceable<UserNotificationsStore>;

  // page stores
  workItemPageStore: Instanceable<WorkItemPageStore>;
  insightsPageStore: Instanceable<InsightsPageStore>;
};

export const Registry = initializeRegistry<ServicesAndStores>(IS_TEST);

// `MockableRegistry` can only be used in testing.
// `initializeRegistry()` ensures the appropriate methods are available
// for testing and non-testing modes. However we need to force the types to be correct
export const MockableRegistry = Registry as MockableRegistryType<ServicesAndStores>;

// expose Registry to `window` when debugging
if (IS_DEBUG) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  (window as any).Registry = Registry;
}
