import { computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export class JsonPersistEntity<T> {
  @persist
  @observable
  private _jsonData?: string;

  constructor(data?: T) {
    makeObservable(this);

    if (data) {
      this._jsonData = JSON.stringify(data);
    }
  }

  @computed
  get value() {
    if (!this._jsonData) {
      return;
    }
    return JSON.parse(this._jsonData) as T;
  }
}
