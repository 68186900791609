import { AtrigamUser, getDateTime, throwIfNullable } from '@atrigam/atrigam-types';

type Options = Pick<AtrigamUser, 'isEmailVerified' | 'createdAt'>;

export const isEmailVerificationOverdue = ({ isEmailVerified, createdAt }: Options) => {
  if (!isEmailVerified) {
    return false;
  }

  throwIfNullable('Created At cannot be undefined', createdAt);

  const days = getDateTime(createdAt).diffNow('days').days;
  return days > 7;
};
