import { stopImpersonateUser } from '@atrigam-webclient/helpers/stopImpersonateUser';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { RouteScope } from '@atrigam-webclient/services/Router/Router.types';
import { createRedirect } from '@atrigam-webclient/services/Router/helpers/createRedirect';
import { createRedirectRoute } from '@atrigam-webclient/services/Router/helpers/createRedirectRoute';

import { pattern } from './unimpersonate.path';

export const UnimpersonateRoute = createRedirectRoute({
  pattern,
  scope: RouteScope.LoggedIn,
  onRedirect: async () => {
    const userStore = Registry.get('userStore');

    const redirectToHome = createRedirect<undefined, undefined>({
      name: 'HomeRoute',
      params: undefined,
    });

    // already impersonating
    if (!userStore.isImpersonating) {
      return redirectToHome;
    }

    // unimpersonate
    await stopImpersonateUser();

    return redirectToHome;
  },
});
