import { ListItemButton } from '@mui/material';
import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

interface Properties {
  $isActive: boolean;
  component?: 'a';
  href?: string;
}

export const NavigationItemWrapper = styled(ListItemButton)<Properties & ThemedStyledComponent>`
  text-decoration: none;
  &:hover,
  &:focus {
    background-color: ${({ theme }: ThemedStyledComponent) => theme.palette.background.light};
  }

  ${({ $isActive, theme }: Properties & ThemedStyledComponent) => {
    if ($isActive) {
      return css`
        background-color: ${theme.palette.background.light};
      `;
    }
  }}
`;
