import { UID } from '@atrigam/atrigam-types';
import { Subscriber } from '@atrigam/server-functions-eu-clientsdk';
import { action, makeObservable, observable } from 'mobx';

import { sortByDateFunction } from '@atrigam-webclient/helpers/sortByDate';

import { UserData, UserProfileEntity } from './entities/UserProfile.entity';

export class TeamStore {
  @observable
  subscribers: Subscriber[] = [];

  /**
   * Cache for users so we don't need to fetch the same data over and over again
   */
  @observable
  userProfiles = observable.map<UID, UserProfileEntity>();

  constructor() {
    makeObservable(this);

    this.userProfiles.clear();
  }

  @action
  addUserProfile = ({ uid, user }: { uid: UID; user: UserData }) => {
    const userProfile = this.userProfiles.get(uid);
    if (userProfile) {
      userProfile.update(user);
      return;
    }

    this.userProfiles.set(uid, new UserProfileEntity(user));
  };

  @action
  updateSubscribers = (subscribers: Subscriber[]) => {
    this.subscribers = subscribers.sort((a, b) => {
      const invitedA = a.invitedRoles
        .map((invitedRole) => invitedRole.invitedAt)
        .sort((m, n) => sortByDateFunction(m, n))
        .shift();

      const invitedB = b.invitedRoles
        .map((invitedRole) => invitedRole.invitedAt)
        .sort((m, n) => sortByDateFunction(m, n))
        .shift();

      if (!invitedA) {
        if (!invitedB) {
          return 0;
        }
        return 1;
      }

      if (!invitedB) {
        return -1;
      }

      return sortByDateFunction(invitedA, invitedB);
    });

    // add subscribers to user profile cache so we have a minimal data set upfront
    subscribers.map((subscriber) => this.addUserProfile({ uid: subscriber.uid, user: subscriber }));
  };
}
