import { getGlobalState } from '@atrigam-webclient/helpers/getGlobalState';
import { reactive } from '@atrigam-webclient/helpers/reactive';

import { AppVersionWrapper } from './styles/AppVersionWrapper.style';

const AppVersionComponent: React.FC = () => {
  const { appVersion } = getGlobalState();
  return <AppVersionWrapper>v{appVersion}</AppVersionWrapper>;
};

export const AppVersion = reactive(AppVersionComponent);
