import { DestructibleService } from '@atrigam/atrigam-service-registry';
import {
  AtrigamAreaName,
  AtrigamBase64Data,
  AtrigamEnvironment,
  AtrigamFirestoreUniverseKpi,
  AtrigamFirestoreUserSubscriptionsUserEnvironmentFlowDocumentCounts,
  AtrigamTaskFlowName,
  AtrigamModelsModelFlow,
  AtrigamModelsModelFlowExtensions,
  AtrigamObjectName,
  AtrigamRole,
  AtrigamUniverseAreaTaskFlow,
  AtrigamUniverseName,
  Email,
  FirestoreTimestamp,
  ISODateTime,
  UUID,
  createAtrigamUniverseAreaTaskFlow,
  throwIfNullable,
} from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { JsonPersistEntity } from '@atrigam-webclient/helpers/mobx/JsonPersist.entity';
import { schemaPersist } from '@atrigam-webclient/helpers/mobx/decorators/schemaPersist';

import { StepEntity } from './taskFlow/Step.entity';
import { TaskFlowCustomizationsEntity } from './taskFlow/TaskFlowCustomizations.entity';
import { TaskFlowNotificationsEntity } from './taskFlow/TaskFlowNotifications.entity';
import { TaskFlowSummaryEntity } from './taskFlow/TaskFlowSummary.entity';
import { InsightsEntity } from './taskFlow/insights/Insights.entity';
import { RecordDetailEntity } from './taskFlow/recordDetail/RecordDetail.entity';
import { sortBySequence } from '../helpers/sortBySequence';
import { watchModelsStoreUserSubscriptions } from '../helpers/watchModelsStoreUserSubscriptions';
import { watchUniverseKpis } from '../helpers/watchUniverseKpis';

interface Options {
  areaLabel?: string;
  environment?: AtrigamEnvironment;
  flowData?: AtrigamModelsModelFlow;
  universeLabel?: string;

  // for tests
  disableWatcher?: boolean;
}

export class TaskFlowModelEntity extends DestructibleService {
  @persist
  @observable
  area?: AtrigamAreaName;

  @persist
  @observable
  base64Icon?: AtrigamBase64Data;

  @persist('object', TaskFlowCustomizationsEntity)
  @observable
  customizations?: TaskFlowCustomizationsEntity;

  @persist
  @observable
  environment?: AtrigamEnvironment;

  @persist
  @observable
  taskFlow?: AtrigamTaskFlowName;

  @persist('object', InsightsEntity)
  @observable
  insights?: InsightsEntity;

  @observable
  kpiUpdatedAt?: FirestoreTimestamp;

  @persist
  @observable
  name?: string;

  @persist('list', TaskFlowNotificationsEntity)
  @observable
  notifications: TaskFlowNotificationsEntity[] = [];

  @persist('list')
  @observable
  ownerList: Email[] = [];

  @persist
  @observable
  objectName?: AtrigamObjectName;

  @persist('object', RecordDetailEntity)
  @observable
  recordDetail?: RecordDetailEntity;

  @persist('list', StepEntity)
  @observable
  steps: StepEntity[] = [];

  @persist('object', TaskFlowSummaryEntity)
  @observable
  summary = new TaskFlowSummaryEntity();

  @persist
  @observable
  universe?: AtrigamUniverseName;

  @persist
  @observable
  uuid?: UUID;

  @persist
  @observable
  version?: string; // version from the model

  @schemaPersist<AtrigamFirestoreUserSubscriptionsUserEnvironmentFlowDocumentCounts>('object', {
    accepted: true,
    archived: true,
    pending: true,
  })
  @observable
  subscriptionCounts?: AtrigamFirestoreUserSubscriptionsUserEnvironmentFlowDocumentCounts;

  @persist
  @observable
  changesCount = 0;

  @persist
  @observable
  userSubscriptionsLastUpdatedAt?: ISODateTime;

  @persist
  @observable
  versionDate?: ISODateTime;

  @persist
  @observable
  private _areaLabel?: string;

  @persist('object', JsonPersistEntity)
  @observable
  private _extensions?: JsonPersistEntity<AtrigamModelsModelFlowExtensions>;

  @persist
  @observable
  private _label?: string;

  @observable
  private _universeKpis?: AtrigamFirestoreUniverseKpi[];

  @persist
  @observable
  private _universeLabel?: string;

  constructor(options?: Options) {
    super();
    makeObservable(this);

    if (options?.environment) {
      this.environment = options.environment;
    }

    this.update(options ?? {});

    if (options?.universeLabel) {
      this._universeLabel = options.universeLabel;
    }

    if (!options?.disableWatcher) {
      watchUniverseKpis(this);
    }

    watchModelsStoreUserSubscriptions(this);
  }

  @computed
  get areaLabel() {
    if (this._areaLabel) {
      return this._areaLabel;
    }

    return this.area;
  }

  @computed
  get environmentOrFail() {
    throwIfNullable('environment cannot be undefined', this.environment);
    return this.environment;
  }

  @computed
  get extensions() {
    return this._extensions?.value;
  }

  @computed
  get label() {
    if (this._label) {
      return this._label;
    }

    if (this.name) {
      return this.name;
    }

    return;
  }

  @computed
  get hasUserInsights() {
    if (!this.insights) {
      return false;
    }

    return this.insights.hasInsights;
  }

  @computed
  get initials() {
    if (!this.label) {
      return '?';
    }

    return this.label.slice(0, 1);
  }

  @computed
  get summaryFormat() {
    const title = this.customizations?.title ?? this.summary.format.title;
    const subtitle = this.customizations?.subtitle ?? this.summary.format.subtitle;
    const avatarTitleField = this.customizations?.avatarTitleField;

    return {
      title,
      subtitle,
      avatarTitleField,
      avatarDefaultColor: this.customizations?.avatarDefaultColor,
      avatarDefaultComplementaryColor: this.customizations?.avatarDefaultComplementaryColor,
    };
  }

  @computed
  get universeAreaTaskFlow() {
    if (!this.area || !this.taskFlow || !this.universe) {
      return 'unknown' as AtrigamUniverseAreaTaskFlow;
    }

    return createAtrigamUniverseAreaTaskFlow({
      area: this.area,
      taskFlow: this.taskFlow,
      universe: this.universe,
    });
  }

  @computed
  get universeKpisMap() {
    if (!this._universeKpis) {
      return {};
    }

    return this._universeKpis.reduce(
      (kpis, kpi) => {
        kpis[kpi.id] = kpi.result;
        return kpis;
      },
      {} as Record<AtrigamFirestoreUniverseKpi['id'], AtrigamFirestoreUniverseKpi['result']>,
    );
  }

  @computed
  get universeLabel() {
    if (this._universeLabel) {
      return this._universeLabel;
    }

    return this.universe;
  }

  @action
  getRecordDetailForRoles = ({ roles }: { roles: AtrigamRole[] }) => {
    if (!this.recordDetail) {
      return [];
    }

    return this.recordDetail.getRecordDetailForRoles({ roles });
  };

  @action
  getDefaultFieldUpdateNotificationsForRole = ({ role }: { role: AtrigamRole }) => {
    if (!this.notifications) {
      return;
    }

    // check if we have a record detail for the role, if not, use default
    const defaultRole = this.recordDetail?.roles.has(role) ? role : ('default' as AtrigamRole);

    const notifications = this.notifications
      .filter(
        (notification) =>
          notification.notificationType === 'fieldUpdate' &&
          notification.role === defaultRole &&
          notification.fieldName !== undefined,
      )
      .map((notification) => notification.fieldName) as string[];

    return notifications.reduce(
      (result, fieldName) => {
        result[fieldName] = true;
        return result;
      },
      {} as Record<string, boolean>,
    );
  };

  @action
  isUserFlowOwner = ({ email }: { email?: Email }) => {
    if (!email) {
      return false;
    }
    return this.ownerList.includes(email);
  };

  @action
  setChangesCount = (count: number) => {
    this.changesCount = count;
  };

  @action
  setSubscriptionCounts = (
    subscriptionCounts?: AtrigamFirestoreUserSubscriptionsUserEnvironmentFlowDocumentCounts,
  ) => {
    this.subscriptionCounts = subscriptionCounts;
  };

  @action
  setUserSubscriptionsLastUpdatedAt = (updatedAt?: ISODateTime) => {
    this.userSubscriptionsLastUpdatedAt = updatedAt;
  };

  @action
  update = (options: Pick<Options, 'flowData' | 'areaLabel'>) => {
    if (options?.flowData) {
      this.area = options.flowData.area;
      this.taskFlow = options.flowData.flow;
      this.name = options.flowData.name;
      this._label = options.flowData.label;
      this.objectName = options.flowData.mainObject;
      this.base64Icon = options.flowData.base64Icon;

      if (options.flowData.extensions) {
        this._extensions = new JsonPersistEntity<AtrigamModelsModelFlowExtensions>(
          options.flowData.extensions,
        );
      } else {
        this._extensions = undefined;
      }

      if (options.flowData.notifications) {
        this.notifications = Object.values(options.flowData.notifications).map(
          (notification) => new TaskFlowNotificationsEntity(notification),
        );
      } else {
        this.notifications = [];
      }

      if (options.flowData.insights) {
        this.insights = new InsightsEntity(options.flowData);
      } else {
        this.insights = undefined;
      }

      this.ownerList = options.flowData.owner
        ? Object.values(options.flowData.owner).map((owner) => owner.email)
        : [];

      this.recordDetail = new RecordDetailEntity(options.flowData);

      this.steps = Object.values(options.flowData.steps)
        .sort(sortBySequence)
        .map((stepData) => new StepEntity(stepData));

      this.summary = new TaskFlowSummaryEntity(options.flowData.summary);

      if (options.flowData.customizations) {
        this.customizations = new TaskFlowCustomizationsEntity(options.flowData.customizations);
      } else {
        this.customizations = undefined;
      }

      this.universe = options.flowData.universe;
      this.uuid = options.flowData.uuid;
      this.version = options.flowData.version;
      this.versionDate = options.flowData.versionDate;
    }

    if (options?.areaLabel) {
      this._areaLabel = options.areaLabel;
    }
  };

  @action
  updateUniverseKpis = ({
    kpis,
    kpiUpdatedAt,
  }: {
    kpis: AtrigamFirestoreUniverseKpi[];
    kpiUpdatedAt: FirestoreTimestamp;
  }) => {
    this._universeKpis = kpis;
    this.kpiUpdatedAt = kpiUpdatedAt;
  };
}
