import { reactive } from '@atrigam-webclient/helpers/reactive';
import { TertiaryButton } from '@atrigam-webclient/primitive/Button/TertiaryButton';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { toggleLanguage } from '@atrigam-webclient/services/Translation/helpers/toggleLanguage';

const LanguageSwitcherComponent: React.FC = () => {
  const { language } = Registry.get('translations');

  return (
    <TertiaryButton onClick={toggleLanguage} aria-label="switch-language" size="small">
      {language}
    </TertiaryButton>
  );
};

export const LanguageSwitcher = reactive(LanguageSwitcherComponent);
