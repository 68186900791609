import React from 'react';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { AnyRoutePath } from '@atrigam-webclient/services/Router/Router.types';

interface Options {
  to: string;
  activeRoutes?: AnyRoutePath[];
}

// useActiveState is used to determine whether a rendered link should get
// an `.active` class so that active state can be styled accordingly.
//
// - `to` describes the string URL of that link to check
//
// - `activeRoutes` is an optional way to tell this hook the active state is not only
//    possible for the route of the given link url, but actually for all route paths in the
//    activeRoutes array.
export const useActiveState = (options: Options) => {
  const { to, activeRoutes } = options;
  const router = Registry.get('router');

  const isActive = React.useMemo(() => {
    if (activeRoutes) {
      return activeRoutes.some((route) => router.isActiveRoutePath(route));
    }

    return router.isActiveRouteURL(to);
    // we really need a recalculation when the current url changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    router.currentURL, // deliberately recalculate this when current url changed
    activeRoutes,
    to,
  ]);

  return { isActive };
};
