/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  isFirestoreClientTimestampObject,
  isFirestoreServerTimestampObject,
  isFirestoreTimestamp,
} from '@atrigam/atrigam-types';
import { Timestamp } from 'firebase/firestore';
import { AdditionalPropArgs, PropSchema } from 'serializr';
import { invariant, processAdditionalPropArgs } from 'serializr/lib/utils/utils';

/**
 * Similar to primitive, serializes instances of FirestoreTimestamp objects
 *
 * @param additionalArgs optional object that contains beforeDeserialize and/or afterDeserialize handlers
 */
export const firestoreTimestamp = (additionalArguments?: AdditionalPropArgs): PropSchema => {
  // TODO: add format option?
  let result: PropSchema = {
    serializer(value) {
      if (value === null || value === undefined) return value;
      invariant(isFirestoreTimestamp(value), 'Expected Firestore Timestamp object');
      return (value as Timestamp).toJSON();
    },
    deserializer(jsonValue, done) {
      if (jsonValue === null || jsonValue === undefined) return void done(null, jsonValue);
      if (isFirestoreClientTimestampObject(jsonValue)) {
        return void done(null, new Timestamp(jsonValue.seconds, jsonValue.nanoseconds));
      }
      if (isFirestoreServerTimestampObject(jsonValue)) {
        return void done(null, new Timestamp(jsonValue._seconds, jsonValue._nanoseconds));
      }
      invariant(true, 'Expected Firestore Timestamp object');
    },
  };
  result = processAdditionalPropArgs(result, additionalArguments);
  return result;
};
