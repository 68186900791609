import { CssBaseline, useTheme } from '@mui/material';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { GlobalFileUploadStyle } from '@atrigam-webclient/patterns/FileUploadModal/styles/GlobalFileUpload.style';
import { AtrigamTheme } from '@atrigam-webclient/typings/atrigamTheme';

import { AnimationsStyles } from './styles/Animations.style';
import { GlobalStyles } from './styles/Global.style';

/*
  Multi-Mounts only might happen in development due to hot reloading
  and in production there are no warnings at all -> fine to leave it always surpressed
*/
const GlobalStyleCollectionComponent: React.FC = () => {
  const theme = useTheme<AtrigamTheme>();
  return (
    <>
      <CssBaseline />
      <GlobalStyles theme={theme} />
      <GlobalFileUploadStyle theme={theme} />
      <AnimationsStyles />
    </>
  );
};

export const GlobalStyleCollection = reactive(GlobalStyleCollectionComponent);
