import { DestructibleService } from '@atrigam/atrigam-service-registry';
import {
  AtrigamFXRates,
  Currency,
  Day,
  ISODateTime,
  getDay,
  getNowDateTime,
} from '@atrigam/atrigam-types';
import { watchFxRates } from '@atrigam/server-functions-eu-clientsdk';
import { action, makeObservable, observable } from 'mobx';

import { watchUserStore } from './helpers/watchUserStore';
import { Registry } from '../Registry/Registry';

interface Options {
  disableWatcher?: boolean;
}

export class ExchangeRateService extends DestructibleService {
  @observable
  base?: Currency;

  @observable
  date?: Day;

  @observable
  updatedAt?: ISODateTime;

  @observable
  enabled = false;

  @observable
  private rates = observable.map<string, number>();

  @observable
  private _unsubscribeWatcher?: () => void;

  private _fxRates?: AtrigamFXRates;

  constructor(options?: Options) {
    super();

    makeObservable(this);

    if (!options?.disableWatcher) {
      watchUserStore(this);
      this.disposers.push(this.disableService);
    }
  }

  @action
  updateRates = (data?: AtrigamFXRates) => {
    if (!data) {
      return;
    }

    this.base = data.base;
    this.date = getDay(data.date);
    this.rates.clear();
    Object.entries(data.rates).forEach(([currency, rate]) => {
      this.rates.set(currency, rate);
    });

    this.updatedAt = getNowDateTime().toISO() as ISODateTime;

    this._fxRates = data;
  };

  @action
  hasRate = (currency: Currency) => {
    return this.rates.has(currency);
  };

  @action
  getRate = (currency: Currency) => {
    return this.rates.get(currency);
  };

  @action
  enableService = () => {
    // nothing todo if we are already enabled
    if (this.enabled) {
      return;
    }

    this._unsubscribeWatcher = watchFxRates({
      onUpdate: this.updateRates,
      onError: () => {
        if (Registry.get('userStore').isChangingLoginState) {
          return false;
        }

        return true;
      },
    });
    this.enabled = true;
  };

  @action
  disableService = () => {
    this.enabled = false;
    if (!this._unsubscribeWatcher) {
      return;
    }

    this._unsubscribeWatcher();
    this._unsubscribeWatcher = undefined;
  };

  getFxRates = () => this._fxRates;
}
