/* eslint-disable no-redeclare */
import { IntlShape } from 'react-intl';

import {
  TranslationKeyWithVariables,
  TranslationKeyWithoutVariables,
  TranslationVariablePrimitive,
  TranslationVariables,
} from '@atrigam-webclient/typings/translations';

export interface TranslateType {
  (key: TranslationKeyWithoutVariables): string;
  <Key extends TranslationKeyWithVariables, Variables extends TranslationVariables[Key]>(
    id: Key,
    variables: Variables,
  ): Variables extends Record<string, TranslationVariablePrimitive> ? string : React.ReactNode[];
}

export const setupWindowTranslation = (intl: IntlShape) => {
  // 1) Translating a key without variables always returns a string
  function translate(key: TranslationKeyWithoutVariables): string;

  // 2) Translating a key with variables returns either a string or a React.ReactNode[]
  // 2a) "string": if every used variable is of type string/number (primitive)
  //     -> we can return a concatenated string
  // 2b) "React.ReactNode[]": if just one used variable is a non-primitive type
  //     -> we cannot return a concatenated string, because it includes react nodes
  //     @see https://github.com/formatjs/react-intl/blob/045dda1da8c7289d9f2a00044379a8127e591e90/docs/API.md#formatmessage
  function translate<
    Key extends TranslationKeyWithVariables,
    Variables extends TranslationVariables[Key],
  >(
    id: Key,
    variables: Variables,
  ): Variables extends Record<string, TranslationVariablePrimitive> ? string : React.ReactNode[];

  // this is a mix of both overloads necessary for the implementation only
  function translate<Key extends TranslationKeyWithoutVariables | TranslationKeyWithVariables>(
    id: Key,
    variables?: Key extends TranslationKeyWithVariables ? TranslationVariables[Key] : never,
  ) {
    // Usage example:
    //
    // 1)
    // const stringResultWithoutVariables = this.translate('app.ok');
    //
    // 2a)
    // const stringResultWithVariables = this.translate(
    //   'stores.Composer.validations.validators.DefaultValidator.title.tooLong',
    //   { maxLength: 'i am a string', currentLength: 1234 },
    // );
    //
    // 2b)
    // const jsxResultWithVariables = this.translate(
    //   'stores.Composer.validations.validators.DefaultValidator.title.tooLong',
    //   { maxLength: 1234, currentLength: <strong>Hello World</strong> },
    // );

    return intl.formatMessage<React.ReactNode>({ id }, variables);
  }

  window.translate = translate;
};
