import { createTheme } from '@mui/material';

import { darkTheme } from './dark';
import { lightTheme } from './light';

const overrides = {
  typography: {
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.285rem',
    },
    h6: {
      fontSize: '1.142rem',
    },
  },
};

export const Themes = {
  light: createTheme({ ...lightTheme, ...overrides }),
  dark: createTheme({ ...darkTheme, ...overrides }),
};

export type ThemeTypes = keyof typeof Themes;
