import { AtrigamAnalyticEvents, AtrigamAnalyticScreens, track } from '@atrigam/atrigam-tracking';

import { logoutUser } from '@atrigam-webclient/api/logoutUser';
import { LoginPath } from '@atrigam-webclient/routes/login/login.path';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { SentryCategories } from '@atrigam-webclient/services/Sentry/Sentry.types';
import { sentry } from '@atrigam-webclient/services/Sentry/helpers/initializeSentry';

import { getReducedUserDataSet } from './getReducedUserDataSet';

interface Options {
  redirect?: boolean;
  ssoSignOut?: boolean;
}

export const logout = async ({ redirect = true }: Options) => {
  const userStore = Registry.get('userStore');
  userStore.setChangingLoginState(true);

  sentry.addBreadcrumb({
    category: SentryCategories.Router,
    message: `Logging out`,
    data: getReducedUserDataSet(userStore.user),
    level: 'info',
  });

  void track({
    event: AtrigamAnalyticEvents.NAVIGATION_Logout,
    screen: AtrigamAnalyticScreens.Navigation,
  });

  const router = Registry.get('router');
  router.setTransition(true);

  userStore.stopWatchers();
  Registry.get('subscriptionsStore').stopWatchers();
  Registry.get('modelsStore').stopWatchers();

  // additional stores to clear
  Registry.get('subscriptionsStore').reset();
  Registry.get('modelsStore').reset();
  // need to reset isSyncing as it has been set to true during reset()
  Registry.get('modelsStore').setIsSyncingModels(false);

  // clear user data (will also clear local storage)
  await userStore.clearUser();
  await logoutUser();

  userStore.setChangingLoginState(false);

  if (!redirect) {
    return;
  }

  // redirect to login
  void router.goTo({
    url: LoginPath.getLink({ params: {}, query: {} }),
    replaceCurrentURL: true,
  });
};
