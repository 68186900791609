import { DestructibleService } from '@atrigam/atrigam-service-registry';
import {
  AtrigamNotificationType,
  AtrigamUserNotification,
  FirestoreTimestamp,
  UID,
} from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable, values } from 'mobx';

import { setupUserNotificationsWatcher } from './helpers/setupUserNotificationsWatcher';
import { watchUserStore } from './helpers/watchUserStore';

export class UserNotificationsStore extends DestructibleService {
  @observable
  filter: (AtrigamNotificationType | null)[] = [];

  @observable
  lastNotificationSent?: FirestoreTimestamp;

  @observable
  uid?: UID;

  @observable
  updatedAt?: FirestoreTimestamp;

  @observable
  unreadCount = 0;

  @observable
  shouldUpdateNotifications = false;

  @observable
  private _notifications = observable.map<string, AtrigamUserNotification>();

  @observable
  private _unsubscribeWatcher?: () => void;

  constructor() {
    super();
    makeObservable(this);

    // watch for logins
    watchUserStore(this);

    // if we are currently watching notifications
    this.disposers.push(() => {
      if (this._unsubscribeWatcher) {
        this._unsubscribeWatcher();
      }
    });
  }

  @computed
  get hasNotifications() {
    return this._notifications.size > 0;
  }

  @computed
  get isEnabled() {
    return this.uid !== undefined;
  }

  @computed
  get notifications() {
    return [...this._notifications.values()]
      .filter((notification) => this.filter.includes(notification.type))
      .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
  }

  @computed
  get notificationTypes() {
    return [...new Set(values(this._notifications).map((notification) => notification.type))].map(
      (type) => ({
        type,
        isActive: this.filter.includes(type),
      }),
    );
  }

  @action
  enableStore = ({ uid }: { uid: UID }) => {
    // user is currently switching users, remove old one first
    if (this.uid && this.uid !== uid) {
      this.disableStore();
    }

    this.uid = uid;
    this._unsubscribeWatcher = setupUserNotificationsWatcher(this);
  };

  @action
  disableStore = () => {
    // remove watcher first
    if (this._unsubscribeWatcher) {
      this._unsubscribeWatcher();
    }

    this.uid = undefined;
    this.lastNotificationSent = undefined;
    this._notifications.clear();
  };

  @action
  setLastNotificationSent = (lastNotificationSent?: FirestoreTimestamp) => {
    this.lastNotificationSent = lastNotificationSent;
  };

  @action
  setShouldUpdateNotifications = (shouldUpdate: boolean) => {
    this.shouldUpdateNotifications = shouldUpdate;
  };

  @action
  setUnreadCount = (count: number) => {
    this.unreadCount = count;
  };

  @action
  setUpdatedAt = (updatedAt?: FirestoreTimestamp) => {
    this.updatedAt = updatedAt;
  };

  @action
  toggleFilter = (type: AtrigamNotificationType | null) => {
    // do we have the type in the filter, remove it
    if (this.filter.includes(type)) {
      this.filter = this.filter.filter((notificationType) => notificationType !== type);
      return;
    }

    this.filter.push(type);
  };

  @action
  updateNotifications = (notifications: AtrigamUserNotification[]) => {
    const types: (AtrigamNotificationType | null)[] = [];

    notifications.forEach((notification) => {
      this._notifications.set(notification.id, notification);

      types.push(notification.type);
    });

    this.filter = [...new Set(types)];
  };
}
