import React from 'react';

import { PageLoadingContextOptions } from '@atrigam-webclient/contexts/PageLoadingContext';

export const usePageLoading = () => {
  const [isPageLoading, setIsPageLoading] = React.useState(false);
  const [pageLoadingMessage, setPageLoadingMessage] = React.useState<string | undefined>();
  const setPageLoading = React.useCallback(({ loading, message }: PageLoadingContextOptions) => {
    setIsPageLoading(loading);
    setPageLoadingMessage(message);
  }, []);
  const pageLoadingContextValue = React.useMemo(
    () => ({
      isPageLoading,
      pageLoadingMessage,
      setPageLoading,
    }),
    [isPageLoading, pageLoadingMessage, setPageLoading],
  );

  return { pageLoadingContextValue };
};
