import {
  AtrigamPhoneNumber,
  AtrigamUserInfo,
  AtrigamUserProfileProfilePicture,
  Email,
  UID,
} from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable } from 'mobx';

import { createAcronymForUser } from '@atrigam-webclient/helpers/createAcronymForUser';
import { getName } from '@atrigam-webclient/helpers/getName';

export interface UserData extends Omit<AtrigamUserInfo, 'isEmailVerified' | 'email'> {
  email?: AtrigamUserInfo['email'];
}

export class UserProfileEntity {
  @observable
  uid: UID;

  @observable
  company?: string;

  @observable
  firstname?: string;

  @observable
  lastname?: string;

  @observable
  email?: Email;

  @observable
  phoneNumber?: AtrigamPhoneNumber;

  @observable
  profilePicture?: AtrigamUserProfileProfilePicture;

  @observable
  emailIsBlocked = false;

  @observable
  emailIsBounced = false;

  @observable
  emailIsMarkedAsSpam = false;

  constructor(data: UserData) {
    makeObservable(this);

    this.uid = data.uid;
    this.update(data);
  }

  @computed
  get acronym() {
    return createAcronymForUser(this);
  }

  @computed
  get name() {
    return getName({
      firstname: this.firstname,
      lastname: this.lastname,
      company: this.company,
      email: this.email,
      phoneNumber: this.phoneNumber,
    });
  }

  @action
  update = (data: UserData) => {
    this.company = data.company;
    this.email = data.email;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.phoneNumber = data.phoneNumber ?? undefined;
    this.profilePicture = data.profilePicture;
    this.uid = data.uid;

    this.emailIsBlocked = data.emailIsBlocked === true;
    this.emailIsBounced = data.emailIsBounced === true;
    this.emailIsMarkedAsSpam = data.emailIsMarkedAsSpam === true;
  };
}
