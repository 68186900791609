import { AtrigamEnvironment } from '@atrigam/atrigam-types';

import { RouteParameters } from '@atrigam-webclient/services/Router/Router.types';
import { createRoutePath } from '@atrigam-webclient/services/Router/helpers/createRoutePath';

export interface Parameters extends RouteParameters {
  environment: AtrigamEnvironment;
}

export const pattern = '/:environment';

export const FlowsOverviewPath = createRoutePath<Parameters>({ pattern });
