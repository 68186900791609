import { AtrigamInteractionImageList } from '@atrigam/atrigam-types';

export const isAtrigamInteractionImageList = (
  field: unknown,
): field is AtrigamInteractionImageList => {
  if (!field || typeof field !== 'object' || field === null) {
    return false;
  }

  return true;
};
