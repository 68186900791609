import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { RouteScope } from '@atrigam-webclient/services/Router/Router.types';
import { createRedirect } from '@atrigam-webclient/services/Router/helpers/createRedirect';
import { createRedirectRoute } from '@atrigam-webclient/services/Router/helpers/createRedirectRoute';

import { pattern } from './home.path';
import { Parameters } from '../flowsOverview/flowsOverview.path';

export const HomeRoute = createRedirectRoute({
  pattern,
  scope: RouteScope.LoggedIn,
  onRedirect: () => {
    const { environment } = Registry.get('modelsStore');
    return createRedirect<Parameters, undefined>({
      name: 'FlowsOverviewRoute',
      params: { environment },
    });
  },
});
