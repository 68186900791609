interface Options<T> {
  target: T;
  source: T;
}

/**
 * Update the target object with values from the source if they have changed
 *
 * useful if you don't want to create a new object by just overriding the old one.
 * Overriding will trigger a new mobx reaction.
 *
 * @returns
 */
export const updateObject = <T>({ target, source }: Options<T>) => {
  for (const k in source) {
    if (Object.prototype.hasOwnProperty.call(source, k)) {
      const value = source[k];

      if (target[k] !== value) {
        target[k] = value;
      }
    }
  }
};
