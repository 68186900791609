import { watchAuthStateChanged } from '@atrigam/server-functions-eu-clientsdk';

import { logger } from '@atrigam-webclient/helpers/logger';
import { logout } from '@atrigam-webclient/helpers/logout';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { UserStore } from '../User.store';

let firstTimeAtrigamEuAuth = true;

const handleUserSessionRunsOut = () => {
  logger.log('user is not logged in anymore. Logging out...');

  Registry.get('snackbar').addNotification({
    message: translate('app.user.invalidSession'),
    options: {
      variant: 'error',
      persist: false,
    },
  });

  void logout({});
};

export const watchAuthState = (userStore: UserStore) => {
  // atrigam-eu
  const unsubscribeEuAuth = watchAuthStateChanged({
    onUpdate: (user) => {
      if (firstTimeAtrigamEuAuth) {
        firstTimeAtrigamEuAuth = false;
        return;
      }

      if (!user && userStore.user && !userStore.isChangingLoginState) {
        handleUserSessionRunsOut();
      }
    },
  });

  userStore.disposers.push(unsubscribeEuAuth);
};
