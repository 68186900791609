import { IS_BETA, IS_DEV, IS_TEST } from '@atrigam-webclient/mode';

let environment = 'production';
if (IS_DEV) {
  environment = 'development';
} else if (IS_TEST) {
  environment = 'testing';
} else if (IS_BETA) {
  environment = 'beta';
}

export const sentryConfiguration = {
  dsn: 'https://30765c8f2fe74867bc9b750eaf7259d7@o457299.ingest.sentry.io/5453082',
  environment,
  release: window.__GLOBAL_STATE__.appVersion,
};
