import {
  AtrigamInteractionType,
  AtrigamModelsModelFlowStepInteraction,
  AtrigamRole,
} from '@atrigam/atrigam-types';
import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { RestrictionsEntity } from './Restrictions.entity';
import { WriteTargetEntity } from './WriteTarget.entity';

export class InteractionEntity {
  @persist
  @observable
  html?: string;

  @persist
  @observable
  inviteRole?: AtrigamRole;

  @persist
  @observable
  label?: string;

  @persist('object', RestrictionsEntity)
  @observable
  restrictions?: RestrictionsEntity;

  @persist('list')
  @observable
  roles: AtrigamRole[] = [];

  @persist
  @observable
  sequence?: number;

  @persist('list')
  @observable
  selectList: string[] = [];

  @persist
  @observable
  type?: AtrigamInteractionType;

  @persist('object', WriteTargetEntity)
  @observable
  writeTarget?: WriteTargetEntity;

  constructor(data?: AtrigamModelsModelFlowStepInteraction) {
    makeObservable(this);

    if (data) {
      this.label = data.name;
      this.type = data.interaction;
      this.sequence = data.sequence;
      this.html = data.html;

      if (data.invite) {
        this.inviteRole = data.invite.assignRole;
      }

      if (data.selectList) {
        this.selectList = Object.values(data.selectList);
      }

      this.roles = [];
      if (data.role) {
        this.roles = [data.role];
      }

      if (data.roles) {
        Object.values(data.roles).forEach((role) => this.roles.push(role));
      }

      this.restrictions = new RestrictionsEntity(data);

      this.writeTarget = data.writeTarget ? new WriteTargetEntity(data.writeTarget) : undefined;
    }
  }

  @action
  getFilteredRoles = ({ roles }: { roles: AtrigamRole[] }) => {
    return this.roles.filter((role) => roles.includes(role));
  };
}
