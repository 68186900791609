import {
  AtrigamChatMessage,
  AtrigamEnvironment,
  AtrigamWorkItemId,
  AtrigamObjectName,
  AtrigamUniverseName,
} from '@atrigam/atrigam-types';
import { watchChatMessages } from '@atrigam/server-functions-eu-clientsdk';

import { SubscriptionEntity } from '@atrigam-webclient/stores/SubscriptionsStore/entities/Subscription.entity';

import { ChatStore } from '../Chat.store';

interface Options {
  chatStore: ChatStore;
  environment: AtrigamEnvironment;
  node: AtrigamWorkItemId;
  objectName: AtrigamObjectName;
  universe: AtrigamUniverseName;
  userSubscription?: SubscriptionEntity;
}

export const setupChatMessageWatcher = ({
  chatStore,
  environment,
  node,
  objectName,
  universe,
  userSubscription,
}: Options) => {
  const unread = Math.min(userSubscription?.unreadChatMessageCount ?? 0, 100); // not more than 100 messages
  const limit = Math.max(ChatStore.MESSAGES_LIMIT, unread);

  const disposer = watchChatMessages({
    universe,
    environment,
    limit,
    node,
    objectName,
    onUpdate: (messages?: AtrigamChatMessage[]) => {
      if (messages) {
        void chatStore.updateMessages({ messages });
      }
    },
  });

  chatStore.disposers.push(() => {
    disposer();
  });
};
