interface Options {
  limit: number;
  loadedCount: number;
  subscriptionCount: number;
}

export const hasMoreSubscriptions = ({ limit, loadedCount, subscriptionCount }: Options) => {
  // workaround: if the subscriptionCount is smaller than the page limit and we already have items, then don't try to load more
  // the subscriptionCounts are probably broken.
  if (loadedCount > 0 && subscriptionCount < limit && loadedCount < subscriptionCount) {
    return false;
  }

  if (loadedCount > subscriptionCount && loadedCount % limit === 0) {
    return true;
  }

  if (loadedCount === subscriptionCount && loadedCount % limit === 0) {
    return true;
  }

  return loadedCount < subscriptionCount;
};
