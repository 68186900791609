import {
  AtrigamModelsModelFlowNotificationsFieldUpdate,
  AtrigamObjectName,
  AtrigamRole,
} from '@atrigam/atrigam-types';
import { makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export class TaskFlowNotificationsEntity {
  @persist
  @observable
  fieldName?: string;

  @persist
  @observable
  notificationType?: 'fieldUpdate';

  @persist
  @observable
  objectName?: AtrigamObjectName;

  @persist
  @observable
  role?: AtrigamRole;

  constructor(data?: AtrigamModelsModelFlowNotificationsFieldUpdate) {
    makeObservable(this);
    if (data) {
      this.fieldName = data.fieldName;
      this.notificationType = data.notificationType;
      this.objectName = data.objectName;
      this.role = data.role;
    }
  }
}
