import { Paper } from '@mui/material';
import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

export const Content = styled(Paper)<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => css`
    flex: 1;
    padding: ${theme.spacing(4)};
  `,
);
