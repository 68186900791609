import { AtrigamUniverseRegistrationClientRole } from '@atrigam/atrigam-types';

import { UserClientRolesEntity } from '@atrigam-webclient/stores/UserStore/entities/UserClientRoles.entity';

export const extractUserClientRoles = (
  userClientRoles: UserClientRolesEntity,
): AtrigamUniverseRegistrationClientRole[] =>
  [...userClientRoles.universes.values()].flatMap((roleModel) =>
    roleModel.clientRoles.map((clientModel) => ({ ...clientModel })),
  );
