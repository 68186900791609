import { RouteParameters } from '@atrigam-webclient/services/Router/Router.types';
import { createRoutePath } from '@atrigam-webclient/services/Router/helpers/createRoutePath';

export interface Parameters extends RouteParameters {
  token: string;
}

export const pattern = '/verifyEmail/:token';

export const VerifyEmailTokenPath = createRoutePath<Parameters>({ pattern });
