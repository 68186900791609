import { State } from 'router5';

import { parseSearchQuery } from '@atrigam-webclient/helpers/parseSearchQuery';

/**
 * extract all query keys in the location search and use them as a blacklist
 */
export const extractParameterKeysFromSearchQuery = (state: State) => {
  const { params } = state;

  const searchQuery = parseSearchQuery(window.location.search);
  const queryKeys = Object.keys(searchQuery);

  return Object.keys(params).filter((key) => !queryKeys.includes(key));
};
