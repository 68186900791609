import { withReaction } from '@atrigam-webclient/helpers/withReaction';

import { InteractionStepEntity } from '../entities/InteractionStep.entity';

export const syncExpandAll = (stepModel: InteractionStepEntity) => {
  return withReaction({
    name: 'syncExpandAll',
    data: () => ({
      interactionStates: stepModel.interactions.map((interaction) => interaction.isOpen),
      areAllExpanded: stepModel.areAllExpanded,
    }),
    onChange: ({ interactionStates, areAllExpanded }) => {
      const allElements = interactionStates.length;
      const openElements = interactionStates.filter((state) => state === true).length;

      if (openElements === allElements) {
        // already open
        if (areAllExpanded) {
          return;
        }
        stepModel.toggleExpandAll();
        return;
      }

      // is closed
      if (openElements === 0 && areAllExpanded) {
        stepModel.toggleExpandAll();
      }
    },
  });
};
