import {
  AtrigamUniverseRegistrationClientRole,
  isAtrigamEnvironment,
} from '@atrigam/atrigam-types';
import { getUniverseModelQuery } from '@atrigam/server-functions-eu-clientsdk';
import { values } from 'mobx';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';

interface Options {
  changedClientRoles?: AtrigamUniverseRegistrationClientRole[];
}

/**
 * Verifies we have all universe models from the user in the models store
 */
export const syncModelsFromUser = async ({ changedClientRoles = [] }: Options) => {
  const { userClientRoles } = Registry.get('userStore');
  const modelsStore = Registry.get('modelsStore');

  // already running, no need to run twice
  if (modelsStore.isSyncingModels) {
    return;
  }

  modelsStore.setIsSyncingModels(true);

  // remove all environments we don't have access to
  const environmentList = userClientRoles.environments;
  [...values(modelsStore.environmentModels)]
    .map((environmentModel) => environmentModel.environment)
    .filter((environment) => environment !== undefined && !environmentList.includes(environment))
    .forEach((environment) => modelsStore.removeEnvironmentModels(environment!));

  // first go through all environments we have
  const promises = environmentList.map(async (environment) => {
    if (!isAtrigamEnvironment(environment)) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unknown Environment: ${environment}`);
    }

    // remove universe models which we don't have access anymore
    const universeNames = userClientRoles.getUniverseNamesForEnvironment({ environment });
    const modelList = modelsStore.getUniverseModelMapForEnvironment(environment);
    [...values(modelList)]
      .map((model) => model.universe)
      .filter((universe) => universe !== undefined && !universeNames.includes(universe))
      .forEach((universe) => {
        modelsStore.removeUniverseModel({ universe: universe!, environment });
      });

    // make sure we have all universe models in the modelsStore
    await Promise.all(
      universeNames.map(async (universe) => {
        const universeModel = modelsStore.getUniverseModel({ environment, universe });
        const universeClientRolesModel = userClientRoles.universes.get(universe)!;

        const taskFlowNames = universeClientRolesModel.getTaskFlowNamesForEnvironment({
          environment,
        });

        if (universeModel !== undefined) {
          // if we have a model, remove the flows we don't have access to
          universeModel.flowNames
            .filter((flowName) => flowName !== undefined && !taskFlowNames.includes(flowName))
            .forEach((flowName) => {
              universeModel.removeFlow(flowName);
            });

          // we have all flows, nothing to do
          if (
            taskFlowNames.every(
              (taskFlowName) =>
                universeModel.flowNames.includes(taskFlowName) &&
                !changedClientRoles.some(
                  (clientRole) =>
                    clientRole.environment === environment &&
                    clientRole.universe === universe &&
                    clientRole.flow === taskFlowName,
                ),
            )
          ) {
            return;
          }
        }

        // model is missing, need to fetch it
        const modelData = await getUniverseModelQuery({ environment, universe });

        // no model found, nothing we can do
        if (!modelData) {
          return;
        }

        // new role assignment creates models with just updatedAt in them
        if (!modelData.universe) {
          return;
        }

        // save model in the current models
        modelsStore.updateUniverseModelForEnvironment({ environment, modelData });
      }),
    );
  });

  await Promise.all(promises);

  modelsStore.setIsSyncingModels(false);
};
