import {
  AtrigamEnvironment,
  AtrigamTaskFlowName,
  AtrigamModelsModel,
  AtrigamModelsModelFlow,
  AtrigamModelsModelFlowInsights,
  AtrigamModelsModelFlows,
  AtrigamModelsModelObjects,
} from '@atrigam/atrigam-types';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';

export const filterTaskFlowModelsForUserRoles = ({
  environment,
  modelData,
}: {
  environment: AtrigamEnvironment;
  modelData: AtrigamModelsModel;
}) => {
  const { userClientRoles } = Registry.get('userStore');
  const universeClientRolesModel = userClientRoles.universes.get(modelData.universe);

  let taskFlowNames: AtrigamTaskFlowName[] = [];
  if (universeClientRolesModel) {
    taskFlowNames = universeClientRolesModel.getTaskFlowNamesForEnvironment({ environment });
  }

  const taskFlowModels = filterTaskFlowModels({
    taskFlowModels: modelData.flows,
    flowNames: taskFlowNames,
    environment,
  });
  const objects = filterObjects({ objects: modelData.objects, taskFlowModels });

  return {
    ...modelData,
    flows: taskFlowModels,
    objects,
  };
};

const filterTaskFlowModels = ({
  taskFlowModels = {},
  flowNames,
  environment,
}: {
  taskFlowModels?: AtrigamModelsModelFlows;
  flowNames: AtrigamTaskFlowName[];
  environment: AtrigamEnvironment;
}): AtrigamModelsModelFlows => {
  const result = {} as AtrigamModelsModelFlows;
  Object.entries(taskFlowModels).forEach(([universeAreaTaskFlow, taskFlowModel]) => {
    if (flowNames.includes(taskFlowModel.flow)) {
      result[universeAreaTaskFlow as keyof AtrigamModelsModelFlows] = taskFlowModel;
      result[universeAreaTaskFlow as keyof AtrigamModelsModelFlows].insights = filterInsights({
        taskFlowModel,
        environment,
      });
    }
  });
  return result;
};

/**
 * We don't want users to have an all in access to the insights data, only to the ones they have access.
 */
const filterInsights = ({
  taskFlowModel,
  environment,
}: {
  taskFlowModel: AtrigamModelsModelFlow;
  environment: AtrigamEnvironment;
}) => {
  if (!taskFlowModel.insights) {
    return;
  }

  const { userClientRoles } = Registry.get('userStore');
  const roles = userClientRoles.getRolesForTaskFlow({
    area: taskFlowModel.area,
    environment,
    taskFlow: taskFlowModel.flow,
    universe: taskFlowModel.universe,
  });

  const { version, ...insightData } = taskFlowModel.insights;

  const result = { version } as AtrigamModelsModelFlowInsights;
  Object.entries(insightData).forEach(([role, roleData]) => {
    if (!roles.includes(role as keyof typeof insightData)) {
      return;
    }
    result[role as keyof typeof insightData] = roleData;
  });

  return result;
};

const filterObjects = ({
  objects = {},
  taskFlowModels = {},
}: {
  objects?: AtrigamModelsModelObjects;
  taskFlowModels?: AtrigamModelsModelFlows;
}) => {
  const objectNames = new Set(
    Object.values(taskFlowModels).map((taskFlowModel) => taskFlowModel.mainObject),
  );
  const result = {} as AtrigamModelsModelObjects;
  Object.entries(objects).forEach(([key, value]) => {
    if (!objectNames.has(key as keyof typeof objects)) {
      return;
    }
    result[key as keyof typeof objects] = value;
  });
  return result;
};
