import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { useActiveState } from '@atrigam-webclient/primitive/Link/hooks/useActiveState';
import { usePreloading } from '@atrigam-webclient/primitive/Link/hooks/usePreloading';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { AnyRoutePath } from '@atrigam-webclient/services/Router/Router.types';

import { NavigationItemIcon } from './features/NavigationItemIcon/NavigationItemIcon.style';
import { NavigationItemText } from './features/NavigationItemText/NavigationItemText.style';
import { NavigationItemWrapper } from './styles/NavigationItemWrapper.style';

export interface Properties {
  activeRoutes?: AnyRoutePath[];
  icon: React.ReactElement;
  label: string;
  testId?: string;
  to: string;
}

const NavigationItemComponent: React.FC<Properties> = ({
  activeRoutes,
  icon,
  label,
  to,
  testId,
}) => {
  const router = Registry.get('router');

  const { isActive } = useActiveState({
    to,
    activeRoutes,
  });

  const { onMouseOver, onMouseOut } = usePreloading({ to });

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // use router navigation
      event.preventDefault();
      void router.goTo({ url: to });
    },
    [router, to],
  );

  return (
    <NavigationItemWrapper
      onClick={onClick}
      $isActive={isActive}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      component="a"
      href={to}
      data-testid={testId}
    >
      <NavigationItemIcon $isActive={isActive}>{icon}</NavigationItemIcon>
      <NavigationItemText $isActive={isActive}>{label}</NavigationItemText>
    </NavigationItemWrapper>
  );
};

export const NavigationItem = reactive(NavigationItemComponent);
