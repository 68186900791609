import { ATRIGAM_PROFILE_ENVIRONMENT, isAtrigamUserProfileFlow } from '@atrigam/atrigam-types';

import { Parameters, Query } from '@atrigam-webclient/routes/editWorkItem/editWorkItem.path';
import { EditWorkItemRoute } from '@atrigam-webclient/routes/editWorkItem/editWorkItem.route';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { LifecycleData } from '../Router.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUserProfileRoute = (data: LifecycleData<any, any>) => {
  // first check if we are on the interactions route
  if (data.name !== EditWorkItemRoute.name) {
    return false;
  }

  // now check if we are on the correct user profile route
  const { universeAreaTaskFlow, environment, node } = (data as LifecycleData<Parameters, Query>)
    .parameters;

  if (!isAtrigamUserProfileFlow(universeAreaTaskFlow)) {
    return false;
  }

  if (environment !== ATRIGAM_PROFILE_ENVIRONMENT) {
    return false;
  }

  const userStore = Registry.get('userStore');
  if (userStore.userOrFail.profileNode !== node) {
    return false;
  }

  return true;
};
