import { Language } from '@atrigam-webclient/typings/language';

export const getLanguageFromIntl = () => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;

  const language = locale.slice(0, 2).toLowerCase();

  switch (language) {
    case 'de': {
      return Language.de;
    }

    case 'en':
    default: {
      return Language.en;
    }
  }
};
