import humanizeDuration from 'humanize-duration';
import { Duration, Settings as LuxonSettings } from 'luxon';
import numbro from 'numbro';

import { DestructibleService } from '@atrigam/atrigam-service-registry';
import {
  AnyDate,
  AnyDateString,
  Currency,
  CurrencySymbol,
  Timezone,
  getCalendarYear,
  getDateTime,
  getTodayDay,
} from '@atrigam/atrigam-types';
import { action, computed, makeObservable, observable } from 'mobx';
// eslint-disable-next-line import/no-extraneous-dependencies
import numbroLanguageDE from 'numbro/languages/de-DE';
import { FormatNumberOptions, FormatRelativeTimeOptions, IntlShape, createIntl } from 'react-intl';

import { Language } from '@atrigam-webclient/typings/language';
import { Translations } from '@atrigam-webclient/typings/translations';
import { Writeable } from '@atrigam-webclient/typings/utils';

import { DateTimeFormatOptions, FormatDateOptions, NumberSeparator } from './Translation.types';
import { getIntl } from './helpers/getIntl';
import { getNumberSeparator } from './helpers/getNumberSeparator';
import { calculateValue, getUnitFromSeconds } from './helpers/getUnitFromSeconds';
import { persistLanguage } from './helpers/persistLanguage';
import { setupWindowTranslation } from './helpers/setupWindowTranslation';

interface Options {
  timezone: Timezone;
  language: Language;
  translations: Translations;
  disablePersist?: boolean;
}

export const ATRIGAM_SYSTEM_CURRENCY = 'EUR' as Currency;

export class TranslationService extends DestructibleService {
  @observable
  intl: IntlShape;

  @observable
  language: Language;

  @observable
  timezone: Timezone;

  @observable
  phoneRegionCode = 'DE';

  @observable
  translations: Writeable<Translations> = {} as Translations;

  @observable
  systemCurrency = ATRIGAM_SYSTEM_CURRENCY;

  @observable
  systemCurrencySymbol = '€' as CurrencySymbol;

  @observable
  numberSeparator: NumberSeparator = '.';

  @observable
  decimalSeparator: NumberSeparator = ',';

  private germanIntl = createIntl({ locale: Language.de });

  constructor(options: Options) {
    super();
    makeObservable(this);

    const { timezone, language, translations, disablePersist = false } = options;

    this.language = language;
    this.timezone = timezone;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    numbro.registerLanguage(numbroLanguageDE);

    this.intl = getIntl({ timezone, language, translations });
    this.updateTranslations(options);

    if (!disablePersist) {
      persistLanguage(this);
    }
  }

  @computed
  // eslint-disable-next-line @typescript-eslint/class-literal-property-style
  get dateFormat() {
    return 'dd.MM.yyyy';
  }

  @computed
  // eslint-disable-next-line @typescript-eslint/class-literal-property-style
  get dateTimeFormat() {
    return 'dd.MM.yyyy HH:mm';
  }

  @action
  updateTranslations = (options: Options) => {
    const { timezone, language, translations } = options;

    this.language = language;
    this.timezone = timezone;

    this.intl = getIntl({ timezone, language, translations });
    this.numberSeparator = getNumberSeparator({ formatNumber: this.formatNumber });
    this.decimalSeparator = this.numberSeparator === '.' ? ',' : '.';
    setupWindowTranslation(this.intl);

    // numbro.setLanguage(language === Language.de ? 'de-DE' : 'en-US');
    numbro.setLanguage('de-DE');
    this._setTranslations(translations);
    this._syncLuxon();
  };

  @action
  private _setTranslations = (translations: Translations) => {
    const translationKeys = Object.keys(translations) as (keyof Translations)[];

    translationKeys.forEach((translationKey) => {
      if (this.translations[translationKey] !== translations[translationKey]) {
        this.translations[translationKey] = translations[translationKey];
      }
    });
  };

  @action
  private _syncLuxon = () => {
    // sync user locale & timezone to luxon
    LuxonSettings.defaultLocale = this.language;
    LuxonSettings.defaultZone = this.timezone;
  };

  formatDate = (date: AnyDate, config?: FormatDateOptions) => {
    const options: FormatDateOptions = {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      year: 'numeric',
      ...config,
    };

    if (options.onlyShowNotCurrentYear) {
      const dateYear = getCalendarYear(date);
      const currentYear = getCalendarYear(getTodayDay());

      // don't show the year if it's the current year
      if (dateYear === currentYear) {
        options.year = undefined;
      }
    }

    // handle timezones
    const dateWithTimezone = getDateTime(date).toJSDate();

    return this.intl.formatDate(dateWithTimezone, options);
  };

  formatRelativeDate = (date: AnyDate, config?: FormatRelativeTimeOptions) => {
    const options: FormatRelativeTimeOptions = {
      numeric: 'auto',
      ...config,
    };

    const seconds = getDateTime(date).diffNow().milliseconds / 1000;
    const unit = getUnitFromSeconds({ seconds });
    const value = calculateValue({ seconds, unit });

    return this.intl.formatRelativeTime(value, unit, options);
  };

  formatTime = (date: AnyDateString, config?: DateTimeFormatOptions) => {
    const options: DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
      ...config,
    };

    return this.intl.formatTime(date, options);
  };

  formatNumber = (value: number, options?: FormatNumberOptions) => {
    return this.germanIntl.formatNumber(value, options);
  };

  formatNumberAbbreviated = (value: number, options?: { decimalPlaces: number }) => {
    return numbro(value ?? 0).format({
      average: true,
      trimMantissa: true,
      mantissa: options?.decimalPlaces ?? 1,
    });
  };

  formatCurrency = (value: number, currency: Currency) => {
    return this.formatNumber(value, {
      currency,
      style: 'currency',
      maximumFractionDigits: 3,
    });
  };

  formatAverageCurrency = (value: number, currency: Currency) => {
    // create average from bigger values
    const template = this.formatNumber(0, {
      currency,
      style: 'currency',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      maximumSignificantDigits: 1,
    });

    const averageValue = numbro(value).format({
      average: true,
      mantissa: 1,
    });

    return template.replace('0', averageValue);
  };

  formatDuration = (
    duration: Duration,
    options: Omit<humanizeDuration.Options, 'language'> = {},
  ) => {
    const milliseconds = duration.toMillis();
    const humanize = humanizeDuration(duration.toMillis(), { ...options, language: this.language });
    return `${milliseconds < 0 ? '- ' : ''}${humanize}`;
  };
}
