import { AtrigamConditionsSetOrAndSet } from '@atrigam/atrigam-types';
import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { ConditionsSetConditionEntity } from './ConditionsSetCondition.entity';

export class ConditionsSetOrAndSetEntity {
  @persist('map', ConditionsSetConditionEntity)
  @observable
  conditions = observable.map<string, ConditionsSetConditionEntity>();

  constructor(data?: AtrigamConditionsSetOrAndSet) {
    makeObservable(this);

    if (data) {
      this.conditions.clear();
      Object.entries(data).forEach(([key, group]) => {
        this.conditions.set(key, new ConditionsSetConditionEntity(group));
      });
    }
  }

  @action
  toJS = () => {
    const data: AtrigamConditionsSetOrAndSet = {};

    this.conditions.forEach((value, key) => {
      data[key] = value.values;
    });

    return data;
  };
}
