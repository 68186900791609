import React from 'react';

const TIME_SECOND = 1000;
const TIME_MINUTE = 60 * TIME_SECOND;

export const useAutoReloadCountdown = () => {
  const timeleftReference = React.useRef<number>();

  const [endTime, setEndTime] = React.useState<number>(0);
  const [timeLeft, setTimeLeft] = React.useState<number>(0);

  const calculateTimeLeft = React.useCallback(() => {
    return endTime - Date.now();
  }, [endTime]);

  const humanReadableLeftTime = React.useMemo(() => {
    if (timeLeft > TIME_MINUTE) {
      return translate('app.UpdateAvailableNotification.minutesLeft', {
        time: Math.round(timeLeft / TIME_MINUTE),
      });
    }

    return translate('app.UpdateAvailableNotification.secondsLeft', {
      time: Math.round(timeLeft / TIME_SECOND),
    });
  }, [timeLeft]);

  // autostart new timeout every render cycle
  React.useEffect(() => {
    // countdown is disabled
    if (endTime === 0) {
      return;
    }

    // while bigger than 1 Minute, set timeout to 1 Minute, else we are talking seconds
    const timeout = timeLeft > TIME_MINUTE ? TIME_MINUTE : TIME_SECOND;

    timeleftReference.current = window.setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, timeout);
  });

  // clear timeout if reloading
  React.useEffect(() => {
    return () => {
      clearTimeout(timeleftReference.current);
    };
  }, []);

  return { setEndTime, humanReadableLeftTime, timeLeft };
};
