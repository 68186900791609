import { AtrigamWorkItem, Currency } from '@atrigam/atrigam-types';
import { ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { useSmallestImageUrl } from '@atrigam-webclient/hooks/useSmallestImageUrl';
import { isAtrigamInteractionImageList } from '@atrigam-webclient/pages/Subscriptions/features/SubscriptionItem/helpers/isAtrigamInteractionImageList';
import { TaskFlowModelEntity } from '@atrigam-webclient/stores/ModelsStore/entities/TaskFlowModel.entity';
import { WorkItemModelEntity } from '@atrigam-webclient/stores/ModelsStore/entities/WorkItemModel.entity';

import { getTitle } from './helpers/getTitle';
import { WorkItemAvatar } from './styles/WorkItemAvatar.style';
import { WorkItemHeaderWrapper } from './styles/WorkItemHeaderWrapper.style';

interface Properties {
  taskFlowModel: TaskFlowModelEntity;
  workItem?: AtrigamWorkItem;
  workItemModel?: WorkItemModelEntity;
  displayCurrency?: Currency;
}

const WorkItemHeaderComponent: React.FC<Properties> = ({
  taskFlowModel,
  workItemModel,
  displayCurrency,
  workItem,
}) => {
  const { summaryFormat } = taskFlowModel;

  const title = getTitle({
    template: summaryFormat.title,
    workItemModel,
    workItem,
    displayCurrency,
  });

  const subtitle = getTitle({
    template: summaryFormat.subtitle,
    workItemModel,
    workItem,
    displayCurrency,
  });

  const workItemAvatarTitleField =
    workItem && summaryFormat.avatarTitleField
      ? workItem[summaryFormat.avatarTitleField]
      : undefined;

  const image = React.useMemo(() => {
    if (!workItemAvatarTitleField) {
      return;
    }

    const field = workItemAvatarTitleField;

    if (isAtrigamInteractionImageList(field)) {
      const values = Object.values(field);
      if (values.length > 0) {
        const key = Object.keys(field).sort().shift();
        return key ? field[key] : values[0];
      }
    }
  }, [workItemAvatarTitleField]);

  const { imageUri } = useSmallestImageUrl({ image });

  const avatarTitle = React.useMemo(() => {
    return title.slice(0, 2).toUpperCase();
  }, [title]);

  const avatarDefaultColor = summaryFormat.avatarDefaultColor;
  const avatarDefaultComplementaryColor = summaryFormat.avatarDefaultComplementaryColor;

  return (
    <WorkItemHeaderWrapper container item alignItems="center" className="workitem-header">
      <ListItemAvatar>
        <WorkItemAvatar
          src={imageUri}
          color={avatarDefaultComplementaryColor}
          background={avatarDefaultColor}
        >
          {avatarTitle}
        </WorkItemAvatar>
      </ListItemAvatar>

      <ListItemText primary={title} secondary={subtitle} />
    </WorkItemHeaderWrapper>
  );
};

export const WorkItemHeader = reactive(WorkItemHeaderComponent);
