import { updateTracking } from '@atrigam/atrigam-tracking';
import { URI } from '@atrigam/atrigam-types';
import {
  getCurrentFirestoreUserQuery,
  getUniverseDataDownloadURL,
  getUserQuery,
} from '@atrigam/server-functions-eu-clientsdk';

import { getName } from '@atrigam-webclient/helpers/getName';
import { getSmallestImageVariationLocation } from '@atrigam-webclient/helpers/getSmallestImageVariationLocation';
import { logout } from '@atrigam-webclient/helpers/logout';
import { createMobxHydrator } from '@atrigam-webclient/helpers/mobx/createMobxHydrator';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { sentry } from '@atrigam-webclient/services/Sentry/helpers/initializeSentry';

import { UserStore } from '../User.store';

export const persistUserStore = async (userStore: UserStore) => {
  const hydrate = createMobxHydrator({ jsonify: false });

  const {
    user,
    persistedVersion: persistanceVersion,
    impersonator,
  } = await hydrate('UserStore', userStore);

  // got user data
  if (!user) {
    userStore.setInitializationFinished();
    return;
  }

  const uid = user.uid;

  // verify user is valid
  const firestoreUser = await getCurrentFirestoreUserQuery();

  // user is missing or uid did not match, logout
  if (
    !uid ||
    !firestoreUser ||
    (firestoreUser.uid !== uid && firestoreUser.uid !== impersonator?.uid)
  ) {
    if (firestoreUser && firestoreUser.uid !== uid && firestoreUser.uid !== impersonator?.uid) {
      sentry.log({
        error: new Error('user did not match'),
        context: {
          uid,
          firestoreUser,
        },
      });
    }

    await logout({});
    userStore.setInitializationFinished();
    return;
  }

  // persistance data is old
  if (persistanceVersion !== UserStore.PERSISTANCE_VERSION) {
    const userData = await getUserQuery({ uid });
    if (!userData) {
      sentry.log({
        error: new Error('user data is missing'),
        context: {
          uid,
        },
      });
      await logout({});
      userStore.setInitializationFinished();
      return;
    }

    userStore.updateUser({ user: userData, forceUpdate: true });
  }

  // check if user is platformAdmin
  await userStore.validatePlatformAdmin();
  await userStore.updateAvatar();

  sentry.setUser({
    id: uid,
    email: userStore.user?.email,
    phoneNumber: userStore.user?.phoneNumber,
    username: getName(userStore.user ?? {}),
  });

  const location = getSmallestImageVariationLocation(impersonator?.profilePicture?.profilePicture);
  let impersonatorAvatar: URI | undefined;
  if (impersonator && location) {
    impersonatorAvatar = await getUniverseDataDownloadURL(location);
  }

  updateTracking({
    uid,
    user: {
      avatar: userStore.avatarUri ?? null,
      email: userStore.user?.email ?? null,
      firstname: userStore.user?.firstname ?? null,
      lastname: userStore.user?.lastname ?? null,
      phoneNumber: userStore.user?.phoneNumber ?? null,
      isTestUser: userStore.user?.isTestUser ?? null,
    },
    isImpersonated: impersonator !== undefined,
    isImpersonatedBy: impersonator
      ? {
          avatar: impersonatorAvatar ?? null,
          email: impersonator.email ?? null,
          firstname: impersonator.firstname ?? null,
          lastname: impersonator.lastname ?? null,
          phoneNumber: impersonator.phoneNumber ?? null,
        }
      : undefined,
  });

  // only set user if we are not impersonating
  if (!Registry.get('userStore').isImpersonating) {
    Registry.get('pushNotifications').setUser({ uid });
    await Registry.get('pushNotifications').refreshToken();
  }

  // and also watch node for changes
  userStore.startWatchers();
  userStore.setInitializationFinished();
};
