import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

interface Properties {
  $isOpen: boolean;
}

export const BreadcrumbsWrapper = styled.div<ThemedStyledComponent & Properties>(
  ({ theme, $isOpen }: ThemedStyledComponent & Properties) => css`
    padding-bottom: ${theme.spacing(2)};
    padding-left: ${$isOpen ? 0 : theme.spacing(3)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${theme.breakpoints.up('lg')} {
      padding-left: ${theme.spacing(0)};
    }

    transition: ${theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })};
  `,
);
