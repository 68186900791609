import { AtrigamEnvironment, AtrigamInteractionType } from '@atrigam/atrigam-types';

import { isInteractionDisabled } from '@atrigam-webclient/helpers/interaction/isInteractionDisabled';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { TaskFlowModelEntity } from '../entities/TaskFlowModel.entity';

interface Options {
  environment: AtrigamEnvironment;
  flowModel: TaskFlowModelEntity;
}

export const canUserCreateWorkItem = ({ environment, flowModel }: Options) => {
  const { userClientRoles } = Registry.get('userStore');

  // user is missing roles for that taskFlow
  if (
    !userClientRoles.environments.includes(environment) ||
    !flowModel.area ||
    !flowModel.taskFlow ||
    !flowModel.universe
  ) {
    return false;
  }

  const userRoles = userClientRoles.getRolesForTaskFlow({
    environment,
    area: flowModel.area,
    taskFlow: flowModel.taskFlow,
    universe: flowModel.universe,
  });

  // taskFlow has no interaction
  if (flowModel.steps.length === 0 || flowModel.steps[0].interactions.length === 0) {
    return false;
  }

  // find all roles for the user for that taskFlow
  // user has no role
  if (userRoles.length === 0) {
    return false;
  }

  // look for an interaction the user can write into
  const foundInteraction = flowModel.steps.find((step) =>
    step.interactions.find((interaction) => {
      // only interactions that write into a workItem are interesting
      if (!interaction.writeTarget) {
        return;
      }

      // ignore invites
      if (interaction.type === AtrigamInteractionType.Invite) {
        return;
      }

      // check restrictions
      if (isInteractionDisabled({ interaction, universeKpisMap: flowModel.universeKpisMap })) {
        return;
      }

      // look if user has a role for that interaction
      return interaction.roles.find((interactionRole) => userRoles.includes(interactionRole));
    }),
  );

  return foundInteraction !== undefined;
};
