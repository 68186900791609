import { AtrigamPushToken, UID } from '@atrigam/atrigam-types';
import {
  APP_NAME_EU,
  removeUserFcmTokenMutation,
  updateUserFcmTokenMutation,
} from '@atrigam/server-functions-eu-clientsdk';
import { getApp } from 'firebase/app';
import { Messaging, deleteToken, getMessaging, getToken, isSupported } from 'firebase/messaging';
import { action, makeObservable, observable } from 'mobx';

import { BrowserInfo, getBrowserInfo } from '@atrigam-webclient/helpers/getBrowserInfo';
import { isCypressTest } from '@atrigam-webclient/helpers/isCypressTest';
import { logger } from '@atrigam-webclient/helpers/logger';

import { listenToServiceWorker } from './helpers/listenToServiceWorker';
import { watchPermissionAndUserUid } from './helpers/watchPermissionAndUserUid';
import { sentry } from '../Sentry/helpers/initializeSentry';

interface Options {
  vapidKey?: string;
  serviceWorkerRegistration?: ServiceWorkerRegistration;
}

export class PushNotificationsService {
  @observable
  permission?: NotificationPermission;

  @observable
  isEnabled = true;

  @observable
  supported = true;

  @observable
  isRefreshingToken = false;

  @observable
  userUid?: UID;

  private browser: BrowserInfo;
  private messaging?: Messaging;
  private options: Options;
  private token?: AtrigamPushToken;

  constructor(options: Options) {
    makeObservable(this);

    this.options = options;
    this.browser = getBrowserInfo();

    // also check if we are in firefox private window
    // check if we are using safari mobile
    if (!window.navigator.serviceWorker || !window.Notification) {
      this.isEnabled = false;
      return;
    }

    void isSupported().then((supported) => {
      this.supported = supported;
    });

    if (!this.supported) {
      this.isEnabled = false;
      return;
    }

    // disable in cypress because we get the error serviceWorker Registration
    // failed. No Idea why.
    if (isCypressTest()) {
      this.isEnabled = false;
      return;
    }

    // eslint-disable-next-line compat/compat
    this.permission = Notification ? Notification.permission : undefined;
    this.messaging = getMessaging(getApp(APP_NAME_EU));

    watchPermissionAndUserUid(this);
    listenToServiceWorker();
  }

  @action
  setUser = ({ uid }: { uid: UID }) => {
    if (!this.isEnabled || !this.messaging) {
      return;
    }

    this.userUid = uid;
  };

  @action
  clearUser = async () => {
    if (!this.isEnabled) {
      return;
    }

    if (!this.userUid || !this.token) {
      return;
    }

    await this.deleteToken();
  };

  @action
  refreshToken = async () => {
    if (!this.isEnabled || !this.messaging || !this.userUid || this.isRefreshingToken) {
      return;
    }

    // need to ask for permission
    if (this.permission !== 'granted') {
      return;
    }

    this.isRefreshingToken = true;

    // get token and recheck it
    let currentToken: string;
    try {
      currentToken = await getToken(this.messaging, this.options);
    } catch (error) {
      sentry.log({ error: error as Error });
      this.isRefreshingToken = false;
      return;
    }

    if (!currentToken) {
      // Show permission request.
      logger.log(
        '* PushNotificationsService.setUser: No registration token available. Request permission to generate one.',
      );

      this.isRefreshingToken = false;
      return;
    }

    // remove old one if token has changed
    if (this.token && this.token !== currentToken) {
      await removeUserFcmTokenMutation({ uid: this.userUid, fcmToken: this.token });
    }

    this.token = currentToken as AtrigamPushToken;
    await updateUserFcmTokenMutation({
      uid: this.userUid,
      fcmToken: this.token,
      browser: this.browser,
    });
    this.isRefreshingToken = false;
  };

  @action
  requestPermission = async () => {
    if (!Notification || this.permission !== 'default') {
      return;
    }

    // eslint-disable-next-line compat/compat
    this.permission = await Notification.requestPermission();
  };

  @action
  private deleteToken = async () => {
    if (!this.token || !this.messaging || !this.userUid) {
      return;
    }

    // if this does not work, just continue
    try {
      await removeUserFcmTokenMutation({ uid: this.userUid, fcmToken: this.token });
    } catch {}

    const result = await deleteToken(this.messaging);
    if (result) {
      this.token = undefined;
    }
  };
}
