import { when } from 'mobx';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { syncModelsFromUser } from '@atrigam-webclient/stores/ModelsStore/helpers/syncModelsFromUser';

export const preloadModels = async () => {
  const modelsStore = Registry.get('modelsStore');
  const userStore = Registry.get('userStore');

  // make sure userStore is initialized
  await when(() => userStore.isInitialized);

  // make sure we are not synching client roles
  await when(() => !userStore.userClientRoles.isSynching);

  // wait till modelsStore is initialized
  await when(() => modelsStore.isInitialized);

  // make sure all models are preloaded

  // no need to preload if we are already syncing, but wait till it's done
  if (modelsStore.isSyncingModels) {
    await when(() => !modelsStore.isSyncingModels);
    return;
  }

  await syncModelsFromUser({});
};
