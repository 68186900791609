import {
  createMissingIndicesMutation,
  GetAllWorkItemsQueryFilter,
} from '@atrigam/server-functions-eu-clientsdk';

import { Registry } from '@atrigam-webclient/services/Registry/Registry';

import { getURLsFromString } from './getURLSFromString';

interface Options {
  errorMessage: string;
  filters: GetAllWorkItemsQueryFilter[];
}

export const handleMissingIndexError = async ({ errorMessage, filters }: Options) => {
  const { user } = Registry.get('userStore');

  const createIndexUri = getURLsFromString(errorMessage);
  const whereClause = filters.map((filter) => {
    switch (filter.type) {
      case 'dateRange': {
        return `${filter.field} between ${filter.from} and ${filter.to}`;
      }

      case 'default':
      default: {
        if (filter.values.length > 0) {
          return `${filter.field} in [${filter.values.join(',')}]`;
        }
        return `${filter.field} == ${filter.values[0]}`;
      }
    }
  });

  if (createIndexUri) {
    await createMissingIndicesMutation({
      missingIndex: errorMessage,
      createIndexUri: createIndexUri[0],
      indexExists: false,
      firstUser: user?.email,
      firstUserUID: user?.uid,
      whereClause,
    });
  }
};
