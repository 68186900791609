import { Avatar } from '@mui/material';
import styled, { css } from 'styled-components';

import { ThemedStyledComponent } from '@atrigam-webclient/themes/Themes.types';

export const PrimaryAvatar = styled(Avatar)<ThemedStyledComponent>(
  ({ theme }: ThemedStyledComponent) => css`
    background-color: ${theme.palette.primary.main};
  `,
);
