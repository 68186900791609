import { UnhandledCaseError } from '@atrigam/atrigam-types';
import { Alert, Button } from '@mui/material';

import { reactive } from '@atrigam-webclient/helpers/reactive';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';

const PushNotificationAlertComponent: React.FC = () => {
  const { permission, requestPermission } = Registry.get('pushNotifications');

  switch (permission) {
    case 'granted': {
      return null;
    }

    case 'default':
    case undefined: {
      // undefined means the user has not answered the question yet
      return (
        <Alert
          severity="warning"
          action={
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            <Button color="inherit" size="small" variant="outlined" onClick={requestPermission}>
              {translate(
                'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.PushNotificationAlert.allowBrowser',
              )}
            </Button>
          }
        >
          {translate(
            'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.PushNotificationAlert.permission.default',
          )}
        </Alert>
      );
    }

    case 'denied': {
      return (
        <Alert severity="error">
          {translate(
            'layouts.DefaultLayout.Navigation.UserNotifications.UserNotificationsWindow.PushNotificationAlert.permission.denied',
          )}
        </Alert>
      );
    }

    default: {
      throw new UnhandledCaseError(permission);
    }
  }
};

export const PushNotificationAlert = reactive(PushNotificationAlertComponent);
