import { Snackbar } from '@mui/material';
import React from 'react';

import { logger } from '@atrigam-webclient/helpers/logger';
import { reactive } from '@atrigam-webclient/helpers/reactive';
import { IS_TEST } from '@atrigam-webclient/mode';
import { PrimaryButton } from '@atrigam-webclient/primitive/Button/PrimaryButton';
import { Registry } from '@atrigam-webclient/services/Registry/Registry';
import { SentryCategories } from '@atrigam-webclient/services/Sentry/Sentry.types';
import { sentry } from '@atrigam-webclient/services/Sentry/helpers/initializeSentry';

import { useAutoReloadCountdown } from './hooks/useAutoReloadCountdown';

const AUTO_RELOAD_TIMEOUT = 30 * 60 * 1000; // 30 minutes
const COUNTDOWN_THRESHOLD = 5 * 60 * 1000; // 5 minutes

const UpdateAvailableNotificationComponent: React.FC = () => {
  const { isAnUpdateAvailable, appVersion, updateApplication } = Registry.get('appStore');
  const { setEndTime, humanReadableLeftTime, timeLeft } = useAutoReloadCountdown();
  const timerReference = React.useRef<number>();

  const reloadPage = React.useCallback(
    ({
      automaticReload,
      type,
    }: {
      automaticReload: boolean;
      type: 'userClicked' | 'notificationTimeout';
    }) => {
      sentry.addBreadcrumb({
        category: SentryCategories.Router,
        message: automaticReload
          ? 'Automatic reloading of page because of new version after countdown.'
          : 'User reloads page because of new version',
        data: {
          oldVersion: appVersion,
        },
        level: 'info',
      });

      // reloading
      void updateApplication(type);
    },
    [appVersion, updateApplication],
  );

  const handleClick = React.useCallback(
    () => reloadPage({ automaticReload: false, type: 'userClicked' }),
    [reloadPage],
  );

  React.useEffect(() => {
    // test override, ignore
    if (IS_TEST) {
      return;
    }

    // no update -> no timer
    if (!isAnUpdateAvailable) {
      clearTimeout(timerReference.current);
      setEndTime(0);
      return;
    }

    // already timing
    if (timerReference.current) {
      return;
    }

    timerReference.current = window.setTimeout(
      () => reloadPage({ automaticReload: true, type: 'notificationTimeout' }),
      AUTO_RELOAD_TIMEOUT,
    );
    setEndTime(Date.now() + AUTO_RELOAD_TIMEOUT);
  }, [reloadPage, setEndTime, isAnUpdateAvailable]);

  // clear timeout if reloading
  React.useEffect(() => {
    return () => {
      clearTimeout(timerReference.current);
    };
  }, []);

  const showTimeout = timeLeft > 0 && timeLeft < COUNTDOWN_THRESHOLD;
  const countdownMessage = showTimeout
    ? '<br/>' +
      translate('app.UpdateAvailableNotification.autoReloadIn', { timeleft: humanReadableLeftTime })
    : '';

  // developer override, ignore
  if (IS_TEST && isAnUpdateAvailable) {
    logger.info(`New version available but ignoring because testing`);
    return null;
  }

  return (
    <Snackbar
      open={isAnUpdateAvailable}
      message={
        <span
          dangerouslySetInnerHTML={{
            __html:
              translate('app.UpdateAvailableNotification.newVersionAvailable') + countdownMessage,
          }}
        />
      }
      data-test-id="screens-new-version-snackbar"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <PrimaryButton size="small" onClick={handleClick} variant="text">
          {translate('app.UpdateAvailableNotification.reload')}
        </PrimaryButton>
      }
    />
  );
};

export const UpdateAvailableNotification = reactive(UpdateAvailableNotificationComponent);
