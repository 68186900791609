import {
  AtrigamUserNotification,
  UID,
  isAtrigamAreaName,
  isAtrigamEnvironment,
  isAtrigamTaskFlowName,
  isAtrigamWorkItemId,
  isAtrigamObjectName,
  isAtrigamUniverseName,
} from '@atrigam/atrigam-types';
import {
  getUserSubscriptionFromCacheQuery,
  getUserSubscriptionQuery,
  getWorkItemFromCacheQuery,
  getWorkItemQuery,
} from '@atrigam/server-functions-eu-clientsdk';

interface Options {
  notifications: AtrigamUserNotification[];
  uid: UID;
}

export const warmupFirestoreCache = async ({ notifications, uid }: Options) => {
  await Promise.all(
    notifications.map(async (notification) => {
      if (
        !isAtrigamWorkItemId(notification.workitemId) ||
        !isAtrigamUniverseName(notification.universe) ||
        !isAtrigamObjectName(notification.objectName) ||
        !isAtrigamEnvironment(notification.environment)
      ) {
        return;
      }

      if (isAtrigamAreaName(notification.area) && isAtrigamTaskFlowName(notification.flow)) {
        try {
          await getUserSubscriptionFromCacheQuery({
            area: notification.area,
            taskFlow: notification.flow,
            universe: notification.universe,
            environment: notification.environment,
            node: notification.workitemId,
            uid,
          });
        } catch {
          await getUserSubscriptionQuery({
            area: notification.area,
            taskFlow: notification.flow,
            universe: notification.universe,
            environment: notification.environment,
            node: notification.workitemId,
            uid,
          });
        }
      }

      try {
        await getWorkItemFromCacheQuery({
          universe: notification.universe,
          environment: notification.environment,
          objectName: notification.objectName,
          node: notification.workitemId,
        });
      } catch {
        await getWorkItemQuery({
          universe: notification.universe,
          environment: notification.environment,
          objectName: notification.objectName,
          node: notification.workitemId,
        });
      }
    }),
  );
};
